import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import {useLocation} from 'react-router-dom';
import { usePersistedDataStore } from "../store";
import axiosInstance from "../../axiosConfig";

function CompAdditionSearch({ handleClose, addCompCallback }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [debouncedTerm, setDebouncedTerm] = useState(searchTerm); // Debounced term state - this is your real "search" object
    const [pidSearch, setPidSearch] = useState(false);
    const getCompSheet = usePersistedDataStore((state) => state.compSheet);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const comp = parseInt(params.get('comp'))-1;
  
    // In your search function - first confirm that the PID does NOT exist in the current array
    // if it does, autoscroll the user down to it?
    // THEN lookup in DB for a parcel ID match to the corresponding address string and log it if it exists
    // Have it default to adding it as the NEXT comparable

    // Debounce effect: update debouncedTerm only after user stops typing for 500ms
    useEffect(() => {
    const handler = setTimeout(() => {
    if(searchTerm.length > 0){
        setDebouncedTerm(searchTerm);
    }else if(loading===true || suggestions.length >0 || debouncedTerm!=='') { // there's no search term, set default states (if not already default).
        setLoading(false);
        setSuggestions([]);
        setDebouncedTerm('');
    }
    }, 500); // 500ms debounce time

    // Cleanup the timeout if searchTerm changes before 500ms
    return () => clearTimeout(handler);
    }, [searchTerm]);

    // Perform the search when debouncedTerm changes
    useEffect(() => {
        const fetchData = async () => {
            if (debouncedTerm) {
                console.log(debouncedTerm);
    
                // Check if it's a PID search
                const isPidSearch = /^[A-Za-z]\d{4}/.test(debouncedTerm) && /-/.test(debouncedTerm);
                // const isPidSearch = /^[A-Za-z]\d{4}$/.test(debouncedTerm);

                // here, set your search locale -- if it is not found, you should then search globally across getCompSheet array.
                let searchLocale = isPidSearch ? getCompSheet[comp]?.parcel_id : getCompSheet[comp]?.Address;

                // search throught he search locale for a partial match of the term:
                let found = searchLocale?.filter((value) => value.toLowerCase().includes(debouncedTerm.toLowerCase()));

                console.log(found)

                // if found, set the suggestions to the found array.
                if(found.length > 0){
                    setSuggestions(found);
                }
                // else{
                //     // if not found, set the suggestions to the entire array.
                //     setSuggestions(searchLocale);
                // }
                
                
                
                // if (isPidSearch) {
                //     if (!pidSearch) {
                //         setPidSearch(true);
                //     }
                //     console.log("Searching by PID:", debouncedTerm);
                //     // Add your PID search logic here
                // } else {
                //     if (pidSearch) {
                //         setPidSearch(false);
                //     }
                //     console.log("Searching by address:", debouncedTerm);
                //     // Add your address search logic here
                // }

                setLoading(false);
    
                // try {
                //     // Perform the API request
                //     const { data: properties } = await axiosInstance.post('/propertySearch', {
                //         searchTerm: debouncedTerm,
                //         pidSearch: isPidSearch,
                //     });
    
                //     console.log(properties);
    
                //     // Update the suggestions state with the fetched properties
                //     setSuggestions(properties || []);
                // } catch (error) {
                //     console.error("Error fetching properties:", error);
                //     // Optionally set error state or handle error here
                // } finally {
                //     setLoading(false);
                // }
            } else if (suggestions.length > 0) {
                // console.log(suggestions);
                setSuggestions([]);
            }
        };
    
        // Call the async function
        fetchData();
    }, [debouncedTerm]);  // Dependency array
    

    const handleInputChange = (event) => {
        setLoading(true);
        setSearchTerm(event.target.value);
    };

    const searchProp = (selection) => {

        // chekc if it was a PID or address serach based on what is passed in using regex.
        const isPidSearch = /^[A-Za-z]\d{4}/.test(selection) && /-/.test(selection);
        // if its a PID search, lookup index by PID, otherwise
        console.log(isPidSearch)

        const searchLocale = isPidSearch ? getCompSheet[comp]?.parcel_id : getCompSheet[comp]?.Address;

        // find the index of the selection in the searchLocale
        const index = searchLocale.findIndex((value) => value.toLowerCase() === selection.toLowerCase());

        // if found, call the comp callback with the pid value from that index.
        if (index !== -1) {
            addCompCallback(getCompSheet[comp]?.parcel_id[index]);
        }
        handleClose();

        // you could use the below code of !== -1 to say if it exists, and replace it direct in rows
        // Otherwise we need to handle adding it into your rows
        // addCompCallback(pid);

        // I actually dont know if you need the lookup below. You can just hit a callback that goes and appends it to your selected array?
    
        // Look for the exact match of pid in the parcel_id array
        // console.log(getCompSheet[comp]?.parcel_id)
        // const index = getCompSheet[comp]?.parcel_id?.findIndex((value) =>
        //     value.toLowerCase() === pid.toLowerCase()
        // );
    
        // if (index !== -1) {
        //     console.log(`PID found at index: ${index}`);
        // } else {
        //     console.log('PID not found');
        // }
    
        // handleClose();
    };

  return (
    <div className='w-60 relative overflow-visible'>
    <TextField
      id="standard-basic"
      placeholder="Search by PID or Address"
      variant="standard"
      fullWidth
      value={searchTerm}
      onChange={handleInputChange}
      autoComplete='off'
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: loading && <CircularProgress size={20} />,
      }}
    />
   
      {/* Display suggestions */}
      {suggestions.length > 0 && (
        <ul className="fixed mt-1 bg-white border shadow-md w-fit h-auto max-h-52 overflow-y-auto p-1 rounded-md z-10">
            {suggestions.map((property) => (
            <li
            // Also set the PID here.
                onClick={() => searchProp(property)} // Ensure to uncomment this line for functionality
                key={property.pid}
                className="px-4 py-2 cursor-pointer hover:bg-[#F1F5F9] hover:rounded-md"
            >
                {property}
                {/* {property.address} */}
            </li>
            ))}
        </ul>
        )}

      {/* Optionally, handle the case of no matching addresses */}
      {debouncedTerm && suggestions.length === 0 && (
        <ul className="fixed mt-1 bg-white border shadow-md w-fit p-1 rounded-md z-10">
          <li className="px-4 py-2 text-center">No matching addresses</li>
        </ul>
      )}
   
    
  </div>  );
}

export default CompAdditionSearch;
