import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Popover from '@mui/material/Popover';
import CompAdditionSearch from './CompAdditionSearch';
import LoopIcon from '@mui/icons-material/Loop';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import { DataGridPro, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import SubjectDataGridRow from './SubjectDataGridRow';
import CircularProgress from '@mui/material/CircularProgress';

// This function ended up being redundant but is fine to seperate these things out a bit mroe from the monolithic MUI table component.
function DataGridContainer({
        addCompCallback,
        discardChanges,
        setIsSavingCallback,
        getTogglableColumns,
        userUpdate,
        handleProcessRowUpdate,
        mergedProps,
        dataGridStyles,
        pinnedRows,
        apiRef,
        memoizedColumnVisibilityModel,
        handleColumnVisibilityChange,
        getRowClassName,
        getCellClassName,
        handleProcessRowUpdateError,
        headerResult,
        isDifferent,
        saveChanges,
        subject,
        unsavedChangesRef,
        updateRefWithSubj,
        handleSaveComps,
        handleToggleReordering,
        reordering,
        isSaving,
        savedCompPids,
        }){

    return(
        // this overlay div should be conditionally displayed based on WHERE we are in the fetch response
        // see if you can use a callback function so that when you invoke the inventory update, it sets a local state down in this?
        // Potentially go one level deeper?
        // or higher? basically don't want the full table rerender if we can avoid but if performant than no stress
        <div className="w-full h-full relative">
            {/* DataGridPro with the toolbar */}
            <DataGridPro
                {...mergedProps}
                className="data-grid-transition"
                sx={dataGridStyles}
                pinnedRows={pinnedRows}
                apiRef={apiRef}
                // loading={isSaving}
                columnVisibilityModel={memoizedColumnVisibilityModel}
                onColumnVisibilityModelChange={handleColumnVisibilityChange}
                getRowClassName={getRowClassName}
                getCellClassName={getCellClassName}
                processRowUpdate={(updatedRow, originalRow) => handleProcessRowUpdate(updatedRow, originalRow)}
                onProcessRowUpdateError={handleProcessRowUpdateError}
                slots={{
                    toolbar: () => (
                        <MemoizedToolbar
                            headers={headerResult}
                            isDifferent={isDifferent}
                            saveChanges={saveChanges}
                            memoizedColumnVisibilityModel={memoizedColumnVisibilityModel}
                            subject={subject}
                            selectedRowIds={pinnedRows}
                            userUpdate={userUpdate}
                            unsavedChangesRef={unsavedChangesRef}
                            discardChanges={discardChanges}
                            updateRefWithSubj={updateRefWithSubj}
                            handleSaveComps={handleSaveComps}
                            handleToggleReordering={handleToggleReordering}
                            reordering={reordering}
                            addCompCallback={addCompCallback}
                            setIsSavingCallback={setIsSavingCallback}
                            savedCompPids={savedCompPids}
                        />
                    ),
                }}
            />

            {/* Conditionally show the overlay and loading spinner over the grid content */}
            {isSaving && (
                <>
                    {/* Overlay only on the grid content */}
                    {/* Top-[70px] removed */}
                    <div className="absolute inset-0 bg-gray-400 opacity-60 pointer-events-none grid-overlay" />

                    {/* CircularProgress centered in the grid content */}
                    <div className="absolute mt-[70px] inset-0 flex items-center justify-center">
                        <CircularProgress className="opacity-100" 
                        sx={{ color: '#374151' }} 
                        />
                    </div>
                </>
            )}
        </div>
    )
}
    export default React.memo(DataGridContainer);
      
    
    // your toolbar component
const MemoizedToolbar = React.memo(({
    headers,
    isDifferent,
    setIsSavingCallback,
    handleSaveComps,
    selectedRowIds,
    savedCompPids,
    saveChanges,
    userUpdate,
    updateRefWithSubj,
    memoizedColumnVisibilityModel,
    subject,
    discardChanges,
    unsavedChangesRef,
    handleToggleReordering,
    reordering,
    addCompCallback,
  }) => {
  
    const hiddenFields = ['actions']; // Specify any fields you want to hide from the toggle
  // Get togglable columns function
  const getTogglableColumns = (columns) => {
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  };
  
  const [anchorEl, setAnchorEl] = useState(null); // Control popover
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Set the button as the anchor element
  };
  
  const handleClose = () => {
    setAnchorEl(null); // Close the popover
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  
  
    return (
      <div>
        <div className='flex items-center justify-between'>
          <div className='flex items-center'>
            <div className='toolbar-buttons'>
            <GridToolbarColumnsButton
              getTogglableColumns={getTogglableColumns(headers)} // Pass the filtered columns to the toolbar
            />
  
              <GridToolbarFilterButton
              />
              <GridToolbarExport
              // name your file specific to the address (dope!)
              csvOptions={{ fileName: `${subject.PropertyInfo.Address}_comps`, separator: ',' }}
              />
              <GridToolbarQuickFilter
              />
            </div>
          </div>
  
          {/* Right section - */}
          <div className='flex-shrink-0 flex items-center justify-end' >
        <Button
          aria-describedby={id}
          className='py-0.5 mr-2 inline-flex items-center justify-center whitespace-nowrap'
          variant='outlined'
          onClick={handleClick} // Show the popover on click
          endIcon={<AddIcon className=''/>}
        >
          Add Comp
        </Button>
        
        {/* MUI Popover for searching comps */}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose} // Close on clicking outside or selecting something
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {/* comp addition popover */}
          <div className='p-4 relative overflow-visible'>
            <CompAdditionSearch handleClose={handleClose} addCompCallback={addCompCallback}/>
          </div>
        </Popover>
          
          {/* inventory update buttons (discard + update) */}
            {Object.keys(unsavedChangesRef.current.unsavedRows).length || Object.keys(unsavedChangesRef.current.unsavedSubject).length ? (
              <div className='p-0.5'>
                <Button
                  variant='outlined'
                  className='mr-2 hover:border-red-500 hover:text-red-500 py-0.5'
                  // add sx style for onhover border-red-500
                  onClick={() => discardChanges()}
                >
                  Discard
                </Button>
                <Button
                  variant='outlined'
                  className='hover:border-green-600 hover:text-green-600 py-0.5'
                  onClick={saveChanges}
                >
                  Update
                </Button>
              </div>
            ) 
            : 
  
            // If you don't have any selected neither of these display? I guess thats correct.
            // Reordering, or saving comps
              <div className='flex whitespace-nowrap'>
                {reordering ? (
                  // this is a little gross, but gets the point across.
                  <Button
                  variant='contained'
                  onClick={handleToggleReordering}
                  className='w-[120px] h-[30.5px] text-center mr-2 group hover:bg-red-700 items-center transition-all duration-300'
                  endIcon={
                    <span>
                      <LoopIcon className='group-hover:hidden ml-[-10px] h-[20px] items-center' />
                      <CloseIcon className='hidden group-hover:flex ml-[-20px]' />
                    </span>
                  }
                >
                  <span className='group-hover:hidden w-full'>Reordering</span>
                  <span className='hidden group-hover:flex w-full pl-2'>Cancel</span>
                </Button>
                ) : (
                  <Button
                    className='py-0.5 mx-2'
                    variant='outlined'
                    onClick={handleToggleReordering}
                    // Temporarily disabled because not working how you want.
                    disabled={savedCompPids.length === 0 && selectedRowIds.top.length === 0}
                    // disabled={true}
                  >
                    Reorder
                  </Button>
                )}
              {/* // Save comps */}
              <Button className='py-0.5'
              disabled={!isDifferent}
              variant="outlined"
              onClick={() => {
                setIsSavingCallback();
                const idsToSave = userUpdate.length > 0 ? userUpdate : selectedRowIds.top;
                handleSaveComps(idsToSave);
              }}
              >
                Save Comps
              </Button>
              </div>
            }
              </div>
        </div>
        <SubjectDataGridRow subject={subject} updateRefWithSubj={updateRefWithSubj} unsavedChangesRef={unsavedChangesRef} headers={headers} columnVisibilityModel={memoizedColumnVisibilityModel} />
      </div>
    );
  });