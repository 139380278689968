import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {usePersistedDataStore, useCompStore} from '../store';
import AdjustmentsPopover from './AdjustmentsPopover';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import CompNavigation from './CompNavigation';

function a11yProps(index) {
  // this some MUI shit.
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const TableCustomToolbar = ({ globalCompRef, handleUpdateStateCallback, view, viewCallback, runDate, regularAvg, savedComps, compsSelected, compAvg, caseNotes, changeCaseNotes }) => {
  // this input changes from controlled to uncontrolled. Haven't really looked into it RE: casenotes. At some point do this.
    const [value, setValue] = useState(0); // using local state, and re-rendering the entir etable when changing.
    // load in evalDate and getnegotiationObj
    const getAdjustments = useCompStore((state)=> state.adjustments)
    const getEvalDate = usePersistedDataStore((state) => state.evalDate);
    const getRar = usePersistedDataStore((state) => state.rar);
    const getEq = usePersistedDataStore((state) => state.eq);
    const formatEvalDate = (dateString) => {
      if (!dateString) return ''; // Handle case where date is null or undefined
      const [year, month, day] = dateString.split('-'); // Split by '-'
      return `${month}/${day}/${year}`; // Rearrange to 'mm/dd/yyyy'
    };

    // minor inefficency on rerenders of eval date.
    // console.log('TableCustomToolbar rendered');
    const formattedEvalDate = formatEvalDate(getEvalDate);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleViewChange = (e, newValue) => {
    setValue(newValue);
    viewCallback(newValue);
    }
    // // if newValue = 0, regular, if 1, court
    // const newView = newValue === 0 ? 'regular' : 'optimized';
    // // update the view in the url
    // const newSearchParams = new URLSearchParams(location.search);
    // newSearchParams.set('view', newView);
    // navigate(`${location.pathname}?${newSearchParams.toString()}`);
    // }
    

    return (
        <div className=''>
            {/* castenotes / chat input */}
            {/* chat piece to be built. */}
            <div className='flex flex-grow mb-2'>
              <TextField
                hiddenLabel
                className='flex-grow py-0'
                InputProps={{
                    // disableUnderline: true,  // Optional: if you want to remove the underline
                    classes: {
                      input: 'py-2',  // Adjust padding (top-bottom padding) here
                    },
                }}
                id="filled-hidden-label-normal"
                // placeholder="Type casenotes, or ask me a question..."
                placeholder="Type any casenotes here"
                autoComplete='off'
                value={caseNotes}
                onChange={(e) => changeCaseNotes(e.target.value)}
                variant="filled"
              />
            </div>
      
      <div className='flex content-center items-center justify-between h-[32px]'>
      {/* Tabs navigation */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className='flex'>
          <Tabs
            value={value}
            onChange={handleViewChange}
            aria-label="basic tabs example"
            sx={{
              minHeight: '30px',  // Adjusting Tabs container height
            }}
            TabIndicatorProps={{
              sx: {
                height: '2px',  // Adjust the height of the bottom indicator
              },
            }}
          >
            <Tab
              label="Regular"
              {...a11yProps(0)}
              sx={{
                minHeight: '30px',  // Adjusting individual Tab height
                padding: '4px 12px',  // Adjusting vertical and horizontal padding
                fontSize: '0.875rem',  // Optional: Adjust text size to fit
              }}
            />
            <Tab
              label="Court"
              {...a11yProps(1)}
              sx={{
                minHeight: '30px',  // Adjusting individual Tab height
                padding: '4px 12px',  // Adjusting vertical and horizontal padding
                fontSize: '0.875rem',  // Optional: Adjust text size to fit
              }}
            />
          </Tabs>

                  {/* The eval date and adj div */}
        <div className='text-sm ml-2'>
          {/* could move this to its own component if you start doing more with the adj price */}
          {/* Eval date popover on top of the adjustments */}
          <div className='flex justify-start items-center text-xs'>
            <span>Eval Date: {formattedEvalDate}</span>
            <InfoOutlinedIcon 
              className='cursor-pointer ml-1' 
              fontSize='inherit'
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <div className='flex flex-col'>
                <span>RAR: {getRar}</span>
                <span>EQ: {getEq}</span>
                </div>
              </Box>
            </Popover>
          </div>
        <AdjustmentsPopover adjustments={getAdjustments} />
        </div>

        </Box>
        

        {/* /* Construct a toolbar component that displays here. Need to style like a MUI header. */}
        <div className='flex flex-col items-center justify-center'>
                    {/* In theory if you save comps this should be overwritten */}
          {runDate ?
            <span className='text-xs'>
            Last Run: {new Date(new Date(runDate).getTime() + 86400000).toLocaleDateString('en-US', { 
              year: '2-digit', 
              month: '2-digit', 
              day: '2-digit' 
            })}
          </span>
          :
          null}
        <div className='flex items-center justify-center gap-2 text-sm mb-2'>
        
        {view === 'regular' ?
        <span className='flex font-bold'>
          Regular Avg: ${Math.round(regularAvg).toLocaleString()}
        </span>
        :
        <span className='font-bold'>
        {
          // autistic way to do this, but it works.
          Array.isArray(compsSelected) ? compsSelected.length > 0 ? 'Selected Avg' : 
          'Top 5 Avg'
           : 
          savedComps.length>0 ? 'Selected Avg' :
           'Top 5 Avg'
          } ${Math.round(compAvg).toLocaleString()}
        </span>
        }
        </div>
        </div>

        <div className='w-[345px] justify-end text-right'>
          <CompNavigation
          globalCompRef={globalCompRef} 
          handleUpdateStateCallback={handleUpdateStateCallback}
          />
          {/* Comp navigation */}
        </div>

        </div>
      </div>
    );
};


export default TableCustomToolbar;