import React, { useState, useEffect, isValidElement } from 'react';
import { useLocation } from 'react-router-dom';
import { useCompStore, usePersistedDataStore } from '../store.js';
import DownloadXLSButton from './DownloadXLSButton.js';
import { showModalAndWaitForSelection } from '../../common/ShowModalAndWaitForSelection.js'; // you should be using this instead of ur manual jury rigged version in fetchcomps.
import axiosInstance from '../../axiosConfig.js';

function ReviewSettlements({isAdmin=false}) {
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
  const taxYear = queryParams.get('TaxYear');
  const courtDate = queryParams.get('CourtDate') || 'All';
  const MuniCode = queryParams.get('MuniCode') || 'All';
  const [countState, setCountState] = useState(0);
  const [totalScarCases, setTotalScarCases] = useState(0)
  const [avgReduction, setAvgReduction] = useState(0);
  const [totalNC, setTotalNC] = useState(0);
  const [taxSavings, setTaxSavings] = useState(0)
  const [scarCases, setScarCases] = useState()
    let runningSetlement = 0; // local var to tally your settlement value.
    let runningIFMV = 0; // var to tally your IFMV's (as cases are settled to calc the % reduct)
    let runningNC = 0;
  const fileName = `${MuniCode}_${taxYear}${courtDate === 'All' ? '' : `_${courtDate}`}`;
    // For the download
    const keys = ['PID', 'Address', 'Town', 'ZipCode','Name', 'SCARIndexNumber','PreviousAssessTown', 'IFMVTown', 'RunDate', 'SalePrice', 'SaleDate', 'RecentSale', 'EstimatedTaxes', 'SubjectMarketValue', 'RAR', 'RequestedAssessment', 'PctReduction', 'CaseNotes', 'RepID', 'settled'];
              // Get data to pull in the sale price and date, and include

              useEffect(() => {
                updateNegotiation(); // Consolidated function call
              }, []);
            
              async function updateNegotiation() {
                let runningSettlement = 0;
                let runningIFMV = 0;
                let runningNC = 0;
                let missingValuesFlag = false;
            
                // Load sales data
                const uids = getNegotiationObj.cases.map((item) => item.PID);
                const salesResponse = await axiosInstance.post('/get_sales_data', {
                  TaxYear: getNegotiationObj.TaxYear,
                  MuniCode: MuniCode,
                  uids: uids,
                });
            
                const salesData = salesResponse.data;
                const updatedCases = getNegotiationObj.cases.map((caseItem) => {
                  const saleInfo = salesData.find((sale) => sale.pid === caseItem.PID);
                  if (saleInfo) {
                    return {
                      ...caseItem,
                      SaleDate: saleInfo.saleDate,
                      SalePrice: saleInfo.salePrice,
                      RecentSale: saleInfo.recentSale,
                    };
                  }
                  return caseItem;
                });
            
                // Initialize negotiation object with updated cases
                const finalNegotiationObj = { ...getNegotiationObj, cases: updatedCases };
            
                // Calculate savings and scar cases
                const countSettled = updatedCases.reduce((count, obj) => {
                  if (obj && obj.type !== null) {
                    const compSheetItem = getCompSheet[updatedCases.indexOf(obj)];
                    if (compSheetItem && compSheetItem.PropertyAssessment) {
                      runningIFMV += parseInt(compSheetItem.PropertyAssessment[0]);
                      runningSettlement += parseInt(obj.SCARDeterminationValue);
                      if (obj.SCARDeterminationAction === 'W' || obj.SCARDeterminationAction === 'SD') {
                        runningNC++;
                      }
            
                      if (obj.SubjectMarketValue === null || obj.SubjectMarketValue === '') {
                        missingValuesFlag = true; // Set the flag if any SubjectMarketValue is null or empty
                      }
                    }
                    if (obj.settled) {
                      return count + 1;
                    }
                  }
                  return count;
                }, 0);
            
                setCountState(countSettled);
                setAvgReduction((runningSettlement - runningIFMV) / runningIFMV);
                setTaxSavings(runningIFMV * 0.02 * ((runningSettlement - runningIFMV) / runningIFMV));
                setTotalNC(runningNC);
                setTotalScarCases(updatedCases.filter((item) => item.type !== null)); // Updated totalScarCases based on new cases
            
                // Update the cases within the final negotiation object
                finalNegotiationObj.cases = finalNegotiationObj.cases.map((item, index) => {
                  if (item && item.type !== null) {
                    const compSheetItem = getCompSheet[index];

                    if (compSheetItem && compSheetItem.PropertyAssessment) {
                      let requestedAssessment = item.SubjectMarketValue;
            
                      if (compSheetItem.IFMV[0] > 450000 && requestedAssessment < 0.75 * compSheetItem.PropertyAssessment[0]) {
                        requestedAssessment = 0.75 * compSheetItem.PropertyAssessment[0];
                      }
                      const percentReduction = ((compSheetItem.PropertyAssessment[0]-requestedAssessment)/compSheetItem.PropertyAssessment[0])/100;
            
                      return {
                        ...item,
                        PropertyAssessment: compSheetItem.PropertyAssessment[0],
                        RAR: compSheetItem.RAR[0],
                        RequestedAssessment: requestedAssessment,
                        PctReduction: percentReduction,
                      };
                    }
                  }
                  return item;
                });
            
                // Set the updated negotiation object only once
                setNegotiationObj(finalNegotiationObj);
            
                // Handle missing SubjectMarketValue
                if (missingValuesFlag) {
                  const userSelection = await showModalAndWaitForSelection(
                    "You are missing subject market values",
                    'Generate them in the admin portal, then re-run and proceed to download xls.',
                    'ignore',
                  );
                  if (userSelection === 'ignore') {
                    console.log('User chose to ignore missing values.');
                  }
                }
            
                // became redundant now.
                // Split SCAR cases
                const filteredScarCases = finalNegotiationObj.cases.filter((caseItem) => caseItem.SCARFiled === 1);
                setScarCases(filteredScarCases);
              }

  return (
    // On this page, if there's no settlements (or even if there are) - have an option to "save review" - this will save the comp order,
    // And save any min/max offers (and settlements)
    <div>
    <div className='relative rounded-sm border  border-stroke max-w-[475px] bg-white py-6 px-7.5 dark:text-white m-auto mt-12 shadow-default dark:border-strokedark dark:bg-boxdark'>
      <p>Number of Cases Settled: {countState} / {totalScarCases.length}</p>
                  {/* Change denominator to only count if getnegotiationobj type !== null. */}
      <p className='flex'>Avg red (settled cases): <span className='font-bold flex ml-1'> {avgReduction.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
      
      {isAdmin?
      <div>
        <p>Estimated tax savings (2% ETR): {Math.floor(taxSavings).toLocaleString()}</p>
        <p className='flex'>Our estimated fee: <span className='font-bold ml-1'>{Math.floor(Math.abs(taxSavings / 2)).toLocaleString()}</span></p>
      </div>
      :null  
      }
      <p>% win rate: {((countState-totalNC)/countState).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 })} ({countState-totalNC} wins)</p>
      
      <p className='text-xs mt-2'>*These stastics only reflect SCAR</p>
    </div>


    {/* // onClick dropdown option for download to be "scar Only" or "All Cases" */}
      {/* Button to download JSON data based on selection */}
      <div className='mt-2'>
      <DownloadXLSButton
        jsonData={getNegotiationObj.cases}
        fileName={fileName}
        keys={keys}
      />
      </div>

    </div>
  );
} export default ReviewSettlements
