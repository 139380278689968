import { usePersistedDataStore } from '../components/store';
import axiosInstance from '../axiosConfig.js'
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';

export default function useUpdateDB() {
  const persistedDataStore = usePersistedDataStore();
  const location = useLocation();
  const negotiationObj = persistedDataStore.negotiationObj;
  const queryParams = new URLSearchParams(location.search);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0;
  const county = queryParams.get('county') || 'All';
  const village = queryParams.get('village') || 'All';
  const courtDate = queryParams.get('CourtDate') || '';
  const muniCode = queryParams.get('MuniCode') || '';
  const navigate = useNavigate();
  const taxYear = queryParams.get('TaxYear') || '';

  async function updateDB({ confirmed = false, handleConfirmCancel, updatedValues }) {
    // Map over the compSheet[comp] and extract the array of parcel_ids
    const parcelIds = negotiationObj.cases.map(obj => obj.PID);
    const userId = JSON.parse(localStorage.getItem('userInfo')).userId;
    const [month, day, year] = courtDate ? courtDate.split('/') : [null, null, null];
    const courtDateFormatted = year
      ? [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-')
      : null;

      // Use comp to extract the subj from pid
    let inventoryUpdateObj = {
      CourtRun: courtDate ? 1 : 0,
      MuniCode: muniCode,
      TaxYear: taxYear,
      UserId: userId,
      Records: updatedValues,
      uids: parcelIds,
      updatedSubject: parcelIds[comp], // Use comp to extract the subj from pid
      originalCompsheet: persistedDataStore.compSheet,
      originalOptimizedComps: persistedDataStore.optimizedComps,
      ...(courtDateFormatted && { CourtDate: courtDateFormatted }), // Conditionally include CourtDate
    };

    // Return the inventoryUpdateObj here
    return inventoryUpdateObj;
  }

  return updateDB;
}





// export default function useUpdateDB() {
//     const persistedDataStore = usePersistedDataStore();
//     const location = useLocation();
//     const negotiationObj = persistedDataStore.negotiationObj;
//     const queryParams = new URLSearchParams(location.search);
//     const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
//     const county = queryParams.get('county') || 'All';
//     const village = queryParams.get('village') || 'All';
//     const courtDate = queryParams.get('CourtDate') || '';
//     const muniCode = queryParams.get('MuniCode') || '';
//     const navigate = useNavigate()
//     const taxYear = queryParams.get('TaxYear') || ''; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.

//     async function updateDB({confirmed = false, handleConfirmCancel, updatedValues}) {

//     // Map over the compSheet[comp] and extract the array of parcel_ids
//     const parcelIds = negotiationObj.cases.map(obj => obj.PID)   // setters for case review return.  
//     const setOptimizedComps = persistedDataStore.setOptimizedComps
//     const setCompSheet = persistedDataStore.setCompSheet

//     try{
//       const userId = JSON.parse(localStorage.getItem('userInfo')).userId;
//       const [month, day, year] = courtDate ? courtDate.split('/') : [null, null, null];
//       const courtDateFormatted = year ? [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-') : null;

//       // const recordPids = updatedValues.map(obj => obj.pid); // only run this for the proper UIDS
//       let inventoryUpdateObj = {
//         CourtRun: courtDate ? 1 : 0,
//         MuniCode: muniCode,
//         TaxYear: taxYear,
//         UserId: userId,
//         Records: updatedValues,
//         uids: parcelIds,
//         ...(courtDateFormatted && { CourtDate: courtDateFormatted }) // Conditionally include CourtDate
//       };
      
//       console.log(inventoryUpdateObj);

//       // HERE RETURN YOUR INVENTORY UPDATE OBJECT, AND CONTINUE THE REST OF THIS FUNCTION WITHIN MUI TABLE
//       return inventoryUpdateObj


//       // MOVE THIS PART TO BE IN YOUR MAIN MUI Table function    
//     const updateInventoryResponse = await axiosInstance.post(`/update_inventory`,inventoryUpdateObj)
//     // THE RESPONSE DOESN"T preserve if you constrained with setteld only or not
//     // const 
//     // if(process)
//     console.log(updateInventoryResponse)

//     let inventoryObj


//     // HERE is where you should start to kickback the update function (route the response back to the MUI table?)
//     // Response comes back differently in different environments atm.
//     if(process.env.REACT_APP_ENV === 'development'){
//       inventoryObj = updateInventoryResponse.data[0].response;
//       // should this be diff ind ev?
//       }else{
//       inventoryObj = updateInventoryResponse.data;
//       }
//     //   console.log('inventory obj as parsed:')
//     // console.log(inventoryObj) // data.parameters is how you can see the actual values updated.
//     // Need to update so you set comps.
    
//     let updatedObj;
//     // this conditional logic attempts to parse JSON (reuired based on response returned in prod), otherwise it just extracts the value.
//     try {
//         updatedObj = JSON.parse(inventoryObj);
//     } catch (error) {
//       // this catch makes it work locally since the JSON parse i  not required.
//         updatedObj = inventoryObj;
//         console.log("JSON coulnd't be parsed, using raw response. if you're in development, this can be ignored.");
//     }
    
//     console.log(updatedObj); // this response object isn't what you should be getting.
//     if(updateInventoryResponse.status===200){
//       // This updates the entire object (really maybe we only want to update the relevant one w/ a quick splicing?)
//       const regularComps = updatedObj.map(comp => comp[0]);  // Extract values at index [0]
//       const optimizedComps = updatedObj.map(comp => comp[1]); // Extract values at index [1]
  
//       // Set the state for each array
//       setCompSheet(regularComps);
//       setOptimizedComps(optimizedComps);
//       handleConfirmCancel({},true)
//     }
//     }catch (error) {
//       handleConfirmCancel()
//       console.log(error, 'Error while reading settlements');
//     }
//   }

// return updateDB
// }