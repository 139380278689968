import React, {useState, useMemo, useRef, useEffect, useCallback} from 'react';
import { usePersistedDataStore } from '../store';
import MuiTable from "./MuiTable";
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import { blue } from '@mui/material/colors';
import TableCustomToolbar from './TableCustomToolbar';
import { saveComps } from '../../common/saveComps'
import Map from './Map';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import cloneDeep from 'lodash/cloneDeep';
import { PropertyInfoTooltip } from './MuiTable';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

const CustomCheckboxHeader = React.memo(({ indeterminate, onChange }) => { // if this could access the api ref 
  return (
    <div className='items-center justify-center content-center m-auto flex h-full w-full'
          style={{
            width: 22, // Match the size of your checkbox
            height: 22, // Match the size of your checkbox
          }}
    >
      {indeterminate &&
      <Checkbox
        indeterminate={indeterminate}
        onChange={onChange}
        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 24 }} />} // Ensure consistent fontSize for unchecked state
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 26, // Apply consistent sizing to all icons
          },
        }}
      />
      }
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.allRowsSelected === nextProps.allRowsSelected &&
          prevProps.indeterminate === nextProps.indeterminate;
});

const CustomCheckboxCell = React.memo(({ checked, selectedRowNumber }) => {
  let number = selectedRowNumber // if selectedrownumber = 0 only way itll be selected.
  return (
    <div className='items-center justify-center flex h-full'>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 24 }} />}
        checkedIcon={
          <div style={{
            position: 'relative',
            width: 20,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: blue[700],
            borderRadius: '10%',
          }}>
            <span style={{
              position: 'absolute',
              fontSize: 16,
              color: '#fff',
              fontWeight: 'bold',
            }}>
              {number}
            </span>
          </div>
        }
        checked={selectedRowNumber>0 ? checked:false}
      />
    </div>
  );
});

// VERY IMPORTANT NOTE - The utilization of MUI's api reference may not be stable indefinitely.
// We updated to the paid plan but haven't fully transitioned the code, I think we have the ability to do multiple row updates at once
// now instead of the iterative single update we're currently doing.

// Ther is a dumb flow that occurs here. You are rendering your casereview page twice on load.
const CaseReview = ({compObject, resetUpdateArrayCallback, compStreamObject, updatedArray, handleUpdateStateCallback, caseObject, optimizedObject, savedComps, globalCompRef}) => {  // update baths field to be "half baths", full baths, and total baths which is displayed by default.
  // If you update it to pull down teh object here, you could handle the comp swap in this component instead of rerendering the parent.
  // This component is poorly optimized. this can be observed if you try to rapidly clickthrough the comps.
  // This issue is made worse by the home rerendering on view swap - which is dumb.
  // perhaps we should remove view from higher level param? You could push it into history instead if thats what
  // const getViewOptions = usePersistedDataStore((state) => state.viewOptions);

  const handleAddAdj = useCallback((parcelId, value) => {
    // currently, updating these adjustments causes a re-render of the whoel grid. Not sure a way around atm.
    // if you don't include the state of selectedAdjustments in this callback, will it be incorrect when you call it from child?
    console.log(selectedAdjustments)
    console.log('adding adjustment')
    // first set the state which displays a popup for the user to input adjustment information
    // Update the ref to track the adjustment

    // render a modal, and pass in the specific PID
    toggleDisplayModal()
    // Use state to trigger a visual update if needed
    // setSelectedAdjustments((prev) => ({
    //   ...prev,
    //   [parcelId]: value,
    // }));
  }, []);

  const location = useLocation();
  const [selectedAdjustments, setSelectedAdjustments] = useState({});
  const getStyleOptions = usePersistedDataStore((state) => state.styleOptions);
  const getViewOptions = usePersistedDataStore((state) => state.viewOptions);
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
  const queryParams = new URLSearchParams(location.search);
  const comp = useMemo(() => ( parseInt(queryParams.get('comp')) - 1 || 0), [queryParams]);
  // const view = useMemo(() => (queryParams.get('view') || 'regular'), [queryParams]);
  const taxYear = useMemo(() => parseInt(queryParams.get('TaxYear')) || '', [queryParams]);
  const village = queryParams.get('village') || 'All';
  const adjustmentRef = useRef({});
  const [rowUpdate, setRowUpdate] = useState(false);
  const memoizedRowUpdate = useMemo(() => rowUpdate, [rowUpdate]);
  const memoizedCompObject = useMemo(() => compObject, [compObject]);
  const [selectedComps, setSelectedComps] = useState(null);
  const memoizedSelectedComps = useMemo(() => selectedComps, [selectedComps]);
  const previousComp = useRef(comp);
  const [caseNotes, setCaseNotes] = useState(caseObject?.CaseNotes||'');
  const caseNotesRef = useRef(caseNotes);
  const [view, setView] = useState('regular'); // changed view to be a local state here.
  // see if you use view anywhere else?

  const viewCallback = useCallback((newValue) => {
    const newView = newValue === 0 ? 'regular' : 'optimized';
    setView(newView);
  }, []);
  // you can set the view state here instead of in toolbar.
  
  // these essentially are functioning as bootleg useeffects. Was playing around with trying to fixx the rerenders.
  // these ones aren't at least optically problematic, 
  // Reset selectedComps when comp changes
  if (previousComp.current !== comp) {
    if(selectedComps !== null){
    setSelectedComps(null);
    }
    // if your current casenotes ref
    const fixedCaseNotes = caseObject.CaseNotes || '';
    if(fixedCaseNotes !== caseNotesRef.current){
      console.log(fixedCaseNotes)
    setCaseNotes(fixedCaseNotes);
    caseNotesRef.current = fixedCaseNotes;
    }
    previousComp.current = comp; // Update the ref to the new value
  }
  // extract yourr subject and comps
  const subject = useMemo(() => {
    // console.log('subjrern')
    return( Object.fromEntries(
    Object.entries(memoizedCompObject).map(([key, value]) => [key, Array.isArray(value) ? value[0] : value])
    ))
  }, [memoizedCompObject]);
  
  // Memoize the optimized components
  const optimizedComps = useMemo(() => {
    const workingOptimizedObject = cloneDeep(optimizedObject);
    const currentOptimizedObject = Object.fromEntries(
      Object.entries(workingOptimizedObject).map(([key, value]) => [key, Array.isArray(value) ? value.slice(1) : value])
    );

    const updatedOptimizedObject = {};

    // REmoved the whole optim reordering function bc idt its necessary anymore with your new pinning.

    return currentOptimizedObject

    // Ensure there are savedComps to reorder
    if (savedComps.length !== 0) {
      savedComps.forEach((comp, indexInSaved) => {
        const parcel_id = comp;
        
        // Find the index of the current parcel_id in the original optimizedObject
        const indexInOptimized = currentOptimizedObject.parcel_id.indexOf(parcel_id);
        
        if (indexInOptimized !== -1) {
          // Move values for every key from indexInOptimized to indexInSaved
          Object.keys(currentOptimizedObject).forEach((key) => {
            if (key === 'price_adjustments') {
              // Do nothing since this is deeply nested and throws errors
              return;
            }
            // Ensure updatedOptimizedObject has the key initialized as an array
            if (!updatedOptimizedObject[key]) {
              updatedOptimizedObject[key] = [];
            }

            // Splice out the value from indexInOptimized
            const [valueToMove] = currentOptimizedObject[key].splice(indexInOptimized, 1);

            // Insert the value at the new indexInSaved position
            updatedOptimizedObject[key].splice(indexInSaved, 0, valueToMove);
          });
        } 
      });

      // Append remaining components that were not part of savedComps (if any)
      Object.keys(currentOptimizedObject).forEach((key) => {
        if (key === 'price_adjustments') {
          // Do nothing since this is deeply nested and throws errors
          return;
        }
        // Ensure the remaining values are added to the end of the updated currentOptimizedObject
        currentOptimizedObject[key].forEach((value) => {
          if (value !== undefined) { // Skip undefined values that were moved earlier
            if (!updatedOptimizedObject[key]) {
              updatedOptimizedObject[key] = [];
            }
            updatedOptimizedObject[key].push(value); // Add remaining values to the end
          }
        });
      });

      console.log(updatedOptimizedObject);
      return updatedOptimizedObject; // Return the final reordered object
    }

    return {}; // Return empty if there are no saved comps
  }, [optimizedObject, savedComps]);

  // Memoize the regular components
  const regularComps = useMemo(() => {
    return Object.fromEntries(
      Object.entries(memoizedCompObject).map(([key, value]) => [key, Array.isArray(value) ? value.slice(1) : value])
    );
  }, [memoizedCompObject]);

// Now you can use optimizedComps and regularComps independently
  const comps = useMemo(() => {
    if (view === 'optimized') {
      return optimizedComps;
    }
    return regularComps;
  }, [view, optimizedComps, regularComps]);
  
  const tableProps = useMemo(() => ({ // custom table props for case review vverrsion of table.
    density: 'compact', // how closely spaced
    disableDensitySelector: true, // remove this option from the table header
    disableColumnResize: true, // can change this enable resizing.
    checkboxSelection: false,
    compTable: true, // in lower levvel MuiTable use this to set the backgrorund color.
    disableRowSelectionOnClick: true, // this will disable row selection on click
    disableAutosize: true,
    hideFooter: true, // remove the pagination / footer.
  }), []);
  // comps to fill in from callback. Default value should be to pass in ur regular saved.

  // idk if any point to memoize, this should work. confirm doesn't cause issues.
  const handleUpdateSelectedComps = useCallback((comps) => {
    console.log(comps)
    setSelectedComps(comps);
  }, []);

  const imputedValueCheck = useCallback((stringValue) => {
    const value = stringValue || ''; // Ensure value is at least an empty string
    if (typeof value === 'string' && value.includes('*')) {
        return (
        <div style={{ color: '#d32f2f' }} className='h-full w-full flex items-center justify-center'>
          {/* ; // Render value in red if it contains an asterisk */}
        <span className='text-red-500 h-full w-full hover:text-blue-500'>{value}</span>
        </div>
        )
    }
  }, []);


  const headers = useMemo(() => [
    // checkbox selection (action col type)
    {
      field: 'actions',
      type: 'actions',
      hide: true,
      width: 50,
      headerClassName: 'actions',
      renderHeader: (params) => {
        const indeterminate = params.colDef.indeterminate
        // const indeterminate = pinnedRowsIds.top.length > 0 && pinnedRowsIds.top.length < rows.length;
        // Conditionally render the checkbox in the header
        const handleChange = (event) => {
          handleSelectAllChange(params);
        };
        return (
          <CustomCheckboxHeader
            indeterminate={indeterminate}
            onChange={handleChange}
          />
        ); // Hide header checkbox if nothing is selected
      },
      // Updating this to be based on the internal grid state the way you had it before.
      renderCell: (params) => {
        // console.log(params.row.selectedRowNumber)
        const reOrderedArray = params.colDef.reOrderArray || [];
        const selectedRows = params.colDef.selectionArray || []; // this is CORRECT now. (we drive this from MUI table.)
        let isSelected = false;

        // console.log(isSelected)
        // console.log('reordered array')
        // console.log(reOrderedArray)

        // console.log('selected rows')
        // console.log(selectedRows)

        let selectedRowNumber = '';
        if(reOrderedArray.length>0 || reOrderedArray===null){
          isSelected = reOrderedArray.includes(params.row.parcel_id);
          selectedRowNumber = reOrderedArray.indexOf(params.row.parcel_id) + 1;
        }
        // its ogttat be from this
        else if(selectedRows.length > 0){ // if > 0 you have as a user picked things.
          console.log('setting selected value from the rows block.')
          isSelected = selectedRows.includes(params.row.parcel_id);
          selectedRowNumber = selectedRows.indexOf(params.row.parcel_id) + 1;
        }

        // else{
        //     selectedRowNumber = savedComps.indexOf(params.row.parcel_id) + 1
        // }
        
            return <CustomCheckboxCell 
            checked={isSelected}
            selectedRowNumber={params.row.selectedRowNumber||selectedRowNumber}
            />
      }
    },

    { // Prop info - address, sd, parcel id
      align: "left",
      editable: false,
      field: "PropertyInfo",
      headerName: "Address",
      minWidth: 120,
      flex: 1, // Allows the column to grow and shrink as needed
      valueGetter: (params) => params.Address, // updated this so you can search and filter by address (use value getter for this function)
      renderCell: (params) => 
      (
        <PropertyInfoTooltip
          address={params.row.PropertyInfo.Address}
          parcelId={params.row.PropertyInfo.parcel_id}
          SDName={params.row.PropertyInfo.SDName}
          SDCode={params.row.PropertyInfo.SDCode}
          town={params.row.PropertyInfo.Town}
          zip={params.row.PropertyInfo.Zip}
        />
      ),
    },

    { // Town
      editable: true,
      field: "Town",
      headerName: "Town",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.6, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // SDName
      editable: true,
      field: "SDName",
      headerName: "SD Name",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      flex: 0.6, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // Zip
      editable: true,
      field: "ZipCode",
      headerName: "Zip",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.5, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // PID
      editable: false,
      field: "parcel_id",
      headerName: "PID",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.9, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // PCC
    editable: false,
    field: "PCC",
    headerName: "PCC",
    type: "string",
    flex: 0.2, // Adjust this value based on proportion from the old width
    },

    { // Style
    editable: true,
    field: "Style",
    headerName: "Style",
    type: 'singleSelect',
    align: "left",
    // list comprehensive value options here
    valueOptions: getStyleOptions,
    hide: false,
    flex: 0.8, // Adjusted based on the old width of 100
    // cellClassName: (params) => console.log(params)
    },

    { // View
      editable: true,
      field: "View",
      headerName: "View",
      type: 'singleSelect',
      align: "left",
      // list comprehensive value options here
      valueOptions: getViewOptions,
      hide: false,
      flex: 0.7, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // Yr
    editable: true,
    field: "YearBuilt",
    headerName: "Yr",
    type: "number",
    flex: 0.5, // Adjust this value based on proportion from the old width
    valueGetter: (value) => {
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value === '') return '';
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
      const [integerPart, decimalPart] = parsedValue.toString().split('.');
      if (decimalPart && decimalPart.length > 4) {
        return `${parsedValue.toFixed(0)}*`;
      }
      return integerPart;
      }
      return '';
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Sqft
    editable: true,
    field: "Sqft",
    headerName: "Sqft",
    type: "number",
    hide: false,
    flex: 0.6, // Adjusted based on the old width of 70
    valueGetter: (value) => {
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value === '') return '';
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
      const formattedValue = Math.round(parsedValue).toLocaleString()
      const [integerPart, decimalPart] = parsedValue.toString().split('.');
      if (decimalPart && decimalPart.length >= 2) {
        return `${formattedValue}*`;
      }
      return parsedValue;
      }
      return '';
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Acres
    editable: true,
    field: "Acres",
    headerName: "Acres",
    type: "number",
    minWidth: 60,
    flex: 0.6, // Adjusted based on the old width of 55
    valueGetter: (value) => {
      
      // if the value has an asterisk, just return the value.
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value === '') return '';
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
      const [integerPart, decimalPart] = parsedValue.toString().split('.');
      if (decimalPart && decimalPart.length > 4) {
        return `${parsedValue.toFixed(2)}*`;
      }
      return parsedValue.toFixed(2);
      }
      return '';
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Full Baths
      editable: true,
      field: "fullBaths",
      headerName: "Full Baths",
      type: "number",
      flex: 0.6, // Adjusted based on the old width of 6
    },

    { // Half Baths
    editable: true,
    field: "halfBaths",
    headerName: "Half Baths",
    type: "number",
    flex: 0.6, // Adjusted based on the old width of 6
    },

    { // Bathrooms
    editable: false,
    field: "totalBaths",
    headerName: "Baths",
    type: "number",
    flex: 0.4, // Adjusted based on the old width of 40
    valueGetter: (value) => {
      // made it so BR return just blank asterisk if zero
      // if the value has an asterisk, just return the value.
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value == 0) return '0*';
      const parsedValue = Number(value);
      return parsedValue.toFixed(0);
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Assessment
    editable: true,
    field: "PropertyAssessment",
    headerName: "Assessment",
    type: "number",
    flex: 1, // Adjusted based on the old width of 100
    valueGetter: (value) => Number(value).toLocaleString() || '',
    },

    { // IFMV
    editable: false,
    field: "IFMV",
    headerName: "IFMV",
    type: "number",
    flex: 0.7, // Adjusted based on the old minWidth of 90
    valueGetter: (value) => {
      if (!isNaN(value)) { 
      return Number(value.toFixed(0)).toLocaleString() || '';
      }
    },
    },

    { // Sale Price
    editable: true,
    field: "SalePrice",
    headerName: "Sale Price",
    type: "number",
    flex: 0.8, // Adjusted based on the old minWidth of 90
    valueGetter: (value) => Number(value).toLocaleString() || '',
    },

    { // Sale Date
    editable: true,
    field: "SaleDate",
    headerName: "Sale Date",
    type: "date",
    flex: 0.8, // Adjusted based on the old minWidth of 90
    valueGetter: (value) => new Date(value) || '',
    },

    { // MLS number
      editable: true,
      field: "MLSNumber",
      headerName: "MLS Number",
      type: "number",
      flex: 0.7, // Adjusted based on the old minWidth of 90
      // value gettter that displays blank string if the value is 'None'
      valueGetter: (value) => {
        if(value==='None'){
          return '';
        }
        return value || '';
      },
    },

    { // Adj Sale
    editable: false,
    field: "adj_price",
    headerName: "Adj Sale",
    type: "number",
    flex: 0.8, // Adjusted based on the old width of 90
    valueGetter: (value) => {
      if (!isNaN(value) && value !== '') { 
      return Number(value.toFixed(0)).toLocaleString() || '';
      }
      // here you define the value for subject if hasn't sold.
      return 'None';
    },
    renderCell: (params) => (
      <div className="flex group justify-end h-full">
      <div className="hidden w-6 group-hover:flex relative items-center justify-center">
        <IconButton
        className='mr-0'
        onClick={() => handleAddAdj(params.row.parcel_id)}
        sx={{ padding: '1px', fontSize: '0.5rem'}}
        >
          <AddIcon />
        </IconButton>
      </div>
     
    {selectedAdjustments[params.row.parcel_id] ?
    // <>
    // {/* have an onhover tooltip over this span */}
    // <Tooltip title='Adjustment: $100'>
    // <span className='mr-2 text-orange-500'>
    //   $
    // </span>
    // </Tooltip>
    // {params.value}
    // </>
    // could just have this show up after the conditionality.
    <div className='flex font-bold justify-end items-center'>
    {params.value}
    </div>
     : 
     <div className='flex font-bold justify-end items-center'>
     {params.value !== undefined && params.value !== null && params.value !== '' ? 
     <span className='justify-end'>
     {params.value}
     </span>
     : 
     'Error'}
   </div>
    }
  </div>
    ),
    },
    { // Distance
    editable: false,
    field: "Distance",
    headerName: "Dist (Mi)",
    type: "number",
    flex: 0.6, // Adjusted based on the old width of 100
    valueGetter: (value) => {
      if (!isNaN(value) && value !== '') { 
      return Number(value.toFixed(2)) || 0;
      }
    },
    },
  ], [getStyleOptions, imputedValueCheck, selectedAdjustments, rowUpdate, savedComps]);

  // use this to trigger renders within the table - if there exists a selected adj for a parcelID, then display a green $ sign
  // next to sale px. See if this top level rerender is performant enough for now.
  const [displayModal, setDisplayModal] = useState(false);

  const toggleDisplayModal = useCallback(() => {
    setDisplayModal((prev) => !prev);
  }, []);

  // const handleAddAdj = useCallback((parcelId, value) => {
  //   // currently, updating these adjustments causes a re-render of the whoel grid. Not sure a way around atm.
  //   // if you don't include the state of selectedAdjustments in this callback, will it be incorrect when you call it from child?
    // console.log(selectedAdjustments)
  //   console.log('adding adjustment')
  //   // first set the state which displays a popup for the user to input adjustment information
  //   // Update the ref to track the adjustment

  //   // render a modal, and pass in the specific PID
  //   setDisplayModal(true)
  //   // Use state to trigger a visual update if needed
  //   // setSelectedAdjustments((prev) => ({
  //   //   ...prev,
  //   //   [parcelId]: value,
  //   // }));
  // }, []);
// }, [selectedAdjustments]);
// const [hoveredCellId, setHoveredCellId] = useState(null);

    // const updateCaseNotes = React.useCallback(() => {
    //   if (caseObject.CaseNotes && caseObject.CaseNotes !== caseNotesRef.current) {
    //     caseNotesRef.current = caseObject.CaseNotes;
    //     setCaseNotes(caseObject.CaseNotes);
    //   } else if (!caseObject.CaseNotes && caseNotesRef.current !== '') {
    //     caseNotesRef.current = '';
    //     setCaseNotes('');
    //   }
    //   // check if memoized selected comps is an array
    //   // if(Array.isArray(memoizedSelectedComps)){
    //   //   setSelectedComps(null)
    //   // }
    // }, [caseObject.CaseNotes]);
    
    // I haven't rethought since this was original hacked tg. Am pretty sure this casenotes update flow is retarded. Working for now.
    // // Trigger this update when necessary (e.g., in response to a user action or another effect)
    // useEffect(() => {
    //   updateCaseNotes(); // Call the memoized function inside useEffect
    // }, [comp, updateCaseNotes]); // Ensure both are dependencies

    const handleChangeNotes = useCallback((value) => {
      // the lower level function that updates this notes
      caseNotesRef.current = value;
      setCaseNotes(value);
    }, []);

    // useEffect(()=>{
    //   caseNotesRef.current = caseNotes;
    // },[caseNotes])

    console.log(savedComps)
    console.log('saved comps')

    // this function is only iterating over the regular comps. Is this a problem?
    const memoizedLatLongs = useMemo(() => { 
      let latLongs;
      const compsToDisplay = memoizedSelectedComps ? memoizedSelectedComps : savedComps;

      console.log(compsToDisplay)
      // If there are no saved comps, return the first 5 entries from `compObject`        
      if (compsToDisplay.length === 0) {
        console.log('no comps saved, displaying top 5')
        latLongs = compObject.parcel_id.slice(0, 1).map((parcelId, index) => ({
          parcel_id: parcelId,
          latitude: compObject.Latitude[index] || null,
          longitude: compObject.Longitude[index] || null,
        }));
      } else {
        // If there are saved comps or selected comps, use those
        // Map over compsToDisplay to get the lat/long values
        latLongs = compsToDisplay.map(parcelId => {
          const index = compObject.parcel_id.indexOf(parcelId);
          if (index !== -1) {
            return {
              parcel_id: parcelId,
              latitude: compObject.Latitude[index],
              longitude: compObject.Longitude[index],
            };
          } else {
            return { parcel_id: parcelId, latitude: null, longitude: null };
          }
        });
      }
    
      // Always append the first lat/long entry from compObject
      const firstParcelId = compObject.parcel_id[0];
      const firstIndex = compObject.parcel_id.indexOf(firstParcelId);
    
      const firstLatLong = {
        parcel_id: firstParcelId,
        latitude: firstIndex !== -1 ? compObject.Latitude[firstIndex] : null,
        longitude: firstIndex !== -1 ? compObject.Longitude[firstIndex] : null,
      };
    
      // Return a new array with the firstLatLong added at the beginning
      return [firstLatLong, ...latLongs];
      
    }, [savedComps, compObject, memoizedSelectedComps]);

    const regularAvg = useMemo(() => {
      const regularAvg = regularComps.adj_price.slice(0, 5).reduce((acc, price) => acc + price, 0) / 5;
      return regularAvg;
    }, [comp, regularComps]);

    // can break this function up to memoize the value individuall (specifically for saved comps this would be smart)
    const compAvg = useMemo(() => {
      // console.log('calculating px')
      // take the average of selected, top 5 or saved comps.
      if (Array.isArray(selectedComps)) {
        // lookup selected comps from within comps.parcel_id array and extract the adj prices for each, thjen get avg.
        // if its an empty array, take the default top 5.
        if(selectedComps.length === 0){
          const compPrices = comps.adj_price.slice(0, 5);
          const sum = compPrices.reduce((acc, price) => acc + price, 0);
          return sum / compPrices.length;
        }else{
        const selectedCompPrices = selectedComps.map((parcelId) => {
          const index = comps.parcel_id.indexOf(parcelId);
          if (index !== -1) {
            return comps.adj_price[index];
          }
          return 0;
        });
        const sum = selectedCompPrices.reduce((acc, price) => acc + price, 0);
        return sum / selectedCompPrices.length;
        }
      }
    
      // if saved comps, then take their average.
      if(savedComps.length > 0){
        // console.log('There are previously saved comps')
        const selectedCompPrices = savedComps.map((parcelId) => {
          const index = comps.parcel_id.indexOf(parcelId);
          if (index !== -1) {
            return comps.adj_price[index];
          }
          return 0;
        });
        const sum = selectedCompPrices.reduce((acc, price) => acc + price, 0);
        return sum / selectedCompPrices.length;
      }else{
        // console.log('you have no saved comps, displaying top5.')
        // display default top5.
        const compPrices = comps.adj_price.slice(0, 5);
        const sum = compPrices.reduce((acc, price) => acc + price, 0);
        return sum / compPrices.length;
      }

    }, [comps, selectedComps]);

    // FOR MAP - work w/ this.
    // console.log(memoizedLatLongs)
    // no longer used because you moved headers down into the table component.

    const handleSelectAllChange = useCallback((params) => { // this function doesn't really do anything it could be fully internal in headers.
      // update your internal datagrid state (this is a little hacky) to be cleared out.
      console.log('removing selected comps')
      console.log(params)
      const apiRef = params.colDef.apiRef.current
      console.log(apiRef)
      // here, have it go through the selectionArray and set selected to false, then clear rray
      // const selectedRowIds = params.colDef.selectionArray || [];

      // Update each row and insert in order based on updatedSelectedRowIds
      // selectedRowIds.forEach((rowId, index) => {
      //   const row = rows.find(r => r.parcel_id === rowId); // Find the row based on rowId
      //   if (row) {
      //     const updatedRow = {
      //       ...row,
      //       isSelected: false, // Set `isSelected` based on the new array
      //       rowIndexPosition: 0, // Use index + 1 to maintain the order
      //       selectionArray: [],
      //       selected: false,
      //     };
      //     console.log(updatedRow)
      //     apiRef.current.updateRows([updatedRow]); // Update row in the grid
      //   }
      // });
      // console.log(apiRef)
      params.colDef.indeterminate = false
      params.colDef.selectionArray = []
      const column = apiRef.getColumn('actions')
      setSelectedComps([])
      setRowUpdate(!memoizedRowUpdate) // force a state update at lower level to clear out its local state in addition to this interal grid state.
      apiRef.updateColumns([column]);

    },[memoizedRowUpdate, setRowUpdate]);

    const createSubjectRows = () => {
      // Construct and return a single object instead of an array
      return {
        PropertyInfo: {
          Address: subject.Address,
          parcel_id: subject.parcel_id,
          SDName: subject.SDName,
          SDCode: subject.SDCode,
        },
        parcel_id: subject.parcel_id,
        Town: subject.Town,
        ZipCode: subject.ZipCode,
        // If you want you can add in settlement information here.
        Name: caseObject?.Name || '',
        ScarIndex: caseObject?.SCARIndexNumber || '',
        PropertyAssessment: subject.PropertyAssessment,
        PCC: subject.PCC,
        // View needs to be updated to add other fields w/ a dict.
        View: subject.Waterfront === 1 ? 'Waterfront' : subject.Waterview === 1 ? 'Waterview' : '',
        SDName: subject.SDName,
        SDCode: subject.SDCode,
        Style: subject.Style,
        YearBuilt: subject.YearBuilt,
        Sqft: subject.Sqft,
        Acres: subject.Acres,
        fullBaths: subject.Bath,  // Use correct key
        halfBaths: subject.HalfBath,  // Use correct key
        totalBaths: subject.Bath + (subject.HalfBath / 2),  // Use correct keys
        IFMV: subject.IFMV,
        SalePrice: subject.SalePrice,
        MLSNumber: subject.MLSNumber,
        SaleDate: subject.SaleDate,
        adj_price: subject.adj_price,
        'Time adj': subject['Time adj'] || '',  // Use optional chaining to handle missing keys
        Distance: subject.Distance,
        RepId: subject.RepID ?? '',
      };
    };

    const createRows = () => {
      // Assuming all arrays within `comps` have the same length, we can use any of the arrays, e.g., comps.Address.length
      const length = comps.Address.length;
    
      return Array.from({ length }, (_, index) => ({
        id: index,
        '#': index + 1, // Index starting from 1 for user readability
        PropertyInfo: {
          Address: comps.Address[index],
          parcel_id: comps.parcel_id[index],
          SDName: comps.SDName[index],
          SDCode: comps.SDCode[index],
          Town: comps.Town[index],
          Zip: comps.ZipCode[index],
        },
        // Address: comps.Address[index],
        parcel_id: comps.parcel_id[index],
        Town: comps.Town[index],
        ZipCode: comps.ZipCode[index],
        PropertyAssessment: comps.PropertyAssessment[index],
        PCC: comps.PCC[index],
        View: comps.Waterfront[index] === 1 ? 'Waterfront' : comps.Waterview[index] === 1 ? 'Waterview' : '',
        SDName: comps.SDName[index],
        SDCode: comps.SDCode[index],
        Style: comps.Style[index],
        YearBuilt: comps.YearBuilt[index],
        Sqft: comps.Sqft[index],
        Acres: comps.Acres[index],
        fullBaths: comps.Bath[index],
        halfBaths: comps.HalfBath[index],
        totalBaths: comps.Bath[index] + (comps.HalfBath[index] / 2),
        IFMV: comps.IFMV[index],
        SalePrice: comps.SalePrice[index],
        SaleDate: comps.SaleDate[index],
        MLSNumber: comps.MLSNumber[index],
        adj_price: comps.adj_price[index],
        'Time adj': comps['Time adj']?.[index] || '', // Optional chaining to handle missing keys
        Distance: comps.Distance[index],
        selected: savedComps.includes(comps.parcel_id[index]),
        isSelected: savedComps.includes(comps.parcel_id[index]),
        status: comps.adj_price[index] > subject.IFMV ? 'negative' : 'positive',

      }));
    };

    // is this the right dependency, or is it comp?
    const subjectRow = useMemo(() => {
      // console.log('rerender subject row')
      return(
      createSubjectRows()
    )
    }, [comps]);
    
    const handleSaveComps = useCallback(async (selectedRowIds) => {
      console.log(selectedRowIds) // 
      // you will also need to pass up any miscellaneous adjustments you create here.
      // save button doesn't disappear on reload? Might be a dev only issue.
      // log the userinfo (ID specifically for DB write) from localstorage
      const userObject = JSON.parse(localStorage.getItem('userInfo'))
      const isArrayofObjects = selectedRowIds.every(item => typeof item === 'object' && item !== null);

      // If it's an array of objects, process it as before; otherwise, use it directly
      const compsObject = isArrayofObjects
        ? selectedRowIds.reduce((acc, value, index) => {
            acc[index + 1] = value.parcel_id;  // map the index to the value
            return acc;
          }, {})
        : selectedRowIds;
    
      console.log(compsObject)

      // can put that state into this object as part of the adjustment
      console.log(village)

      const saveObject = {
        ManualReviewFlag: 1, 
        ManualOverride: 1, // this may only be relevant in the saveall version of function?
        Cases: [ // pass in as an object so the function works to be modular to save all too.
          {
          Subject: subjectRow.parcel_id,
          Comps: compsObject,
          CompAdjustments: {},
          // include comps adjustments in comp Object? what is structure?
          CaseNotes: caseNotesRef.current,
          RepId: subjectRow.RepId !== "" ? parseInt(subjectRow.RepId, 10) : null,
          },
        ],
        UserId: userObject.userId || null,
        VillageFlag: village!=='All' ? 1 : 0,
        TaxYear: taxYear,
      }

      console.log(saveObject)

      const newComps = await saveComps(saveObject)
      console.log(newComps) // make sure u can properly return whatever value. (can just be t/f for erro rhandling)
      // apiRef.current.updateColumns([column]);
      const updatedNegotiationObj = {...getNegotiationObj}; // set this equal t
      updatedNegotiationObj.cases[comp].Comps = compsObject
      updatedNegotiationObj.cases[comp].CaseNotes = caseNotesRef.current
      const today = new Date();
      updatedNegotiationObj.cases[comp].RunDate = today.toUTCString();
      console.log(updatedNegotiationObj)
      setNegotiationObj(updatedNegotiationObj)
      setRowUpdate(!rowUpdate)
      // setRowUpdate(!memoizedRowUpdate) // force a state update at lower level to clear out its local state in addition to this interal grid state.
    }, [ subjectRow, rowUpdate]);

    // memoize rows
    const rows = useMemo(() => createRows(), [comps, rowUpdate]);

//   LOOK INTO NEW MAPPING LIBRARY HERE - see if you ccan also impose a side streetview partition in the
    return (
      <div className='h-full'>

        {displayModal && 
        // remove this from here, it shoul dbe its own component.
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50'>
          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-lg'>
            <div className='flex justify-between'>
              <h1>Adjustment</h1>
              <button onClick={() => setDisplayModal(false)}>X</button>
            </div>
            {/* input name for adj */}
            <div>
              <input type='text' placeholder='Adjustment'></input>
            </div>

            {/* input adj type */}

            {/* add a submit to log the adjustment */}
          </div>
        </div>
            }


            {/* here, based on if map is rendered, render the table in diff sizes. (just do classname update) */}
            <div className='h-full flex flex-col'>
              {/* <div className='flex-grow'>
              </div> */}
        <Map
        // Redo this, passing inteh subject Lat / long, and comp lat / long (can just pass int he object for now?)
          latLongs={memoizedLatLongs}
        />
        {/* render the map here, passing in the subject, and the comps (some # of comps) */}
        {/* here, will setting casenotes cause a whole re-render fuckfest? */}
        <div className='h-1/2'>
          <TableCustomToolbar 
          compAvg={compAvg}
          caseNotes={caseNotes}
          changeCaseNotes={handleChangeNotes}
          view={view}
          handleUpdateStateCallback={handleUpdateStateCallback}
          // Set rundate to today if it doesn't exist
          runDate={caseObject?.RunDate || new Date().toLocaleDateString('en-US')}
          regularAvg={regularAvg}
          compsSelected={selectedComps}
          savedComps={savedComps}
          viewCallback={viewCallback}
          globalCompRef={globalCompRef}
          // do yoiu need to memoize these selected comps? itll lrerender every time even if not bool flip.;
          />

        {/* {getCompSheet[comp] && ( // this syntax superior to ternarry, it will render this when true. */}
          <MuiTable 
            subject={subjectRow}
            compStreamObject={compStreamObject}
            handleUpdateStateCallback={handleUpdateStateCallback}
            handleSaveComps={handleSaveComps}
            originalRows={rows} // These may need to be memoized
            resetUpdateArrayCallback={resetUpdateArrayCallback}
            updatedArray={updatedArray}
            view={view}
            savedCompPids={savedComps}
            rowUpdate={memoizedRowUpdate}
            headers={headers}
            toggleDisplayModal={toggleDisplayModal}
            tableProps={tableProps}
            adjustmentRef={adjustmentRef}
            updateSelectedComps={handleUpdateSelectedComps}
          />
      </div>
    </div>
      </div>
    );
};
export default CaseReview