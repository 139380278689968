import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useSearchParams, useLocation, NavLink } from 'react-router-dom';
import {useCompStore, useAdminSettingsStore, useLocalDataStore, usePersistedDataStore} from '../store.js';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import {handleApiError} from '../../common/handleApiError.js'
import axiosInstance from '../../axiosConfig.js'
import _, {isEqual} from 'lodash';
import MuniSelection from './MuniSelection.js'
import CircularProgress from '@mui/material/CircularProgress';
import ReviewSettlements from './ReviewSettlements.js';

function CompDashboard({setLoading, nyDataObj, setWeightWidget, yearDropdownOptions, globalCompRef}){
  // setloading can be deleted once your save comps is fixed.
    const setCompSheet = usePersistedDataStore((state) => state.setCompSheet) // set comps
    const getCompSheet = usePersistedDataStore((state) => state.compSheet)
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
    const getCaseNotes = usePersistedDataStore((state) => state.caseNotes);
    const setCaseNumber = usePersistedDataStore((state) => state.setCaseNumber)
    const getFetchLoad = usePersistedDataStore((state)=> state.fetchLoad)
    const setFetchLoad = usePersistedDataStore((state)=> state.setFetchLoad)
    const getOptimizedComps = usePersistedDataStore((state)=> state.optimizedComps)
    const setOptimizedComps = usePersistedDataStore((state)=> state.setOptimizedComps)
    const setPopup = useAdminSettingsStore((state) => state.setPopup)
    const setCourtMunis = usePersistedDataStore((state)=> state.setCourtMunis)
    const getDefaultComps = useAdminSettingsStore((state) => state.defaultComps)
    const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
    const setReviewPage = useLocalDataStore((state)=>state.setReviewPage)
    const [displayCustom, setDisplayCustom] = useState(false)
    const navigate = useNavigate();
    const [addedPID, setAddedPID] = useState(null)
    const [addIndex, setAddIndex] = useState(null)
    const getCourtComps = usePersistedDataStore((state) => state.courtComps);
    const setCourtComps = usePersistedDataStore((state) => state.setCourtComps);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
    const MuniCode = queryParams.get('MuniCode') || 'All';
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const village = queryParams.get('village') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
    const view = queryParams.get('view') || 'regular';
    const [upcomingCourtDates, setUpcomingCourtDates] = useState([])


    // this component is probably inefficient because of the work it does on each render

    async function fetchCourtDates() { // this will cause a reload in this component, because it runs then sets state.
      // This should really be fetched way higher up in tree, and passed down.
      try {
        const courtResponse = await axiosInstance.get('/load_court_dates');
        const courtDates = Object.keys(courtResponse.data);
        courtDates.unshift('');
        setUpcomingCourtDates(courtDates); // do you need to set this tate here? can you just pass it down?
      } catch (error) {
        console.error('Error fetching court dates:', error);
        // Handle error if needed
      }
    }
    
    const stillLoading = useMemo(() => {
      // Default values
      console.log('updating loading val')
      // Initialize to true if getCompSheet is empty
      if (getCompSheet.length === 0) return true;
    
      // Logic to determine loading state
      return (
        getCompSheet.length < getNegotiationObj.cases.length
      );
    }, [getCompSheet]);

      // you should do this fetch higher up, no real reasoon to rerender this component.
    useEffect(() => {
        fetchCourtDates()
    }, []);

    // Here, compare the length of ur globalCompsRef to the getCompSheet length. If they are different, then you need to update the compsheet.
    // perform the update by calling the setCompSheet function, but set the value overriding the gloobalcompsheet with whatever you have in the compSheet, and for the undefined ones, take the value of the globalcompRef
    function nextSubj(){ // only this function writes to the database
      const url = new URL(location.pathname+location.search, window.location.origin);
      const searchParams = new URLSearchParams(url.search);
      const comp = parseInt(queryParams.get('comp'));
       if(comp<getCompSheet.length){ // run normal function if you're not on last comp.
        // Here, as you move, update your higher level state object with sequential fetch runs.
        if (globalCompRef.current?.properties[0].length > getCompSheet.length) {
          console.log('updating to match our ref object')
          const updateCompSheet = [...getCompSheet];
          const updateOptimizedComps = [...getOptimizedComps];
      
          // Append new values from properties if they exceed the current length
          const newCompSheet = globalCompRef.current.properties[0].slice(updateCompSheet.length);
          console.log('comps to add in from new sheet: ', newCompSheet);
          
          // Use spread operator to append new values without mutating the array
          const mergedCompSheet = [...updateCompSheet, ...newCompSheet];
          console.log('the updated compsheet post merge: ', mergedCompSheet);
          
          const newOptimizedComps = globalCompRef.current.properties[1].slice(updateOptimizedComps.length);
          const mergedOptimizedComps = [...updateOptimizedComps, ...newOptimizedComps];
          console.log('the updated optimized compsheet post merge: ', mergedOptimizedComps);
          //   console.log(testNegotiationObj)

          // i want to update the negotiation Object so it has address, town and zip in the cases.
          // it is absurdly stupid for this to happen on the frontned. But quickest way.
          setCompSheet(mergedCompSheet);
          setOptimizedComps(mergedOptimizedComps);
          // setNegotiationObj(testNegotiationObj);
        }
        // change the index of this for non-array operations.
        searchParams.set('comp', comp+1);
        const newUrlString = `${url.pathname}?${searchParams.toString()}`;
        navigate(newUrlString);
      }
      // may need to do the same +1 to the comp here since this t
      else if(comp===getNegotiationObj.cases.length){ // on last comp, write to DB and show summary page.
        console.log(comp)
        const updatedNegotiationObj = {
          ...getNegotiationObj,
          cases: getNegotiationObj.cases.map((caseObj, index) => {
            return {
              ...caseObj,
              Address: getCompSheet[index].Address[0],
              Town: getCompSheet[index].Town[0],
              ZipCode: getCompSheet[index].ZipCode[0],
            };
          })
        };
        setNegotiationObj(updatedNegotiationObj);
        // Don't do anything else if you are at the end of the sheet.
        // review page doesn't work rn? it goes + at the right time ( I think?)
        setReviewPage(true)
      }
      
    }
    function priorSubj(){
      const url = new URL(location.pathname+location.search, window.location.origin);
      const searchParams = new URLSearchParams(url.search);

      if(getReviewPage){
        setReviewPage(false)
      }else if(comp===0){ 
        return // don't do anything if at the beginning of list.
      }else{
        searchParams.set('comp', comp);
      }
        // searchParams.set('view', 'regular');
        const newUrlString = `${url.pathname}?${searchParams.toString()}`;
        navigate(newUrlString);
    }

  function handleAddComp(){ // add a comp into your matrix - this function is retarded. Just pass in the input for diff views and saving function. no need for ifs.
    // setLoading(true)
    // This function is also insanely inefficient traverses tons of arrays & nested obj's unnecessarily.

    if(view==='optimized'){ // add comp in optim view
      const updatedCompSheet = [...getOptimizedComps]
      // Foreach to shift every single one.
      Object.entries(updatedCompSheet[comp]).forEach(([key, value]) => {
        if(key==='price_adjustments'){
          Object.entries(updatedCompSheet[comp][key]).forEach(([key2,value]) => {
            // Truthfully, I think this entire adjustments array is completley unnecessary given our current codebase, but wanted to re-order it just in case.
            updatedCompSheet[comp][key][key2].splice(addIndex, 0, '');
          })
        }
        else if(key!=='Regular_midpoint' && key!== 'Regular_straight_average' && key!=='Optimized_midpoint' && key!=='Optimized_straight_average' && key!=='minimum_optimized_value'){ // every case except for price adjustments.
          const targetArray = updatedCompSheet[comp][key];
            if (Array.isArray(targetArray)) {
            if(key==='parcel_id'){
              targetArray.splice(addIndex, 0, addedPID); // Insert addedPID at addIndex
            }else{
              targetArray.splice(addIndex, 0, ''); // Insert addedPID at addIndex
            }
            // Update the object with the modified array
            updatedCompSheet[comp][key] = targetArray;
            setOptimizedComps(updatedCompSheet)
            // console.log(updatedCompSheet[comp])
            }
        }
      });
    }else if(view==='regular'){ // add comp in regular view
      const updatedCompSheet = [...getCompSheet]
      // Foreach to shift every single one.
      Object.entries(updatedCompSheet[comp]).forEach(([key, value]) => {
        if(key==='price_adjustments'){
          Object.entries(updatedCompSheet[comp][key]).forEach(([key2,value]) => {
            // Truthfully, I think this entire adjustments array is completley unnecessary given our current codebase, but wanted to re-order it just in case.
            updatedCompSheet[comp][key][key2].splice(addIndex, 0, '');
          })
        }
        else if(key!=='Regular_midpoint' && key!== 'Regular_straight_average' && key!=='Optimized_midpoint' && key!=='Optimized_straight_average' && key!=='minimum_optimized_value'){ // every case except for price adjustments.
          const targetArray = updatedCompSheet[comp][key];
            if (Array.isArray(targetArray)) {
            if(key==='parcel_id'){
              targetArray.splice(addIndex, 0, addedPID); // Insert addedPID at addIndex
            }else{
              targetArray.splice(addIndex, 0, ''); // Insert addedPID at addIndex
            }
            // Update the object with the modified array
            updatedCompSheet[comp][key] = targetArray;
            setCompSheet(updatedCompSheet)
            }
        }
      });
    }else if (view==='court'){
      console.log('running court comps');
      const updatedCompSheet = [...getCourtComps];
  
      // Iterate over the entries of the specific comp
      Object.entries(updatedCompSheet[comp]).forEach(([key, value]) => {
          if (key === 'price_adjustments') {
              Object.entries(updatedCompSheet[comp][key]).forEach(([key2, value]) => {
                  // Insert empty string at addIndex and shift remaining elements
                  updatedCompSheet[comp][key][key2].splice(addIndex, 0, '');
              });
          } else if (key !== 'Regular_midpoint' && key !== 'Regular_straight_average' && key !== 'Optimized_midpoint' && key !== 'Optimized_straight_average' && key !== 'minimum_optimized_value') {
              const targetArray = updatedCompSheet[comp][key];
  
              if (Array.isArray(targetArray)) {
                  if (key === 'parcel_id') {
                      targetArray.splice(addIndex, 0, addedPID); // Insert addedPID at addIndex
                  } else {
                      targetArray.splice(addIndex, 0, ''); // Insert empty string at addIndex
                  }
  
                  // Update the object with the modified array
                  updatedCompSheet[comp][key] = targetArray;
              }
          }
      });
  
      setCourtComps(updatedCompSheet); // this doesn't seem to re-render the thing?
      console.log(updatedCompSheet);
  }
  
    // setLoading(false)
  }
    async function handleFlagNoSCAR(){
      console.log(getCompSheet[comp].parcel_id[0])
      try{
      const optimizeResponse = await axiosInstance.post(`/flag_case_scar_status`, { // passing in the indexes for pagination
                  Subject: getCompSheet[comp].parcel_id[0],
                  TaxYear: getNegotiationObj.TaxYear,
                  filingStatus: 0,
                });
        toast.success(`Flagged case to not file at Scar.`, {
          position: 'top-left',
          className: 'mt-10',
          autoClose: 3000,
      });
    }catch{
      toast.error('some Error occured', {
        position: 'top-left',
        className: 'mt-10',
        autoClose: 3000,
      })
    }
  }   
    // Have the useEffect re-run when comp changes (and this should )
    function customInput(){
      setDisplayCustom(!displayCustom)
    }

    async function saveOneComp(Comparables) { // write just one comp into DB as you work through.
      // Merge this comp into the saveall function as well. No reason to have two.
      // pull comp saving way down in stack, out of the top level app
      console.log(Comparables)
      // This writes your comps into the SQL database (comparablesWK)
      const updateComps = []
      updateComps[comp] = _.cloneDeep(Comparables)
      for (let key in updateComps[comp]) {
        if (updateComps[comp].hasOwnProperty(key) && Array.isArray(updateComps[comp][key])) {
          if(key!=='Regular_midpoint' && key!== 'Regular_straight_average' && key!=='Optimized_midpoint' && key!=='Optimized_straight_average' && key!=='minimum_optimized_value'){ // every case except for price adjustments.
          updateComps[comp][key] = updateComps[comp][key].slice(0, 6);
        }
        }
      }
      console.log(updateComps)

      console.log('running')

      let village = queryParams.get('village') || ''; // Get the village parameter, or an empty string if not present
      if (village === 'All' || village === 'select' || village === '') {
        village = 0;
      } else {
        village = 1;
      }

      const resultObject = {
        Cases: {}, // Outer level to accumulate case objects
        CaseNotes: getCaseNotes[comp],
        TaxYear: taxYear,
        villageFlag: village,
        manualReview: true,
      };

      const {parcel_id} = Comparables;
        // User input + 1
        const truncatedParcelId = parcel_id.slice(1, getDefaultComps+1); // Take the first 5 comps of PID
        const subjId = parcel_id.slice(0, 1);

        // this accumulation object is unnecessary in the "save one comp" implementation, but may be used for multisave (?)
    
        const valueObject = {};
        const RepID = updateComps[comp]?.RepID?.[0] ?? null;
        const InitialVal = parseInt(getCompSheet[comp].Regular_straight_average); // don't need this

        for (let i = 0; i < truncatedParcelId.length; i++) {valueObject[(i + 1).toString()] = truncatedParcelId[i];}
        const caseObject = {[subjId]: { ...valueObject },
                            RepID: RepID==='' ? null : RepID,
                            InitialVal: InitialVal,
                            };
        // Accumulate each caseObject at the outer level
        resultObject.Cases = { ...resultObject.Cases, ...caseObject };
        
        console.log(resultObject)


      // Here - in resultObject - if you don't have comps >= default # then you need to add in "nulls"
      
      try { // post to write in comps
          setLoading(true);
          const updateComparables = await axiosInstance.post(`/update_comparables`, resultObject);
          const response = updateComparables;
          // Toast this response.data.message
          toast.success(`${response.data.message} ✅`, {
            position: 'top-left',
            className: 'mt-10',
            autoClose: 3000,
        });

        console.log(resultObject)
        // now update your local negotiation object state
        const updatedNegotiationObj = {
          ...getNegotiationObj,
          cases: getNegotiationObj.cases.map((caseObj, index) => 
            index === comp 
              ? {
                  ...caseObj,
                  Comps: resultObject.Cases[subjId]
                }
              : {...caseObj}
          )
        };

        console.log(updatedNegotiationObj)

        setNegotiationObj(updatedNegotiationObj)
        setCourtComps(updateComps)
        // getReviewedComps(updatedNegotiationObj)
        
        const url = new URL(location.pathname+location.search, window.location.origin);
        const searchParams = new URLSearchParams(url.search);
        searchParams.set('view', 'court');
        const newUrlString = `${url.pathname}?${searchParams.toString()}`;
        navigate(newUrlString);

      } catch (error) {
        handleApiError(error, 'Error writing to ComparablesWK MySQL table', setLoading);
      }
      setLoading(false)
      // console.log(resultObject);
    }

    function updateOffers(e) {
      // If settled === true, DON'T UPDATE:
      const { id } = e.target;
      const inputValue = e.target.value;
          const updatedNegotiation = { // clone the outside obj, and the interior cases array.
            ...getNegotiationObj,
            cases: [
              ...getNegotiationObj.cases
            ]
          }
          // Ensure that the element at comp exists or create it
          if (!updatedNegotiation.cases[comp]) { // create the object within the array.
            // console.log('Creatng new Obj - this case has no prior val')
            updatedNegotiation.cases[comp] = {settlement: ''};
          }
          if(updatedNegotiation.cases[comp].settled){
            return
          }else{
          if (inputValue === '') { // sets it blank without doing the comma conversion if the val is empty
            updatedNegotiation.cases[comp][id]=''; // or updateStateWithNumericValue(null);
            console.log(updatedNegotiation)
            setNegotiationObj(updatedNegotiation)
            } else {
            // Remove commas before updating the state
            // console.log(getCompSheet[comp].parcel_id[0])
            updatedNegotiation.cases[comp].PID = getCompSheet[comp].parcel_id[0]
            const numericValue = inputValue.replace(/[^0-9.]/g, '')
            updatedNegotiation.cases[comp][id] = numericValue
            setNegotiationObj(updatedNegotiation)
            console.log(updatedNegotiation)
          }
          }
      }


    function handleClear(){ // removes your states for offers and comps.
      const updatedNegotiation = { // clone the outside obj, create the interior cases array.
        ...getNegotiationObj,
        cases: []
      }
      setCompSheet('') // remove the compsheet
      setFetchLoad(false)
      updatedNegotiation.municipality = 'All'
      updatedNegotiation.county = 'All'
      updatedNegotiation.village = 'All'
      updatedNegotiation.MuniCode = null
      updatedNegotiation.courtDate = 'All'
      updatedNegotiation.CourtRun = 0
      updatedNegotiation.courtDateSelectedMuni = null
      // udpatedNegotiation.
      setCaseNumber(0)
      setOptimizedComps([])
      setReviewPage(false)
      setCourtMunis([])
      setNegotiationObj(updatedNegotiation) // remove all offers
      setCourtComps([])

      const url = new URL(location.pathname+location.search, window.location.origin);
      const searchParams = new URLSearchParams(url.search);
      // searchParams.set('view', 'regular');
      const newUrlString = `${url.pathname}?${searchParams.toString()}`;
      navigate(newUrlString);
      // SetCourt date
    }

    function setSettled(value){ // the .settlement is inherently set, becasuse it gets locked when this function runs.
      console.log(getNegotiationObj)
      const updatedSettlement = { // clone the outside obj, and the interior cases array.
        ...getNegotiationObj,
        cases: [...getNegotiationObj.cases]
        }

        if(updatedSettlement.cases[comp].settlement>getCompSheet[comp].IFMV[0]){
        toast.error('Settlement higher than current value!', { // display warning toast.
          position: toast.POSITION.TOP_RIGHT,
        });
        updatedSettlement.cases[comp].settlement = '' // and reset the settlement value to zero
      }else{
        updatedSettlement.cases[comp].settled=value
        // Should probably round the updated settlement vale to be
        console.log(getCompSheet[comp].RAR[0])
        console.log(updatedSettlement.cases[comp].settlement)
        // Calculate the Settled AV using RAR
        updatedSettlement.cases[comp].settledAV=updatedSettlement.cases[comp].settlement*getCompSheet[comp].RAR[0]
        
        // Set the type equal to 'S' -- may need to amend this to ST.
        updatedSettlement.cases[comp].type = 'ST'
      }
        setNegotiationObj(updatedSettlement)
    }

    function clearInputs(){ // eliminate settlement value
      const updatedNegotiation = { // clone the outside obj, and the interior cases array.
        ...getNegotiationObj,
        cases: [
          ...getNegotiationObj.cases
        ]
      }
      // Ensure that the element at comp exists or create it
      if (!updatedNegotiation.cases[comp]) { // create the object within the array.
        console.log('Creatng new Obj - this case has no prior val')
        updatedNegotiation.cases[comp] = {minOffer:'', maxOffer:'', settlement:'', settledAV:'', settled:false, type: ''};
      }
      updatedNegotiation.cases[comp].settlement = ''
      updatedNegotiation.cases[comp].settledAV = null
      updatedNegotiation.cases[comp].type = ''
      setNegotiationObj(updatedNegotiation)
    }

    return(
      <div className={`relative testing inline overflow-clip min-h-[400px]`}>
          {getCompSheet.length > 0 &&
          <div id='sticky' className="sticky top-0 flex justify-between items-center border-b-2 border-gray-500">
              <div className="flex flex-col  gap-3 sm:flex-row sm:items-center ">
                {/* Breadcrumbs below */}
                <nav>
                  <ol className="flex items-center gap-2">
                    <li className='flex mb-0.5'>

                      <NavLink to="/table" className='flex items-center justify-center mr-1 mt-0.5' onClick={()=>handleClear()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#65758B" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                        </svg>
                      </NavLink>
                    </li>
                    {/* Breadcrumb display - County / Muni / SCAR ER # */}
                    {
                    courtDate!=='' ?
                      <li className='flex'> {courtDate} - <span className={'text-primary ml-1'}> {MuniCode + ' ('+taxYear+')'}</span>
                      </li>
                    :
                    null
                    }

                    {/* Breadcrumb for county / muni */}
                    {MuniCode!=='All' && courtDate === ''?
                      <div className='flex text-center items-center'>
                        <li id='text' className="flex text-[#1976d2] font-medium mt-1" style={{ lineHeight: '1' }}>
                          {MuniCode + ' (' + taxYear + ')'}
                        </li>
                      </div>
                      :
                      null
                    }
                  </ol>
                </nav>
              </div>
          </div>
                      }

    </div>
)
}export default CompDashboard
