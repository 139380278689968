import React, { useState, useEffect, useReducer, useRef, useCallback, useMemo } from 'react';
import { DataGridPro, GridToolbar, useGridApiRef } from '@mui/x-data-grid-pro';
// import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';
import { darken, lighten, styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import DBConfirmModal from '../../common/DBConfirmModal';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import cloneDeep from 'lodash/cloneDeep';
import { useStreamProcessor } from '../../common/useStreamProcessor';
import DataGridContainer from './DataGridContainer';

// Memoize the getBackgroundColor function?
const getBackgroundColor = (color, theme, coefficient) => ({
  backgroundColor: darken(color, coefficient),
  ...theme.applyStyles('light', {
    backgroundColor: lighten(color, coefficient),
  }),
});

const handleCopy = (text, type) => {
  // here also include the address, town, ny, zip in the copy if address.

  navigator.clipboard.writeText(text)
    .then(() => {
      toast.success(`Copied ${type} to clipboard!`, {
        position: 'top-left',
        autoClose: 2000,
      });
    })
    .catch(err => {
      console.error('Failed to copy: ', err);
    });
};

export const PropertyInfoTooltip = ({ address, parcelId, SDName, SDCode, town, zip, subject=false, RepId }) => {
  const addressCopy = `${address}, ${town}, NY ${zip}`;
  return (

    <div style={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }} className='table-cell-truncate'>
      {/* Truncated address text */}
      <Tooltip
    title={
      <div>
          <div className='flex justify-between'>
          <span className="text-base">
            {address}
          </span>
        </div>
        <div>
          {subject && 
            <div className='flex flex-col'>
              <span className="text-xs">
                {subject.RepId!==''?subject.Name+` - REPID: ${subject.RepId}` : subject.Name} 
              </span>
              
              {subject.ScarIndex &&
              <span className="text-xs cursor-pointer" onClick={() => handleCopy(subject.ScarIndex, 'Scar Index')}>
                {subject.ScarIndex}
                <ContentCopyIcon
                  style={{ cursor: 'pointer', marginLeft: '6px', marginBottom: '4px', fontSize: '12px' }}
                />
              </span>
              }
            </div>
          }
          <span className="text-xs cursor-pointer" onClick={() => handleCopy(parcelId, 'PID')}>
            {parcelId}
          </span>

            <ContentCopyIcon
              onClick={() => handleCopy(parcelId, 'PID')}
              style={{ cursor: 'pointer', marginLeft: '6px', marginBottom: '4px', fontSize: '15px' }}
            />
        </div>
        <div>
          <span className="text-xs">
            {SDName} ({SDCode})
          </span>
        </div>
      </div>
    }
    arrow
  >
      <span
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} // Ensure text ellipsis
        className="flex-grow"
      >
        {address}
      </span>
      </Tooltip>
      {/* Info icon, always visible */}
      <ContentCopyIcon
        onClick={() => handleCopy(addressCopy, 'address')}
        style={{ cursor: 'pointer', marginLeft: '2px', marginBottom: '2px', fontSize: '14px' }}
      />
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return isEqual(prevProps.originalRows, nextProps.originalRows) &&
         isEqual(prevProps.subject, nextProps.subject) &&
         isEqual(prevProps.tableProps, nextProps.tableProps) &&
         isEqual(prevProps.rowUpdate, nextProps.rowUpdate) &&
         isEqual(prevProps.savedCompPids, nextProps.savedCompPids) &&
         isEqual(prevProps.handleSaveComps, nextProps.handleSaveComps) &&
         isEqual(prevProps.toggleDisplayModal, nextProps.toggleDisplayModal) &&
         isEqual(prevProps.updatedArray, nextProps.updatedArray);
};

const calculateFlex = (headers, columnVisibilityModel, containerRef) => {
  if (containerRef.current) {
    const totalWidth = containerRef.current.clientWidth;
    const contentWidth = totalWidth - 65; // Adjust as needed
    const totalFlex = headers.reduce((sum, header) => {
      if(header.field==='actions'){
        return sum
      }
      if (columnVisibilityModel[header.field] !== false) {
        return sum + (header.flex || 1);
      }
      return sum;
    }, 0);

    return headers.map(header => ({
      ...header,
      calcWidth: (header.flex || 1) / totalFlex * contentWidth,
    }));
  }
  return headers;
};

function MuiTable({ headers, view, resetUpdateArrayCallback, updatedArray, compStreamObject, originalRows, handleUpdateStateCallback, updateSelectedComps, subject = false, tableProps, rowUpdate, savedCompPids, toggleDisplayModal, handleSaveComps }) {
  const startingRows = cloneDeep(originalRows);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const comp = useMemo(() => parseInt(queryParams.get('comp')) - 1 || 0, [queryParams]);
  const [userUpdate, setUserUpdate] = useState(false);
  const [reordering, setReordering] = useState(false);
  const reOrderingRef = useRef(false);

  // Callback cleanup function for the stream processor
  const handleNewUpdate = useCallback((response) => {
    if(response === 1){ // if you get 1st response back, clear out the inventory updates.
    unsavedChangesRef.current = {
      originalSubject: {},
      unsavedRows: {},
      rowsBeforeChange: {},
      unsavedSubject: {},
    };
  }
  }, []);
  const { processStream, loading } = useStreamProcessor(handleNewUpdate);

  // console.log('fetch iteration:', fetchIteration)
  // Updated this to be based on your actual original rows instead of deep clone (for inventory update purposes)
  const { rows, pinnedRows } = useMemo(() => {
    if (view === 'regular') {
      const rowsData = [...originalRows]; // Just return all rows as is
      return {
        rows: rowsData,
        pinnedRows: { top: [], bottom: [] }, // Empty pinnedRows for 'regular' view
      };
    }
    if (!startingRows || !savedCompPids) return { rows: [], pinnedRows: { top: [], bottom: [] } };
    const savedCompPidsSet = new Set(savedCompPids); // Optimized lookup using Set
    const updatedRowsSet = userUpdate && userUpdate.length > 0 ? new Set(userUpdate) : new Set(); // Optimized lookup using Set
    const rowsData = [];
    const pinnedRowsData = { top: [], bottom: [] }; // Ensure that top and bottom are arrays
  
    // Use savedCompPids or userUpdate to dictate order of pinned rows
    const pinningSource = userUpdate ? userUpdate : savedCompPids;
  
    // Loop through pinningSource in order and push to pinnedRows
    pinningSource.forEach((pid) => {
      const row = originalRows.find((r) => r.parcel_id === pid);
      if (row) {
        row.selected = true;
        pinnedRowsData.top.push(row); // Adding to the top in the order they appear in the set
      }
    });
  
    // Add remaining rows that aren't pinned to rowsData
    originalRows.forEach((row) => {
      if (!pinningSource.includes(row.parcel_id)) {
        rowsData.push(row);
      }
    });
  
    return {
      rows: rowsData,
      pinnedRows: pinnedRowsData, // Ensure pinnedRowsData contains valid arrays
    };
  }, [originalRows, savedCompPids, userUpdate]);
  
  // comparison function if the user has chosen comps other than the saved ones.
  const isSame = useMemo(() => {
    if (!savedCompPids || !userUpdate) return true;
    return (
      savedCompPids.length === userUpdate.length &&
      savedCompPids.every((pid, index) => pid === userUpdate[index])
    );
  }, [savedCompPids, userUpdate]);
  // If this value is true, your comps are different.
  const isDifferent = useMemo(() => !isSame, [isSame]); // true if different, false if the same

  // const handleAddAdj = useCallback((parcelId, value) => {
  //   // currently, updating these adjustments causes a re-render of the whoel grid. Not sure a way around atm.
  //   // if you don't include the state of selectedAdjustments in this callback, will it be incorrect when you call it from child?
  //   console.log(selectedAdjustments)
  //   console.log('adding adjustment')
  //   // first set the state which displays a popup for the user to input adjustment information
  //   // Update the ref to track the adjustment

  //   // render a modal, and pass in the specific PID
  //   toggleDisplayModal()
  //   // Use state to trigger a visual update if needed
  //   // setSelectedAdjustments((prev) => ({
  //   //   ...prev,
  //   //   [parcelId]: value,
  //   // }));
  // }, []);

  const gridRef = useRef(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const theme = useTheme();

  const dataGridStyles = useMemo(() => ({
    boxShadow: 2,
    '& .MuiDataGrid-cell:hover': {
      color: 'primary.main',
    },
    '& .case-review--positive': {
        ...getBackgroundColor('#66bb6a', theme, 0.2),
        '&:hover': {
          ...getBackgroundColor('#66bb6a', theme, 0.4),
        },
        '&.Mui-selected': {
          ...getBackgroundColor('#66bb6a', theme, 0),
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover': {
            ...getBackgroundColor('#66bb6a', theme, 0.3),
          },
        },
      },
      '& .case-review--negative': {
        ...getBackgroundColor('#f44336', theme, 0.5),
        '&:hover': {
          ...getBackgroundColor('#f44336', theme, 0.6),
        },
        '&.Mui-selected': {
          ...getBackgroundColor('#f44336', theme, 0.3),
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover': {
            ...getBackgroundColor('#f44336', theme, 0.4),
          },
        },
      },
      '& .MuiDataGrid-cell': {
        border: 'none !important',
        borderTop: 'none !important',
        boxSizing: 'border-box',
        '&.Mui-selected': {
          borderLeft: `2px solid ${theme.palette.primary.main}`,
          borderRight: `2px solid ${theme.palette.primary.main}`,
          borderBottom: 'none',
          backgroundColor: lighten('#66bb6a', 0.1),
        },
      },
      '& .edited': {
        color: '#facc15',
      },
  }), [theme]); // Memoized static styles


  // these useeffects should be the first place you visit for future optimizations. If this happens above the componet context you'll remove
  // the visible lag that occurs when you click on the grid to swap (if user had selected other comps prev.)
  // force state update to clear out locally selected rows when header deselect is clicked.
  useEffect(() => {
    console.log('cleaning up')
    if(!!userUpdate){
    setUserUpdate([]);
    }
  }, [rowUpdate]);

  // What columns are visible by default + loading in from localstorage if it exists
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(() => {
    const savedModel = localStorage.getItem('columnVisibilityModel');
    return savedModel ? JSON.parse(savedModel) : {
      PropertyAssessment: false,
      fullBaths: false,
      halfBaths: false,
      View: false,
      parcel_id: false,
      MLSNumber: false,
    };
  });
  // if a user changes column display, set in localstorage.
  useEffect(() => {
    // console.log('column viz changed.')
    localStorage.setItem('columnVisibilityModel', JSON.stringify(columnVisibilityModel));
  }, [columnVisibilityModel]);
  
  // why are you memoizing this? not sure this does anything of value.
  const memoizedColumnVisibilityModel = useMemo(() => columnVisibilityModel, [columnVisibilityModel]);
  const [toolbarKey, setToolbarKey] = useState(1); // is this still relevant to us now that toolbar isn't memoized?
  const [isSaving, setIsSaving] = useState(false); // state to set loading on the grid (doesn't work with pinned but thats a minor bug)
  const unsavedChangesRef = useRef({
    originalSubject: {},
    unsavedRows: {},
    rowsBeforeChange: {},
    unsavedSubject: {},
  });

  const forceToolbarUpdate = useCallback(() => {
    setToolbarKey(prevKey => prevKey + 1); // Change the key to force re-render
  }, []);

  const apiRef = useGridApiRef();
  
  // when you change comps, clear out the datagrid internal state for selected comp / edited inventory.
  const handleApiOperations = useCallback((savedCompOverride=savedCompPids) => {
    if (apiRef.current) {
      const columns = apiRef.current.getAllColumns();
      const checkboxColumn = columns.find(col => col.field === 'actions');
      // console.log(checkboxColumn)
      // console.log('its runnin')

      if (checkboxColumn) {
        checkboxColumn.indeterminate = false;
        checkboxColumn.selectionArray = savedCompOverride;
        checkboxColumn.reOrderArray = [];
        apiRef.current.updateColumns([checkboxColumn]);
      }

      reOrderingRef.current = [];

      unsavedChangesRef.current = {
        originalSubject: {},
        unsavedRows: {},
        rowsBeforeChange: {},
        unsavedSubject: {},
      };
      // forceToolbarUpdate();
      // console.log('Grid state cleared due to comp change');
    }
  }, [apiRef, comp, savedCompPids]);

  // If the status is isSaving for more than like 10 seconds, you should fire the callback from below function 
  // to go and check if the value can be updated

  // Added the change on view for cleaning function
  useEffect(()=> {
    cleaningFunction();
  }, [view, savedCompPids]);

  // added rowupdate into dependencies. Not sure if necessary.
  useEffect(() => { // refactor to a callback so you stop getting issues w/ apiref current being null on load.
    cleaningFunction();
    // conditional state cleaning as you change comps for is saving & compsheed update
    if (!compStreamObject.current?.updated || compStreamObject.current.updated.length === 0) {
      // console.log('no updated array')
      if(isSaving){setIsSaving(false)}
      return
    }
    if ((updatedArray && comp in updatedArray) || (compStreamObject.current.updated && comp in compStreamObject.current.updated)) {
      // console.log('there is an updated arr.');
      if (updatedArray[comp] === false || compStreamObject.current.updated[comp] === false) {
        // console.log('this is NOT updated.')
        if (!isSaving) {setIsSaving(true);}
        // This will happen if you change views, is that desired? or should we have it only when comp changes?
        // if only when comp, need to remove view from this useeffect and make a new one to do all but this.
        handleUpdateStateCallback();
      } else {
        // console.log('it is flagged that this comp is updated, setting saving to false.')
        if (isSaving) {setIsSaving(false);}}
    } else {
      // This block is when you don't have the stream obj (meaning you haven't updated inventory)
      if (isSaving) {setIsSaving(false);}
    }
    
  }, [comp, savedCompPids]);

  // this MOSTLY works, but as the stream goes on it seems to have issues?
  useEffect(() => {
    console.log("firing useeffect because rray prop changed.")
    // the array state has changed, so you need to check if the comp is updated.
    if(isSaving){
      if(updatedArray[comp] === true){
        console.log('this comp should be done')
      setIsSaving(false);
      }
    }
  }, [updatedArray]);

  // useEffect(() => {
  //   console.log('saving state changed')
  //   console.log(isSaving)

  //   console.log('your array values;')
  //   console.log(updatedArray)
  // }, [isSaving, updatedArray]);

  // Couldn't get this to work running automatically
// const checkForUpdates = useCallback(() => {
//   console.log('checking for updates');
//   console.log(updatedArray)

//   if (updatedArray[comp] === true) {
//     setIsSaving(false);
//     return;
//   }
  
//   if ((updatedArray && comp in updatedArray) || (compStreamObject.current?.updated && comp in compStreamObject.current.updated)) {
//     console.log('there is an updated arr.');
//     if (updatedArray[comp] === false) {
//       console.log('this is NOT updated.');
//       if (!isSaving) {  
//         setIsSaving(true);
//       }
//       handleUpdateStateCallback();
//     } else {
//       console.log('it is flagged that this comp is updated, setting saving to false.');
//       if (isSaving) {  
//         setIsSaving(false);
//       }
//     }
//   } else {
//     console.log('there is no updated inventory array');
//     if (isSaving) {  
//       setIsSaving(false);
//     }
//   }
// }, [comp, handleUpdateStateCallback, updatedArray,]);

// useEffect(() => {
//   const intervalId = setInterval(() => {
//     checkForUpdates(); // Call your callback function every 2 seconds
//   }, 2000);

//   return () => clearInterval(intervalId); // Clean up the interval on unmount
// }, [checkForUpdates, updatedArray]); // Include the callback in the dependency array

  // Useeffect with setintever

  // New useeffect to refresh if the 

  // need the cleaning function to be run on view change.

  const cleaningFunction = useCallback(() => {
    handleApiOperations();

    // console.log(userUpdate)
    if(!!userUpdate){ // if its not empty,
      console.log('resetting to userUpdates to false (empty)')
      setUserUpdate(false);
    }
    if(reordering){setReordering(false)}
  }, [handleApiOperations, userUpdate, reordering]);
  // Inventory update code:
  // instead of the callback from processrowupdate - see if you can just trigger this callback
  const handleProcessRowUpdate = useCallback((updatedRow, originalRow) => {
    // Function to get the changed fields between the updated and original row

    console.log('updated row:', updatedRow)
    console.log('original row:', originalRow)

    const getChangedFields = (updated, original) => {
      return Object.keys(updated).reduce((acc, key) => {
        // ADDED ADJ PRICE INTO THIS BUT MAY WANT TO CHANGE IN FUTURE?
        if (key !== 'PropertyInfo' && key!== 'selected' && key!== 'adj_price' && updated[key] != original[key]) {
          acc[key] = updated[key];
        }
        return acc;
      }, {});
    };
    
    // There is a bug here where the adjusted price will be DIFFERENT after updating inventory (because the inventory was different)
    //  need to make sure that the original values are properly cleared out after updating inventory.

    // if the value of the row is the same as original
    
    // had to use this to do the comparison, otherwise it would overwrite the old updates.
    const originalRowInRows = startingRows.find(row => row.id === originalRow.id);

    const changedFields = getChangedFields(updatedRow, originalRowInRows);
    
    // Merge with existing unsaved changes if any
    const existingChanges = unsavedChangesRef.current.unsavedRows[updatedRow.id] || {};

    if (Object.keys(changedFields).length > 0) {
      // Set only the changed fields in the ref, merging with existing changes
      unsavedChangesRef.current.rowsBeforeChange[updatedRow.id] = { ...originalRow };
      unsavedChangesRef.current.unsavedRows[updatedRow.id] = { ...existingChanges, ...changedFields };
      unsavedChangesRef.current.unsavedRows[updatedRow.id].pid = updatedRow.PropertyInfo.parcel_id;
    } else {
      // Remove entry from ref if no changes
      console.log('seems equal');
      delete unsavedChangesRef.current.unsavedRows[updatedRow.id];
      delete unsavedChangesRef.current.rowsBeforeChange[updatedRow.id];
    }
  
    // apiRef.current.updateRows([updatedRow]); // Update row in the grid
    forceToolbarUpdate();
    return updatedRow;
  }, [comp, apiRef]);
  // Actually commit the inventory changes -- Need to test in production once deployed..
  const saveChanges = useCallback(async (additionalProps) => {
    // this function causes a MUITable rerender when the state updates, which causes the grid to rerender and not display the new user updated value.
    // Find some way to displayt he DB modal compoenent without causing a global rerender here

    try {
      // unsaved changes ref had no value.
      // console.log('ref to update:', unsavedChangesRef)
      // Persist updates in the database
      setIsSaving(true); // this should maybe be changed to be a diff local
      setConfirmModal(true);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
    // forceToolbarUpdate();
  }, []);
  // Pass this callback down to the subjtoolbar.
  const updateRefWithSubj = useCallback((newSubj,originalSubj) => {
    if(newSubj.Style === originalSubj.Style){ 
      // custom style handling, if the style is the same, delete it from the object. (this is necessary bc of downstream quirks)
      console.log('its the same!')
      delete newSubj.Style;
      // Also, if there's no keys left other than PID, set the whole value for subject and original to empty object
      if(Object.keys(newSubj).length === 1){
        unsavedChangesRef.current.unsavedSubject = {};
        unsavedChangesRef.current.originalSAubject = {};
        return;
      }
    }
    // Add in the unSavedSubject to the ref.
    unsavedChangesRef.current.unsavedSubject = newSubj;
    // add in the original subject to the ref.
    unsavedChangesRef.current.originalSubject = originalSubj;
    forceToolbarUpdate();

  }, []);
  // Will use this discard function later if the user doesn't commit their updates:
  const discardChanges = useCallback(() => {
    Object.values(unsavedChangesRef.current.rowsBeforeChange).forEach((row) => {
      apiRef.current.updateRows([row]);
    });
    unsavedChangesRef.current = {
      originalSubject: {},
      unsavedRows: {},
      rowsBeforeChange: {},
      unsavedSubject: {},
    };
    forceToolbarUpdate();
  }, [apiRef]);

  // idk that this needs "headers" as a dependency.
  // Function to calculate the flex for the default (to pass to subj row will need to update this with the resize event in future if we want to enable that functionality)
  const calculateFlexForHeaders = useCallback(() => {
    const filteredHeaders = headers.filter((header) => {
      return memoizedColumnVisibilityModel[header.field] !== false;
    });
    return calculateFlex(filteredHeaders, memoizedColumnVisibilityModel, gridRef);
  }, [memoizedColumnVisibilityModel, headers]);
  // just directly call on component load, was having issues w/ passing down correct flex to subject row.
  const headerResult = calculateFlexForHeaders();

  // function to handle the custom checkbox click // had to add in rows as a dependency.
  // this has gotten unruly and should be broken into two different functions.
  const handleCellClick = useCallback((params) => {
    // console.log(params);
    // Can have an "IF here" for if the 'reordering' state is active
    // if it is active, we should instead be reordering the selected array based on the user clicks (disregard prior order)
    if (params.colDef.headerClassName === 'actions') {
      // if you are reordering, we WONT be updating pinned rows.

      // for reordering, when you exit, then update the higher level state.
      if (reordering) { 
        // Then we will JUST be updating the order of your saved comps, do not change pinned rows.
        const selectedRowIds = params.colDef.reOrderArray || [];
        const allSelectedRows = params.colDef.selectionArray || [];
        
        console.log('prev. selected');
        console.log(selectedRowIds);
        
        console.log('allSelectedRows');
        console.log(allSelectedRows);
        
        // Determine the new list of selected rows
        const updatedSelectedRowIds = selectedRowIds.includes(params.row.parcel_id)
            ? selectedRowIds.filter(rowId => rowId !== params.row.parcel_id) // Remove if currently selected
            : [...selectedRowIds, params.row.parcel_id]; // Add if not currently selected
        
        // Use a Set to ensure uniqueness
        const finalRows = Array.from(new Set([...allSelectedRows, ...updatedSelectedRowIds]));
        console.log('final rows:', finalRows);
      
        console.log('new array');
        console.log(updatedSelectedRowIds);
      
        const parcelIds = new Array(updatedSelectedRowIds.length); // Initialize array to maintain order
        
        // Iterate over each row in originalRows
        const updatedRows = originalRows.map(row => {
          if (updatedSelectedRowIds.includes(row.parcel_id)) {
            // If the row is in the updated selection array, set isSelected to true and update selectedRowNumber
            parcelIds[updatedSelectedRowIds.indexOf(row.parcel_id)] = row.parcel_id;
            return {
              ...row,
              isSelected: true,
              selectedRowNumber: updatedSelectedRowIds.indexOf(row.parcel_id) + 1,
              reOrderArray: updatedSelectedRowIds
            };
          // add to parcel ID array at the index of new array
          } else if (selectedRowIds.includes(row.parcel_id)) {
            // If the row was previously selected but is now being removed, set isSelected to false
            return {
              ...row,
              isSelected: false,
              selectedRowNumber: null
            };
          }
          // Return unchanged rows
          return row;
        });
      
        // Apply the updates to the grid
        apiRef.current.updateRows(updatedRows);
      
        // Clear `parcelIds` if `updatedSelectedRowIds` is empty
        if (updatedSelectedRowIds.length === 0) {
          console.log('length is zero');
          const clearedRow = {
            ...params.row,
            isSelected: false,
            reOrderArray: null,
          };
          apiRef.current.updateRows([clearedRow]); // Update row in the grid
          params.colDef.reOrderArray = null;
          reOrderingRef.current = parcelIds;
          return;
        }
        
        // Set column definition properties
        params.colDef.reOrderArray = updatedSelectedRowIds;
        params.colDef.selectionArray = updatedSelectedRowIds;
        params.colDef.apiRef = apiRef;
        reOrderingRef.current = parcelIds;
        
        console.log('Updated parcelIds:', parcelIds);
      }
      
      
      else{
      // YOUR FUNCTION TO PIN THE COLUMSN (the normal selection fucntion)
      // Get the current selection array from the column
      const selectedRowIds = params.colDef.selectionArray || [];
      // Determine if the row is currently selected
      const isCurrentlySelected = selectedRowIds.includes(params.row.parcel_id);
      // if the row is currently selected, you need to put it BACK into your rows - see about filtering at the toplevel?

      // Find all rows that need to be updated based on the current selection state
      // const rowsToUpdate = rows.filter(row => selectedRowIds.includes(row.parcel_id) || row.parcel_id === params.row.parcel_id);
  
      // Toggle the selection array values
      const updatedSelectedRowIds = isCurrentlySelected
        ? selectedRowIds.filter(rowId => rowId !== params.row.parcel_id) // Remove if currently selected
        : [...selectedRowIds, params.row.parcel_id]; // Add if not currently selected
  
      const parcelIds = new Array(updatedSelectedRowIds.length); // Initialize array to maintain order
      // Update each row and insert in order based on updatedSelectedRowIds
      updatedSelectedRowIds.forEach((rowId, index) => {
        const row = startingRows.find(r => r.parcel_id === rowId); // Find the row based on rowId
        if (row) {
          const updatedRow = {
            ...row,
            isSelected: updatedSelectedRowIds.includes(row.parcel_id), // Set `isSelected` based on the new array
            rowIndexPosition: index + 1, // Use index + 1 to maintain the order
            selectionArray: updatedSelectedRowIds,
            selected: updatedSelectedRowIds.includes(row.parcel_id),
          };
  
          // Push the Parcel ID value into parcelIds at the correct index
          parcelIds[index] = row.parcel_id;
          // pinnedRows[index] = row
          apiRef.current.updateRows([updatedRow]); // Update row in the grid
        }
      });

      // Update the column's selection array (this may be a legacy thing, idk if you still use. May use in deselect all in casereview.js)
      params.colDef.selectionArray = updatedSelectedRowIds;
      // Set indeterminate state if there are any selected rows
      // this should reallly be defined by if it was diff than original, not that its >0.
      params.colDef.indeterminate = updatedSelectedRowIds.length > 0;
      params.colDef.apiRef = apiRef;
  
      console.log(parcelIds)
      setUserUpdate(parcelIds);
      updateSelectedComps(parcelIds);
      }
    }

  }, [apiRef, rows, reordering]);
  

    React.useEffect(() => {
      const cellClickSubscription = apiRef.current.subscribeEvent('cellClick', handleCellClick);
    
      // Cleanup the event listener on unmount or when dependencies change
      return () => {
        cellClickSubscription(); // Unsubscribe from the event
      };
    }, [apiRef, handleCellClick]);


    // NEED TO UPDATE THIS TO BE ABLE TO ADD IN A NEW COMP! (and test more.)
    const addCompCallback = useCallback((parcelId) => {
      // This will be local to the table, if its important to have this added across both comps, we can have a callback here
      // That sends it one level higher to caseReview.

      // Filter the original rows to see if this Parcel ID exists. if it does (and is currently not in selected), put it in your selected comps.
      // If it is already in the selected comps, then send a toast saying "comp already selected".

      originalRows.forEach(row => {
        if(row.parcel_id === parcelId){
          console.log('found it')
          console.log(row)
        }
      })

      // cosnole.log(pinnedRows)
      const isPinned = pinnedRows.top.find(row => row.parcel_id === parcelId);
      if(isPinned){
        console.log('comp already pinned')
        toast.error('Comp already pinned')
        return
      }
      
      console.log(userUpdate)
      if(!userUpdate){ // the user hasn't picked anything manually, so check pins.
        // Check to see if the row is already pinned
        console.log(isPinned) // this is already done! throw error!
      }else if(userUpdate.includes(parcelId)){ // if hte user already picked it
        console.log('comp already selected')
        toast.error('Comp already selected')
        return
      }
      
      // need to add in the internal grid update here.
      // Here, check if there's any userUpdates, if so, then add this
      
        console.log('comp not selected')
        // need to figure out how to access the params.coldef and internal grid state from here. Can just use apiref?
        // console.log(apiRef.current)
        // console.log(apiRef.current.getAllColumns())
        // console.log(apiRef.current.getAllColumns()[0].field)
        // console.log(apiRef.current.getAllColumns()[0].selectionArray)

        const selectedRowIds = apiRef.current.getAllColumns()[0].selectionArray || [];
        console.log('selected rows (attempt)')
        console.log(selectedRowIds)

        const newRow = startingRows.find(row => row.parcel_id === parcelId)
        console.log(newRow) // if this isn't found, set indx equal to startingrows +1
        const updateRows = [...selectedRowIds, parcelId];

        // here, IF there is no newRow found, then provide a console error for now.

        if(!newRow){
          toast.error('Selected property not found in the selected view for this table, this feature is a work in progress.')
          return
        }

        const updateRow = {
          ...newRow,
          isSelected: true,
          rowIndexPosition: selectedRowIds.updateRows + 1,
          selectionArray: updateRows,
          selected: true,
          // if there is no newRow found, set the id equal to length + 1:
          id: newRow ? newRow.id : `added-${updateRows.length+1}`,
        }

          apiRef.current.updateRows([updateRow]); // Update row in the grid

      // Update the column's selection array (this may be a legacy thing, idk if you still use. May use in deselect all in casereview.js)
      apiRef.current.getAllColumns()[0].selectionArray = updateRows;
      // Set indeterminate state if there are any selected rows
      // this should reallly be defined by if it was diff than original, not that its >0.
      apiRef.current.getAllColumns()[0].indeterminate = updateRows.length > 0;
      apiRef.current.getAllColumns()[0].apiRef = apiRef;
      
      setUserUpdate(updateRows);

      // Utilize a similar function to the above cellclick.
      // Do not have it toggle to remove.

      // If the PID doesn't exist in the response array 
    }, [pinnedRows, userUpdate]);

    console.log('saved comps')
    console.log(savedCompPids)

    const getRowClassName = useCallback((params) => {
      // Determine the status class based on 'positive' or 'negative' status
      const statusClass = params.row.status === 'positive' ? 'positive' : 'negative';
      
      // Default to the row's selected value
      let isSelected = params.row.isSelected;
      
      // If `userUpdate` is provided, check if the row's parcel_id is included in `userUpdate`
      if (userUpdate) {
        isSelected = userUpdate.includes(params.row.PropertyInfo.parcel_id);
      } 
      // If `userUpdate` is an empty array, no rows should be selected
      else if (userUpdate && userUpdate.length === 0) {
        isSelected = false;
      }

      // When `userUpdate` is not available, use `savedCompPids`
      // else if (!userUpdate) {
      //   const isParcelInSaved = savedCompPids.includes(params.row.PropertyInfo.parcel_id);
        
      //   // Update only if the parcel is in savedCompPids and is not already selected,
      //   // or if it’s not in savedCompPids and the current value is not already false
      //   if ((isParcelInSaved && !isSelected) || (!isParcelInSaved && isSelected)) {
      //     isSelected = isParcelInSaved;
      //   }
      // }
    
      // Return the appropriate class based on `isSelected`
      return `${isSelected ? 'selected-comp' : `case-review--${statusClass}`}`;
    }, [userUpdate, savedCompPids]);
    

  // yellow coloring in grid for edited cells.
  const getCellClassName = useCallback((params) => {
    const rowId = params.row.id;
    const field = params.field;
    if(params.field !== 'pid'){
    const isCellEdited = !!unsavedChangesRef.current.unsavedRows[rowId]?.[field];
    // if(isCellEdited){
    // console.log(isCellEdited)
    // console.log(unsavedChangesRef.current)
    // }
    return isCellEdited ? 'edited' : '';
    }
  }, []);

  const mergedProps = useMemo(() => ({
    rows: rows,
    columns: headers,
    ...tableProps,
  }), [rows, headers, tableProps]);

  // Funtion to actually update the inventory.
  const handleProcessDBUpdate = useCallback(async (responseObject) => {
    
    responseObject.manualReview = 1; // did this
    // clonedeep the uids array and se tit equal to responseObject.originalPidOrder
    responseObject.originalPidOrder = cloneDeep(responseObject.uids)
    // update the value of compStreamObject.current.updated array to be all false.
    compStreamObject.current.updated = new Array(responseObject.uids.length).fill(false);
    resetUpdateArrayCallback()

    // Find the index of updatedSubject in the uids array
    const updatedSubjectIndex = responseObject.uids.indexOf(responseObject.updatedSubject);

    if (updatedSubjectIndex > -1) {
      // Remove the updatedSubject from its current position
      const [updatedSubject] = responseObject.uids.splice(updatedSubjectIndex, 1);
      // Insert the updatedSubject at the 0th position
      responseObject.uids.unshift(updatedSubject);
    }

    console.log(responseObject)
    
    // here, update the responseObject.uids ordering so the 0th index is the records[]
  try {
    // Need an onupdate function which will be called to process stream
    const updateInventoryResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update_inventory`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify(responseObject),
  });

    if (!updateInventoryResponse.ok) {
      throw new Error(`Inventory update failed: ${updateInventoryResponse.statusText}`);
    }
    console.log("Inventory updated successfully. Starting stream processing...");

    // Now process your stream response as it comes back.
    const streamProcessingResponse = await processStream({
      compStreamObjectRef: compStreamObject.current,
      stream: updateInventoryResponse.body, // pass this in to then get reader.
      negotiationObj: responseObject
      // currentComps:
    });

    console.log('loading state from stream ')
    console.log(loading)

    console.log('stream response')
    console.log(streamProcessingResponse)

    console.log('obj after the process')
    console.log(compStreamObject.current)

    // Here you don't clear the inv update out until the whole thing has re-run.
    // THis should really happen as it starts running
    // clear out the update inventory ref.
    unsavedChangesRef.current = {
      originalSubject: {},
      unsavedRows: {},
      rowsBeforeChange: {},
      unsavedSubject: {},
    };


  } catch (error) {
    console.error('Error while updating inventory:', error);
  }


    // here, invoke the function to process the fetch response you

    // Lastly, log the response (in the global object you get back from that processsing.)
  }, []);
  // User did not confirm to update database, close the modal and clear out the unsaved changes.
  const handleConfirmationClose = useCallback((responseObject=null,confirmed=false) => {
    
    if(confirmed){
    unsavedChangesRef.current.rowsBeforeChange = {};
    setConfirmModal(false);
    }
    else{
    console.log('canceling modal');
    if(responseObject){
      console.log('response object')
      // Here we should invoke the "update inventory" function. the fac tthat you don't have these seperated functions just returning vals is dumb.

      // 0th index = regular comps, 1st =optimized
      // here splice you rows per the response you got back.
      
    }
    // Close modal and call function to revert the grid back to the original state
    setConfirmModal(false);
    setIsSaving(false);
    discardChanges();
  }
  }, []);

  // only functions as local error handling would need to confirm the internal grid state is still correct. Haven't been able to get it to error to test.
  const handleProcessRowUpdateError = React.useCallback((error) => {
    console.log('Error updating row:', error);
    unsavedChangesRef.current = {
      originalSubject: {},
      unsavedRows: {},
      rowsBeforeChange: {},
      unsavedSubject: {},
    };
  }, []);

  const setIsSavingCallback = useCallback((isSaving) => {
    handleApiOperations([])
    setIsSaving(!isSaving);
  }, []);
  
  const handleColumnVisibilityChange = useCallback((newModel) => {
    setColumnVisibilityModel(newModel);
  }, []);

  const handleToggleReordering = useCallback(() => {
    if(reOrderingRef.current.length>0){
      console.log('you already have a value, I think you need to set the user IDS value here.')
      console.log('reordering ref')
      console.log(reOrderingRef.current)
      setUserUpdate(reOrderingRef.current);
      updateSelectedComps(reOrderingRef.current);
      // handleUpdateSelectedComps(reOrderingRef.current);
      // here you need to set the user update to those comps
      // this function sets the values equal to the normal 5 by clearing both out.
      // set the value equal to your ref
      // reOrderingRef.current = [];
      handleApiOperations(reOrderingRef.current);
    }
    // set steate
    setReordering(!reordering);
  }, [reordering]);


  // this prevents certain columns from showing up in teh gridtoggle panel.
  const hiddenFields = ['actions',];

  const getTogglableColumns = useCallback((columns) => {
    console.log(columns);
    return columns
      .filter((column) => !hiddenFields.includes(column.field))
      .map((column) => column.field);
  }, []);

  return (
    // if you have map enabled, then make this height smaller - done in parent.
    <div className='w-full h-full' ref={gridRef}>
      {/* display loading */}
      {confirmModal && (
        <DBConfirmModal
        confirmModal={confirmModal}
        onDBUpdateSuccess={handleProcessDBUpdate}
        // no reason to have this be two diff functions for closing approve vs disapprove.
        handleConfirmCancel={handleConfirmationClose}
        inventoryUpdates={unsavedChangesRef.current}
      />
      )
      }
      <DataGridContainer
      addCompCallback={addCompCallback}
      discardChanges={discardChanges}
      setIsSavingCallback={setIsSavingCallback}
      getTogglableColumns={getTogglableColumns}
      handleProcessRowUpdate={handleProcessRowUpdate}
      mergedProps={mergedProps}
      dataGridStyles={dataGridStyles}
      pinnedRows={pinnedRows}
      userUpdate={userUpdate}
      apiRef={apiRef}
      memoizedColumnVisibilityModel={memoizedColumnVisibilityModel}
      handleColumnVisibilityChange={handleColumnVisibilityChange}
      getRowClassName={getRowClassName}
      getCellClassName={getCellClassName}
      handleProcessRowUpdateError={handleProcessRowUpdateError}
      headerResult={headerResult}
      isDifferent={isDifferent}
      saveChanges={saveChanges}
      subject={subject}
      unsavedChangesRef={unsavedChangesRef}
      updateRefWithSubj={updateRefWithSubj}
      handleSaveComps={handleSaveComps}
      handleToggleReordering={handleToggleReordering}
      reordering={reordering}
      isSaving={isSaving}
      savedCompPids={savedCompPids}
      />
      
    </div>
  );
}
// MuiTable.whyDidYouRender = true;
export default React.memo(MuiTable, areEqual);

// pass down a callback to this toolbar which updates the ref in the parent component.

