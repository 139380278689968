import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import CaseReview from "./CaseReview";
import {
  useLocalDataStore,
  useAdminSettingsStore,
  usePersistedDataStore,
} from "../store";
import CompDashboard from "./CompDashboard";
import ReviewSettlements from "./ReviewSettlements";
import _ from "lodash";
import SettingsWidget from "../SettingsWidget";
import axiosInstance from '../../axiosConfig';
import MuniSelection from "./MuniSelection";
import Button from '@mui/material/Button';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function Home({
  setLoading,
  initializationFetch,
  weightWidget,
  setWeightWidget,
  isAdmin,
  nyDataObj,
  yearDropdownOptions,
}) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const [reOrderedComps, setReOrderedComps] = useState({});
  const getReviewPage = useLocalDataStore((state)=> state.reviewPage)
  const setReviewPage = useLocalDataStore((state)=> state.setReviewPage)
  // at the end of your fethc, do you update any of these sttes?
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const getOptimizedComps = usePersistedDataStore((state) => state.optimizedComps);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0; // Subtract 1
  const memoizedNegoiationObj = useMemo(() => getNegotiationObj.cases[comp], [getNegotiationObj,comp]);
  const memoizedOptimizedComps = useMemo(() => getOptimizedComps[comp], [getOptimizedComps,comp]);
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  // create the ref which will track global comps from fetch
  const savedComps = Object.values(getNegotiationObj.cases[comp]?.Comps || {});
  const [upcomingCourtDates, setUpcomingCourtDates] = useState([]);
  const [updatedArray, setUpdatedArray] = useState([]);

  const globalCompRef = useRef({});
  const compStreamObject = useRef({properties: []}); // this is used to see if we should update our compsheet, optimizedcomps, and negotiation Obj


  // Memoize the fetchCourtDates function to prevent it from being recreated on each render
  const fetchCourtDates = useCallback(async () => {
    try {
      const courtResponse = await axiosInstance.get('/load_court_dates');
      const courtDates = Object.keys(courtResponse.data);
      courtDates.unshift(''); // Add an empty string at the start if necessary
      setUpcomingCourtDates(courtDates);
    } catch (error) {
      console.error('Error fetching court dates:', error);
    }
  }, []);


  // Use your ref objects from streamed responses to update state when applicable
  // Invoked on comp change.
  const handleUpdateStateCallback = useCallback((resetState) => {
    console.log('State update callback invoked.');
    // console.log(compStreamObject.current);
    // if (updatedArray.length === 0) {
    //   if (!compStreamObject.current.updated || compStreamObject.current.updated.length === 0) {
    //     console.log('No updated array exists, you havent run update inventory.');
    //     // return; // Exit early if there is no updated array
    //   }else if (getCompSheet.length > 0 && compStreamObject.current.properties.length > 0) {
    //     console.log('You have run comps, and the inventory response has started coming back');
    //     setUpdatedArray(new Array(getCompSheet).fill(false)); // Initialize state to track updated status
    //   }
    // }
  
  
    const globalHasMoreComps = globalCompRef.current.properties[0]?.length > getCompSheet.length;
    let regularCompsToUpdate = [...getCompSheet];
    let optimizedCompsToUpdate = [...getOptimizedComps];

    if(globalHasMoreComps){
      regularCompsToUpdate = [...globalCompRef.current.properties[0]];
      optimizedCompsToUpdate = [...globalCompRef.current.properties[1]];
    }
  
    // console.log('the updated array:', updatedArray);
    // console.log('the updated compstreamref:', compStreamObject.current.updated);
    const anyIsUpdatedFalse = 
    (updatedArray.length > 0 && updatedArray.some(isUpdated => !isUpdated)) || 
    (compStreamObject.current.updated && compStreamObject.current.updated.length > 0 && compStreamObject.current.updated.some(isUpdated => !isUpdated));
  
    let updatedFlags = []; // Make a copy to modify and update after the loop
    
    // Set your update flags.
    if(anyIsUpdatedFalse){
    updatedFlags = [...updatedArray]; // Make a copy to modify and update after the loop
    const globalCompLength = globalCompRef.current.properties[0]?.length || 0;
      if (globalCompLength > updatedFlags.length) {
        const extensionLength = globalCompLength - updatedFlags.length;
        updatedFlags = [...updatedFlags, ...new Array(extensionLength).fill(false)]; // Extend with 'NA'
        console.log(`Extended updatedFlags to length ${globalCompLength} with false for new entries.`);
      }
    }
  
    if (anyIsUpdatedFalse || globalHasMoreComps) {
      console.log('Updating comps as one or more conditions were met.');
      console.log(globalHasMoreComps);
  
      regularCompsToUpdate.forEach((regularComp, index) => {
        if(anyIsUpdatedFalse){
          if(!updatedArray[index] && compStreamObject.current.properties.length>0 && compStreamObject.current.updated[index] === true){
            console.log('This index isn\'t updated:', index);
  
            const newRegularComp = compStreamObject.current.properties[0][index];
            const newOptimizedComp = compStreamObject.current.properties[1][index];
    
            // Log old and new values
            console.log(`Comp ${index}: Old regularComp =`, regularComp, ", New regularComp =", newRegularComp);
    
            // Update comps if new values are available
            // if (newRegularComp !== undefined && regularComp !== newRegularComp) {
              regularCompsToUpdate[index] = newRegularComp;
              console.log(`Comp at index ${index} regularComp updated.`);
            // }
    
            // if (newOptimizedComp !== undefined && optimizedCompsToUpdate[index] !== newOptimizedComp) {
              optimizedCompsToUpdate[index] = newOptimizedComp;
              console.log(`Comp at index ${index} optimizedComp updated.`);
            // }
    
            // Mark this index as updated
            updatedFlags[index] = true;
            // compStreamObject.current.updated[index] = true;
          }
        }else if(globalHasMoreComps){

          const newRegularComp = globalCompRef.current.properties[0][index];
          const newOptimizedComp = globalCompRef.current.properties[1][index];

          regularCompsToUpdate[index] = newRegularComp;
          optimizedCompsToUpdate[index] = newOptimizedComp;
        }
      });
  
      // Set the state outside the loop
      if(anyIsUpdatedFalse){
      setUpdatedArray(updatedFlags);
      }
      setCompSheet(regularCompsToUpdate);
      setOptimizedComps(optimizedCompsToUpdate);
    } else {
      console.log('No updates were needed.');
    }
  }, [getCompSheet, getOptimizedComps, updatedArray]);
  
  const resetUpdateArrayCallback = useCallback(() => {
    console.log('Resetting updated array.');
    console.log(getCompSheet.length)
    setUpdatedArray(new Array(getCompSheet.length).fill(false));
  }, []);

  useEffect(() => {
    console.log(updatedArray)
  }, [updatedArray]);
  
  

  useEffect(() => {
    fetchCourtDates();
  }, [fetchCourtDates]);

  // This doesn't work at all.
  useEffect(() => {
    console.log('instantiating array.')
    if (getCompSheet.length > 0) {
      console.log('it has compsheet to set it too.')
      setUpdatedArray(new Array(getCompSheet.length).fill(false)); // Initialize state to track updated status
    }
  }, []);

  return (
    <div className="bg-gray-200 flex flex-col h-full">
      {/* Weight widget ternary -- move this down into case review.*/}
      {weightWidget ?
        <SettingsWidget nyDataObj={nyDataObj} initializationFetch={initializationFetch} setWeightWidget={setWeightWidget}
          setLoading={setLoading}
        ></SettingsWidget>
        : null}

      {/* The below top nav section */}
      <div className="h-full overflow-y-hidden">
        <section className=" overflow-y-hidden max-w-screen-22xl px-6 h-full" id='home'>
          {/* Top navigration above map. */}
          {/* UPDATE DISPLAY FROM GETNEGOTIATION OBJ FOR MUNICODE AND TAXYEAR IN HEADER. */}
          <CompDashboard yearDropdownOptions={yearDropdownOptions} globalCompRef={globalCompRef} reOrderedComps={reOrderedComps} weightWidget={weightWidget} setWeightWidget={setWeightWidget} setReOrderedComps={setReOrderedComps} nyDataObj={nyDataObj}></CompDashboard>
          <>
          {getReviewPage ?
          <>
          <Button onClick={()=>setReviewPage(false)}
          startIcon={<KeyboardBackspaceIcon/>}
            >Back to Comps</Button>
          <ReviewSettlements></ReviewSettlements>
          </>
          :
          getCompSheet[comp] === undefined ? // update this to 
          <div className="mt-4">
            <MuniSelection nyDataObj={nyDataObj} globalCompRef={globalCompRef} yearDropdownOptions={yearDropdownOptions} upcomingCourtDates={upcomingCourtDates}
            />
          </div>
            :
            // {/* New container component, this will cannibalize the map, grid, and the misc */}
            getCompSheet[comp] === undefined ? (null) : (
              <CaseReview compObject={getCompSheet[comp]}
                handleUpdateStateCallback={handleUpdateStateCallback}              
                globalCompRef={globalCompRef} // idt you need this here
                caseObject={memoizedNegoiationObj} // if you pass in the negotiation object
                updatedArray={updatedArray}
                // savedComps={Object.values(memoizedNegoiationObj?.comps || {})}
                compStreamObject={compStreamObject}
                savedComps={Object.values(getNegotiationObj.cases[comp]?.Comps || {})}
                resetUpdateArrayCallback={resetUpdateArrayCallback}
                optimizedObject={memoizedOptimizedComps}>
              </CaseReview>
            )}
          </>
        </section>
      </div>
    </div>
  );
}
// memoize home
const HomeMemo = React.memo(Home);
export default HomeMemo
// export default Home;
