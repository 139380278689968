import React, { useState, useEffect, memo, useMemo, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GraphParams from './GraphParams.js';
import dayjs from 'dayjs';
import AxisOverlay from './AxisOverlay.js';
import axiosInstance from '../../axiosConfig.js';
import SortableTable from '../SortableTable.js';
import GraphlyPlot from './GraphlyPlot.js';

const GraphOverlay = memo(({ nyDataObj, yearDropdownOptions }) => { // top level "filter" which renders the axes in their desired locations
  // create a sub component for   // 
  const [timeAdjOverride, setTimeAdjOverride] = useState('')
  const memoizedTimeAdjOverride = useMemo(() => timeAdjOverride, [timeAdjOverride]);
  const [microLoad, setMicroLoad] = useState(false)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [taxYear, setTaxYear] = useState(2024);
  const memoizedTaxYear = useMemo(() => taxYear, [taxYear]);
  const [baseYear, setBaseYear] = useState(parseInt(taxYear) - 1);
  const memoizedBaseYear = useMemo(() => baseYear, [baseYear]);
  const [plot, setPlot] = useState('');
  const [chartType, setChartType] = useState('');
  const MuniCode = queryParams.get('MuniCode') || 'All';
  const municipality = queryParams.get('municipality') || 'All';
  const county = queryParams.get('county') || 'All';
  const [graphError, setGraphError] = useState(null)
  const [displayMode, setDisplayMode] = React.useState('graph');
  const [axes, setAxes] = useState({ xAxis: '', yAxis: '', zAxis: ''});
  const memoizedAxes = useMemo(() => axes, [axes]);
  const [storedFilter, setStoredFilter] = useState(null);
  const memoizedFilter = useMemo(() => storedFilter, [storedFilter]);
  const [summaryStats, setSummaryStats] = useState(null)

  const handleParamsChange = useCallback((value, label) => {
    console.log('Received value:', value, 'for label:', label);
    // Update the base year and tax year based on the label
    if (label === 'Base Year') {
      // console.log('Updating Base Year:', value);
      setBaseYear(value);
    } else if (label === 'Tax Year') {
      // console.log('Updating Tax Year:', value);
      setTaxYear(value);
    } else if (label === 'Time Adjustment') {
      // console.log('Updating Time Adjustment:', value);
      setTimeAdjOverride(value);
    }
  
    // Log the response of this axes update:
    setAxes((prevAxes) => {
      console.log('Updating axes based on new baseYear and taxYear');
      // Initialize new axes based on previous state
      const updatedAxes = { ...prevAxes };
  
      // We need to update based on the label passed in
      if (label === 'Base Year') {
        // Search all axes for the old baseYear and replace it with the new value
        updatedAxes.xAxis = updatedAxes.xAxis.replace(baseYear, value);
        updatedAxes.yAxis = updatedAxes.yAxis.replace(baseYear, value);
        updatedAxes.zAxis = updatedAxes.zAxis.replace(baseYear, value);
      } else if (label === 'Tax Year') {
        // Search all axes for the old taxYear and replace it with the new value
        updatedAxes.xAxis = updatedAxes.xAxis.replace(taxYear, value);
        updatedAxes.yAxis = updatedAxes.yAxis.replace(taxYear, value);
        updatedAxes.zAxis = updatedAxes.zAxis.replace(taxYear, value);
      }
  
      console.log('Updated Axes:', updatedAxes);
      return updatedAxes;
    });
  }, [baseYear, taxYear, setBaseYear, setTaxYear, setTimeAdjOverride, axes, setAxes]);
  

  const graphDependencies = useMemo(() => ({ // the functions will re-script when these values change.
    baseYear,
    taxYear,
    MuniCode,
    county,
    timeAdjOverride,
    storedFilter,
    memoizedAxes,
    chartType,
  }), [baseYear, taxYear, MuniCode, county, timeAdjOverride, storedFilter, axes, chartType]);

  // based on charttype, axis options shoul dbe constrained (ie: can't represent a 1d axis on 2d chart)
  // this can be memoized and returned from a function response (theoretically can change on chart type)
  const axisOptions = useMemo(() => [ // defined axis object - could use a response to update this.
    { value: 'SaleDate', label: 'Sale Date', type: 'date' },
    { value: 'SaleMonth', label: 'Sale Month', type: 'numeric' },
    { value: 'SaleYear', label: 'Sale Year', type: 'numeric' },
    { value: 'Address', label: 'Address', type: 'numeric' },
    { value: 'Year', label: 'Year', type: 'numeric' },
    { value: 'SalePrice', label: 'Sale Price', type: 'numeric' },
    { value: 'NeighborhoodCode', label: 'Neighborhood Code', type: 'numeric' },
    { value: 'IFMV', label: 'IFMV', type: 'numeric' },
    { value: 'Assessment', label: "Assessment", type: 'numeric' },
    { value: `Assessment_${baseYear}`, label: `Assessment ${baseYear}`, type: 'numeric' },
    { value: 'spot_RAR', label: 'Calculated RAR', type: 'numeric' },
    { value: 'spot_RAR_timeAdj', label: `Time Adj RAR`, type: 'numeric' },
    { value: `spot_RAR_${baseYear}`, label: `RAR using ${baseYear} AV`, type: 'numeric' },
    { value: `spot_RAR_timeAdj_${baseYear}`, label: `Time Adj RAR using ${baseYear} AV`, type: 'numeric' },
    { value: `AssessDelta_${taxYear}_${baseYear}`, label: `Assessment Δ ${baseYear} -> ${taxYear}`, type: 'numeric' },
    { value: 'TimeDelta', label: 'Time Adj', type: 'numeric' },
    { value: 'Sold', label: 'Sold', type: 'bool' },
    { value: 'RegridConvertedSqFt', label: 'regrid sqft', type: 'numeric' },
    { value: 'Sqft', label: 'Sqft', type: 'numeric' },
    { value: 'Zip5', label: 'zip', type: 'numeric' },
    { value: 'Style', label: 'Style', type: 'string' },
    { value: 'high_end', label: 'High End', type: 'bool' },
    { value: 'Client', label: 'Client', type: 'numeric' },
    // { value: 'RAR', label: 'RAR' }, // this is the static RAR
    // What other fields do we want to includ efrom the axes
  ], [baseYear, taxYear]);

  console.log(axisOptions)

  const [headers, setHeaders] = useState([ // update this state as you change your axes
    {
        id: 'index',
        numeric: false,
        disablePadding: true,
        alignment: 'left',
        style: 'text-xs',
        label: 'Index',
    },
    {
        id: 'axis1',
        numeric: false,
        disablePadding: true,
        alignment: 'left',
        label: 'Address',
    },
  ]);
  const [rows, setRows] = useState([]);
  // this getTaxyear nmay be the commonality theme rerendering everything

  function createData({index, axis1,}) { // define data you build in table
      return {
      index,
      axis1,
      };
  }
//   async function loadOptions(muniObj){ // consolidate the options load into your other call.
//     setMicroLoad(true)
//     try {
//         const visualizationObject = {
//             ...muniObj,
//             // lag: lag,
//             PlotType: chartType,
//           };
//         const visualizationFeatures = await axiosInstance.post(`/load_viz_features`, visualizationObject);
//         const axisOptions = visualizationFeatures.data;
//         console.log(axisOptions)
//     } catch (error) {
//         // Handle the error here
//         setPlot('')
//         setGraphError('Uncaught Data error. Try a different Year/Area')
//         console.error('An error occurred while loading visualization features:', error);
//         // Optionally, you can update the state to reflect the error condition
//     }
// }

  const loadGraph = useCallback(async (graphType, axes={xAxis: '', yAxis: '', zAxis: ''}, filter=memoizedFilter) => { // function that re-renders graph
    // await loadOptions({MuniCode, TaxYear: taxYear, BaseYear: baseYear, timeAdjOverride})
    if(filter){ // store your filter, for subsequent graph update runs.
      setStoredFilter(filter)
    }
    setPlot('loading')
    setChartType(graphType)
    setMicroLoad(true)
    if(MuniCode === 'All' && county === 'All'){
        setPlot('')
        setMicroLoad(false)
        setGraphError('select a County and Municipality')
    return
    }else{
        setGraphError(null)
    }

    const axesMap = {
      'Distribution': 1,
      'X,Y Scatter': 2,
      '3-D Scatter': 3,
    };
    let numRequiredAxes = axesMap[graphType] || 0;

    // Filter axes to get non-blank values
    const nonEmptyAxes = Object.values(axes).filter(value => value !== '');
    console.log(nonEmptyAxes)
    
    // If number of non-empty axes is less than required, get the default axes (program axis values)
    if (nonEmptyAxes.length < numRequiredAxes) {
      // Fetch or define your program axis values here
      // Third one is made up for now, figure out what it should be later.
      const programAxisValues = ['spot_RAR', 'IFMV', 'Client'];
      console.log('Using (up to 3) default axes:', programAxisValues);
      // Fill in missing axes with program values
      let overrideCount = 0;
      Object.keys(axes).forEach((key, index) => {
          if (axes[key] === '' && overrideCount < numRequiredAxes) {
              axes[key] = programAxisValues[overrideCount];
              overrideCount++;
          }
      });
      console.log('Not enough axes passed, updating adtl axes to defaults.')
      // see if it double renders may need to move this state set out of here.
      setAxes(prevAxes => ({
        ...prevAxes,
        xAxis: axes.xAxis || prevAxes.xAxis,
        yAxis: axes.yAxis || prevAxes.yAxis,
        zAxis: axes.zAxis || prevAxes.zAxis
      }));
    }
    // Initialize visualization object
    const visualizationObject = {
        MuniCode: MuniCode,
        TaxYear: taxYear,
        BaseYear: memoizedBaseYear,
        timeAdjOverride: memoizedTimeAdjOverride,
        PlotType: graphType,
        Axes: axes,
        ...(filter &&  filter ), // this includes groups.
    };

    console.log('params being passed in:')
    console.log(visualizationObject)
    try{
    const vizResponse = await axiosInstance.post(`/get_plot`, visualizationObject);
    const visualization = JSON.parse(vizResponse.data[0])
    const summaryStats = vizResponse.data[1]
    // console.log(summaryStats) // render these on the plot.
    setSummaryStats(summaryStats) // use this to display summary stats.
    // plot handling for styling.
    visualization.layout.margin = visualization.layout.margin || {};
    visualization.layout.pad = visualization.layout.pad || {};
    visualization.layout.autosize = true;
    visualization.layout.margin.l = 260; 
    visualization.layout.margin.r = 180;
    if (graphType === 'Distribution') { // figure out sizing of components for non-3d chart
      // don't need this anymore, but in case we need custom handling for htis in the future.
    } else if (graphType === 'X,Y Scatter') {
        // axis1 = visualization.layout.xaxis.title.text;
        visualization.layout.margin.r = 0
    } else if (graphType === '3-D Scatter') {
        visualization.layout.scene.camera = visualization.layout.scene.camera || {};
        visualization.layout.scene.camera.eye = visualization.layout.scene.camera.eye || {};
        visualization.layout.scene.camera.eye.z = 1.35; // changed default here to prevent cutoff corner. 1.4 is fully non-cut.
        // const scene = visualization.layout.scene;
        // // Iterate over keys and set axis values accordingly - currently these axes aren't used for anhything.
        // Object.keys(scene).forEach(key => {
        //     if (key === 'xaxis') {
        //         axis1 = scene[key].title.text;
        //     } else if (key === 'yaxis') {
        //         axis2 = scene[key].title.text;
        //     } else if (key === 'zaxis') {
        //         axis3 = scene[key].title.text;
        //     }
        // });
    }

    // Finally, set your plot + table value:
    setPlot(visualization)
    createTable(visualization.data[0].y) // index is for each plot that is rendered, will need to map over for groups if you want in tabular.
    }catch{
    setPlot('')
    setGraphError('Uncaught Data error. Try a different Year/Area')
    }
    console.log('setting load to false')
    setMicroLoad(false)
  },[graphDependencies])

  function createTable(data){
      const tableRows = data.map((axis1, index) => { // this takes your data for the plot, and creates a table
          return createData({index, axis1})
      })
      setRows(tableRows)
      // setHeaders(headers)
  }

  // This top level also contains the 
    const handleAxisChange = useCallback((axisLabel, value) => {
      setAxes((prevAxes) => ({ // THIS updates your axis values in the parent.
        ...prevAxes,
        [axisLabel]: value
      }));
    },[]);

  const formatFilterObject = useCallback((filters) => {
    // Create a new array by mapping over the original filters
    const updatedFilters = filters.flatMap((filter) => {
        // If filter type is 'date', format the date value and return a new filter object
        console.log(filter)
        // bandaid filtering fix, need to set the dtype of the saleDate filter, it snot coming through.
        if (filter.dataType  === 'date' || filter.column==='SaleDate') {
          console.log('filter')
            const formattedDate = dayjs(filter.value).format('YYYY-MM-DD');
            return { ...filter, value: formattedDate, column: 'SaleDate' };
        } 
        // If filter type is 'dateRange', return two new filters: one for 'from' and one for 'to'
        else if (filter.dataType === 'dateRange') {
            const newFilters = [];
            // Handle 'from' date
            if (filter.value.hasOwnProperty('from')) {
                const fromDate = dayjs(filter.value.from).format('YYYY-MM-DD');
                newFilters.push({ ...filter, value: fromDate, operator: '>', column: 'SaleDate', dataType: 'date' });
            }
            // Handle 'to' date
            if (filter.value.hasOwnProperty('to')) {
                const toDate = dayjs(filter.value.to).format('YYYY-MM-DD');
                newFilters.push({ ...filter, value: toDate, operator: '<', column: 'SaleDate', dataType: 'date' });
            }
            return newFilters; // `flatMap` will handle the flattening of the array
        }
        // If not a date or dateRange, return the filter as is
        return filter;
    });

    // Reduce the updatedFilters to create a filter object, separating Filters and Groups
    const filterObject = updatedFilters.reduce((acc, curr) => {
        if (curr.type === 'Filter') {
            acc.Filters.push(curr);
        } else if (curr.type === 'Group') {
            // You can sort groups here if needed
            acc.Groups.push(curr);
        }
        return acc;
    }, { Filters: [], Groups: [] });

    console.log('filterObject', filterObject);
    return filterObject;
  }, []);

  const onFilterChange = useCallback((filter) => { // this filter callback could be down in the axis overlay component technically.
      const processedFilter = formatFilterObject(filter);
      if (chartType !== '') { // may not nneed this conditiona aat all
          // update the chart with load graph function
          handleUpdateGraph(chartType, processedFilter);
      }
  }, [graphDependencies]);

  const handleUpdateGraph = useCallback(
    async (plotType, filter=memoizedFilter) => {
      setMicroLoad(true);
      try {
        // Pass filter along to loadGraph
        await loadGraph(plotType, memoizedAxes, filter);
      } catch (error) {
        console.error('Error loading graph:', error);
      } finally {
        setMicroLoad(false);
      }
    },
    [graphDependencies] // The function will be recreated if axes changes
  );
console.log(memoizedBaseYear)
  return (
    <div className='flex flex-col h-full'>
      {/* Top section for graph params. */}
        <div className='bg-white rounded-md flex flex-col items-center min-h-[90px] mb-2'>
          <div className='text-center font-bold'>
            Visualization Parameters:
          </div>
            <GraphParams nyDataObj={nyDataObj} loadGraph={(value) => handleUpdateGraph(value)} taxYear={memoizedTaxYear} timeAdjOverride={memoizedTimeAdjOverride} handleParamsChange={handleParamsChange} baseYear={memoizedBaseYear} microLoad={microLoad} setMicroLoad={setMicroLoad} yearDropdownOptions={yearDropdownOptions} 
            />
        </div>

      {/* Graph content container */}
      <div className='flex flex-col flex-grow bg-white'>
          <div className='relative flex-grow justify-center items-center'>
          
          {/* Graph vs. data view selection */}
          <div className='flex absolute top-0 left-0 group'>
            <button 
              className={`px-0.5 z-50 border border-gray-300 bg-white hover:border-black ${displayMode === 'graph' ? '[&&]:border-blue-500 border-2' : ''}`} 
              style={{ minWidth: '3.25rem', minHeight: '1.5rem' }} 
              onClick={() => setDisplayMode('graph')}>
              Graph
            </button>
            <button 
              className={`px-1 z-50 border hover:border-black bg-white border-gray-300 ${displayMode === 'data' ? '[&&]:border-blue-500 border-2' : ''}`} 
              style={{ minWidth: '3.25rem', minHeight: '1.5rem' }} 
              onClick={() => setDisplayMode('data')}>
              Data
            </button>
          </div>

          {/* Axis and filter overlay */}
          <AxisOverlay
            axes={axes}
            handleAxisChange={handleAxisChange}
            axisOptions={axisOptions}
            onFilterChange={onFilterChange}
            summaryStats={summaryStats}
          />

          {displayMode === 'data' ? (
            <div className='flex justify-center items-center w-full h-full'>
              <SortableTable
                headCells={headers}
                rows={rows}
                functionName={'Flag to not file SCAR'}
                undoFunctionName={'Undo'}
              />
            </div>
          ) : (
              
            // HERE IS WHERE THE GRAPH CONTENT IS RENDERED
              <div className='flex flex-grow relative h-full justify-center items-center'>
                {graphError && (
                  <div className="flex justify-center items-center h-full ">
                    <div className="text-meta-1 pb-24">Error: {graphError}</div>
                  </div>
                ) }
                {microLoad ? (
                  <div className="flex justify-center items-center h-full">
                    <div className="h-16 w-16 animate-spin z-[500] rounded-full border-4 border-solid border-primary border-t-transparent" />
                  </div>
                ) : 
                plot && (
                  <div className='flex flex-grow w-full h-full'>
                  <GraphlyPlot
                  plot={plot}
                  />
                  </div>
                )}
              </div>
            )}
          </div>
      </div>
    </div>
  );
  })

  export default GraphOverlay;