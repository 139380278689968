import React from 'react';
import ExcelJS from 'exceljs';
import { usePersistedDataStore } from '../store';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const DownloadXLSButton = ({ jsonData, fileName, keys }) => {
  const getRar = usePersistedDataStore((state) => state.rar);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('All_Cases'); // Default selection

  // const jsonData = selectedOption === 'Scar Only' ? scarCases : getNegotiationObj.cases;
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    handleClose(); // Close the menu after selection

    // Perform filtering for 'Scar Only' selection
    if (option === 'Scar_Only') {
      const filteredScarCases = jsonData.filter((caseItem) => caseItem.SCARFiled === 1);
      downloadXLS(filteredScarCases,option); // Download filtered scar cases
    } else {
      downloadXLS(jsonData,option); // Download all cases
    }
  };

  const downloadXLS = async (dataToDownload,option) => {
    // Define the desired order of keys

    // Filter out undefined or null values and reorder keys in the JSON data
    // stupid ass code, will revisit this one day, but functions for now.
    const reorderedData = dataToDownload.map(item => {
      const reorderedItem = {};
      for (const key of keys) {
        const value = item[key];
        if (key === 'RAR') {
          reorderedItem[key] = getRar;
        } else if (key === 'PctReduction') {
          // Convert value to percentage and truncate to 2 decimals
          reorderedItem[key] = value !== undefined && value !== null 
            ? Math.trunc(value * 10000) / 100 // Multiplies by 100 to shift decimal, truncates, then divides back
            : ''; // Handle undefined or null values
        } else if (value === undefined || value === null || (typeof value === 'number' && Number.isNaN(value))) {
          reorderedItem[key] = ''; // Set undefined, null, or NaN values to an empty string
        } else {
          reorderedItem[key] = value; // Keep all other values
        }
      }
      return reorderedItem;
    });

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const pctReductionColumnIndex = keys.indexOf('PctReduction')+1; // ExcelJS uses 1-based indexing
    worksheet.getColumn(pctReductionColumnIndex).numFmt = '0.00%'; // Format as percentage with two decimals

    // Add headers to the worksheet
    worksheet.columns = keys.map((key) => ({
      header: key, 
      key: key
    }));

    // Add rows of reordered data to the worksheet
    reorderedData.forEach((data) => {
      worksheet.addRow(data);
    });

    reorderedData.forEach((data, rowIndex) => {
      const cell = worksheet.getCell(rowIndex + 2, pctReductionColumnIndex); // +2 because headers are in row 1
      if (data.PctReduction !== '' && data.PctReduction !== undefined) {
        cell.value = data.PctReduction / 100; // Divide by 100 to convert it to a proper percentage format
        cell.numFmt = '0.00%'; // Format as percentage with two decimals
      }
    });
    // Write to file
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a blob for download
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a link element and download the file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}_${option}.xlsx`;
    link.click();
  };

  return (
    <div>
    <button
      onClick={handleClick}
      className='flex mx-auto w-fit px-3 py-2 font-semibold rounded bg-violet-400 text-gray-800'
    >
      Download XLS
    </button>

    {/* MUI Menu for options */}
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => handleOptionSelect('Scar_Only')}>Scar Only</MenuItem>
      <MenuItem onClick={() => handleOptionSelect('All_Cases')}>All Cases</MenuItem>
    </Menu>
  </div>
  );
};

export default DownloadXLSButton;
