import React,{ useRef, useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles'; // Updated imports
import ForgotPassword from './ForgotPassword';
import { GoogleIcon, FacebookIcon, SitemarkIcon } from './CustomIcons';
import { Navigate, useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme({
  palette: {
    mode: 'light', // or 'dark'
  },
  // Add any custom theme properties here
});

const userObject = {
  'james@aventine.ai': 1,
  'maegan@aventine.ai': 3,
  'jrburns@aventine.ai': 16,
  'brennan@aventine.ai': 19,
}

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...(theme.palette.mode === 'dark' && {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  padding: 20,
  marginTop: '10vh',
  position: 'relative',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage:
      'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...(theme.palette.mode === 'dark' && {
      backgroundImage:
        'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

// env variables:
// this is redundant code atm, it also exists in axios config.
let baseURL;
if (process.env.REACT_APP_ENV === 'production') { // this is where your post url gets set.
  baseURL = 'https://api.proprietyrealty.com'
} else if (process.env.REACT_APP_ENV === 'staging') { // this is where your post url gets set.
    baseURL = 'https://staging.proprietyrealty.com/'
  } else {
  baseURL = process.env.REACT_APP_API_BASE_URL
  }
const clientId = process.env.REACT_APP_GOOGLE_API_CLIENT_ID
const clientSecret = process.env.REACT_APP_GOOGLE_API_CLIENT_SECRET
const redirectUri = window.location.origin+process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI

export default function SignIn(props) {
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [signingIn, setSigningIn] = useState(false); //state if user is signing in or signing up.
  const [loginError, setLoginError] = useState(false);
  const [signupError, setSignupError] = useState(false);
  const [signupErrorText, setSignupErrorText] = useState(false);
  const [spinningLoad, setSpinningLoad] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState(false)
  const [passwordReset, setPasswordReset] = useState(false)
  const buttonRef = useRef();
  const [emailSent, setEmailSent] = useState(false)
  const [signinError, setSigninError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
  
    if (code) {
      setSigningIn(true);
      // Exchange code for token
      exchangeCodeForToken(code)
        .then(data => {
          if (data.error) {
            throw new Error(data.error); // Handle token exchange error
          }
          const accessToken = data.access_token;
          // Fetch user info with access token
          return fetchUserInfo(accessToken);
        })
        .then(userInfo => {
          if (!userInfo.email.endsWith('aventine.ai')) {
            setSigninError(true); // Email domain not authorized
            throw new Error('Unauthorized email domain');
          }

          // based on matching the userInfo.email to the key in the userObject, add in the user id to the userInfo object.
          userInfo.userId = userObject[userInfo.email] || null;
          // Store user info in localStorage
          localStorage.setItem('userInfo', JSON.stringify(userInfo));

          console.log('navigating')
          navigate('/table');
        })
        .catch(error => {
          console.error('Error:', error);
          if(error.message === 'Unauthorized email domain') {
            setSigninError(true);
          }else{
          // setSignupError(true); // Set signup error
          // setSignupErrorText('Failed to sign in. Please try again.');
          }
          setSigningIn(false); // Stop the signing in spinner
        });
    }
  }, []);
  
  const fetchUserInfo = async (accessToken) => {
    const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    });
    const userInfo = await response.json();
    return userInfo;
  };
  

  const exchangeCodeForToken = async (authorizationCode) => {
    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ // move these to env variable.
        code: authorizationCode,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri, // Same as the redirect URI used in authorization request
        grant_type: 'authorization_code',
      }),
    });
    const data = await response.json();
    return data;
  };

  const handleGoogleRedirectSignIn = () => { 
    const scope = 'openid profile email';
    
    const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${encodeURIComponent(scope)}`;
    window.location.href = googleAuthUrl;
  //  setUser(true)
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
  };

  const validateInputs = () => {
    const email = document.getElementById('email');
    // const password = document.getElementById('password');

    let isValid = true;

    if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
      setEmailError(true);
      setEmailErrorMessage('Please enter a valid email address.');
      isValid = false;
    } else {
      setEmailError(false);
      setEmailErrorMessage('');
    }

    // if (!password.value || password.value.length < 6) {
    //   setPasswordError(true);
    //   setPasswordErrorMessage('Password must be at least 6 characters long.');
    //   isValid = false;
    // } else {
    //   setPasswordError(false);
    //   setPasswordErrorMessage('');
    // }

    return isValid;
  };

  return (
    // You should probably remove the actual sign in piece of this since it doesnt work atm
    <ThemeProvider theme={theme}> {/* Wrap the component with ThemeProvider */}
      <CssBaseline />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <SitemarkIcon />
          
          {signingIn ? 
          // If the user logged with google and values were extracted, display loading spinner.
                    <Typography
                    component="h1"
                    variant="h4"
                    sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
                  >
                    Signing in...
                  <CircularProgress />
                  </Typography>
          :
          <>
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                error={emailError}
                helperText={emailErrorMessage}
                id="email"
                type="email"
                name="email"
                placeholder="your@email.com"
                autoComplete="email"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={emailError ? 'error' : 'primary'}
                sx={{ ariaLabel: 'email' }}
              />
            </FormControl>
            {signupError && <div className="text-red-500 text-sm text-center">{signupErrorText}</div>}
            {/* <FormControl>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Link
                  component="button"
                  onClick={handleClickOpen}
                  variant="body2"
                  sx={{ alignSelf: 'baseline' }}
                >
                  Forgot your password?
                </Link>
              </Box>
              <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={passwordError ? 'error' : 'primary'}
              />
            </FormControl> */}
            <FormControlLabel
              control={<Checkbox 
                value="remember" color="primary"
                // Have this checkbox set the username in state.
                 />}
              label="Remember me"
              sx={{ 
                bgcolor: 'transparent',
               }}
            />
            <ForgotPassword open={open} handleClose={handleClose} />
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              onClick={() => handleGoogleRedirectSignIn()}
              startIcon={<GoogleIcon />}
              // disabled={signingIn}
            >
              Sign in with Google
            </Button>

            {/* {loginError && <div className="text-red-500 text-sm text-center">Invalid email or password</div>} */}
            {signinError && <div className="text-red-500 text-sm text-center">Use an email with an authorized domain</div>}
            {/* <GoogleLogin onSuccess={(e)=>console.log(e)} onError={()=>console.log('errror')} /> */}
            {/* <Button
              type="submit"
              fullWidth
              variant="outlined"
              onClick={() => alert('Sign in with Facebook')}
              startIcon={<FacebookIcon />}
            >
              Sign in with Facebook
            </Button> */}
          </Box>
        </>
          }
        </Card>
      </SignInContainer>
    </ThemeProvider>
  );
}

// table for email you're sending.
{/* <table width="600" style={{ border: '1px solid #333' }}>
<thead>
  <tr>
    <th style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f4f4f4', fontWeight: 'bold' }}>Property</th>
    <th style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f4f4f4', fontWeight: 'bold' }}>Address</th>
    <th style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f4f4f4', fontWeight: 'bold' }}>Sale Price</th>
    <th style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f4f4f4', fontWeight: 'bold' }}>Assessment</th>
    <th style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f4f4f4', fontWeight: 'bold', textAlign: 'right' }}>Est. Tax Bill</th>
  </tr>
</thead>
<tbody>
<tr>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>A</td>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>
<a href="https://www.zillow.com/homedetails/56-Rutland-Rd-Yonkers-NY-10708/33000858_zpid/" target="_blank" rel="noopener noreferrer">
56 Rutland Rd
</a>
</td>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>$701,000</td>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>21,500</td>
<td style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f2f2a1', textAlign: 'right', fontWeight: 'bold' }}>22,366</td>
</tr>
<tr>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>B</td>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>
<a href="https://www.zillow.com/homedetails/134-Bretton-Rd-Yonkers-NY-10710/32992238_zpid/" target="_blank" rel="noopener noreferrer">
134 Bretton Rd
</a>
</td>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>$700,000</td>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>8,050</td>
<td style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f2f2a1', textAlign: 'right', fontWeight: 'bold' }}>8,374</td>
</tr>
<tr>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>C</td>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>
<a href="https://www.zillow.com/homedetails/15-Pondview-Ln-Yonkers-NY-10710/32994356_zpid/" target="_blank" rel="noopener noreferrer">
15 Pondview Ln
</a>
</td>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>$1,100,000</td>
<td style={{ border: '1px solid #dddddd', padding: '8px' }}>16,400</td>
<td style={{ border: '1px solid #dddddd', padding: '8px', backgroundColor: '#f2f2a1', textAlign: 'right', fontWeight: 'bold' }}>17,061</td>
</tr>

</tbody>
</table> */}