import { usePersistedDataStore } from '../components/store';
import { useState, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

export function useStreamProcessor(setFetchIterationCallback) {
  // console.log(setFetchIterationCallback)
  const setCompSheet = usePersistedDataStore((state) => state.setCompSheet);
  const setOptimizedComps = usePersistedDataStore((state) => state.setOptimizedComps);
  const setEvalDate = usePersistedDataStore((state) => state.setEvalDate);
  const setRar = usePersistedDataStore((state) => state.setRar);
  const setEq = usePersistedDataStore((state) => state.setEq);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet);
  const getOptimizedComps = usePersistedDataStore((state) => state.optimizedComps);
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const comp = parseInt(searchParams.get('comp')) - 1 || 0; // Subtract 1
  const [loading, setLoading] = useState(true);

  const iterationRef = useRef(0);

  const navigate = useNavigate();

  const processFetchResponse = useCallback(({ response, globalCompRef, negotiationObj, currentIndex, ManualReview }) => {
    const regularComps = [response[0]];
    const optimizedComps = [response[1]];
    const evalDate = response[3];

    // If you have .uids then make it the update inventory run (don't navigate and set state on 2nd process)
    const inventoryUpdate = negotiationObj.uids ? true : false;

    // Determine whether to use negotiationObj.uids or negotiationObj.cases
    const lookupArray = negotiationObj.originalPidOrder || negotiationObj.cases;

    // Check if updated array already exists in globalCompRef, if not initialize it
    // if (!globalCompRef.updated) {
    //   globalCompRef.updated = new Array(lookupArray.length).fill(false);
    // }


    // Map over response[0] and update the updatedArray
      const parcelId = response[0].parcel_id[0]; // extract parcel_id[0] // parcel id should be the parcel ID of hte subject in question?
      // console.log(parcelId) 
      const indexInLookupArray = lookupArray.findIndex(item => item === parcelId);

      // If the parcelId exists in negotiationObj.uids or negotiationObj.cases, update the corresponding index in updatedArray
      // if (indexInLookupArray !== -1) {
      //   updatedArray[indexInLookupArray] = true; // or use 1 if you prefer binary values
      // }

    // Attach updatedArray to globalCompRef
    // globalCompRef.updated = updatedArray;

    // Determine if manual review is needed
    if (negotiationObj.manualReview === 1) {
      if (currentIndex === 1) { 
        console.log('its the first index')
        if(inventoryUpdate){
          console.log('running the inventory update portion')
          const updatedArray = globalCompRef.updated; // use the existing array
          // instantiate an empty globalcompref properties array of length equal to the lookup array in first run.
            globalCompRef.properties = [
              new Array(lookupArray.length).fill(null), // First nested array
              new Array(lookupArray.length).fill(null)  // Second nested array
            ];

          // There is a low probability bug here, where if the user is ON the edge of a batch, and updates inv, THEN navigates
          // This can be fixed by also calling the comparator and updating your state of compsheet and optim comps on inv update too.
          // You can call it at the start of function?
          // This should become basically a non-issue once backend gets faster.  as the user can't get to the end of the batch before next is done?
          const updateRegularComps = cloneDeep(negotiationObj.originalCompsheet);
          const updateOptimzedComps = cloneDeep(negotiationObj.originalOptimizedComps);

          // Instantiate the global ref
          globalCompRef.properties[0] = updateRegularComps;
          globalCompRef.properties[1] = updateOptimzedComps;
          globalCompRef.properties[0][indexInLookupArray] = response[0];
          globalCompRef.properties[1][indexInLookupArray] = response[1];
          // const updateRegularComps = cloneDeep(globalCompRef.properties[0]);
          // const updateOptimzedComps = cloneDeep(globalCompRef.properties[1]);
          updateRegularComps[indexInLookupArray] = response[0];
          updateOptimzedComps[indexInLookupArray] = response[1];
          // update the globalCompRef.updated value fo rhte proper index to true.
          updatedArray[indexInLookupArray] = true;
          console.log('updating regular comps')
          console.log(updateRegularComps)
          setCompSheet(updateRegularComps);
          setOptimizedComps(updateOptimzedComps);
        }else{
        globalCompRef.properties = [regularComps, optimizedComps];
        if (negotiationObj.cases.length === 1) {
          setCompSheet(regularComps);
          setOptimizedComps(optimizedComps);
          setEvalDate(evalDate);
        }
        }
      } else if (currentIndex === 2 && !inventoryUpdate) { // this navigation should only really happen in knn run not in the update inventory.
        console.log('running the second iteration')
        globalCompRef.properties[0] = globalCompRef.properties[0].concat(regularComps);
        globalCompRef.properties[1] = globalCompRef.properties[1].concat(optimizedComps);
        setRar(globalCompRef.properties[0][0].RAR[0]);
        setEq(globalCompRef.properties[0][0].EQ[0]);
        // setEvalDate(evalDate);
        setCompSheet(globalCompRef.properties[0]);
        setOptimizedComps(globalCompRef.properties[1]);
        setEvalDate(evalDate);
        // Update URL
        const url = new URL(location.pathname + location.search, window.location.origin);
        const searchParams = new URLSearchParams(url.search);
        searchParams.set('comp', 1);
        searchParams.set('TaxYear', negotiationObj.TaxYear);
        const newUrlString = `${url.pathname}?${searchParams.toString()}`;
        navigate(newUrlString);
      } else {
        if(inventoryUpdate){ // only update invdividual entry if you're in the inventory update.
          // Every one done here shoul be flagged as updated FALSE
          globalCompRef.properties[0][indexInLookupArray] = response[0]
          globalCompRef.properties[1][indexInLookupArray] = response[1]
          // Then only update them in a callback from "these haven't been updated yet out of MUI table"
        }else{
          console.log('not an inventory update, processing.')
          // If not an inventory lookup, append the values as you go.
        globalCompRef.properties[0] = globalCompRef.properties[0].concat(regularComps);
        globalCompRef.properties[1] = globalCompRef.properties[1].concat(optimizedComps);
        }

      }

      // non-manual review processing function:
    } else {

      // Does this case even really matter to process this? revisit the no manual review run.
      // The noManual review flow is NOT working right.
      // console.log('no manual review')
      if (!globalCompRef || !globalCompRef.properties || globalCompRef.properties.length === 0) {
        // console.log('no compref, instantiating')
        globalCompRef.properties = [regularComps, optimizedComps];
      } else {
        // console.log(' the appended comps')
        // console.log(globalCompRef.properties[0].concat(regularComps))
        globalCompRef.properties[0] = globalCompRef.properties[0].concat(regularComps);
        globalCompRef.properties[1] = globalCompRef.properties[1].concat(optimizedComps);
      }
    }

    // Update fetch iteration ref and state
    iterationRef.current = currentIndex;

    if(inventoryUpdate){ // flag this to be true
      globalCompRef.updated[indexInLookupArray] = true;
    }

    if (setFetchIterationCallback) {
      // console.log('setting fetch iteration callback')
      // console.log(iterationRef.current)
      setFetchIterationCallback(iterationRef.current);
    } else {
      console.warn('setFetchIterationCallback is not provided');
    }

  }, [setCompSheet, getCompSheet, comp, setOptimizedComps, setEvalDate, setRar, setEq, location, navigate]);

  const processStream = useCallback(async ({ compStreamObjectRef, stream, negotiationObj, resetIteration=false }) => {
    console.log(negotiationObj) // does this have your UIDS? if so, you can use this so determine the index position
    // setLoading(true);

    try {
      const reader = stream.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = '';
      // Reset your count if you re-call function (ie: from inventory)
      let iterationCount = resetIteration ? 0 : iterationRef.current;

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          // console.log('Stream completed');
          iterationRef.current = 0;
          break;
        }

        buffer += decoder.decode(value, { stream: true });
        const parts = buffer.split('\n');
        buffer = parts.pop();
        
        for (const part of parts) {
          if (part.trim() !== "") {
            try {
              const update = JSON.parse(part);
              iterationCount += 1;
              // console.log("Processing stream update:", update);

              processFetchResponse({
                response: update,
                globalCompRef: compStreamObjectRef,
                negotiationObj: negotiationObj,
                currentIndex: iterationCount,
              });
            } catch (err) {
              console.error("Error parsing JSON:", err, part);
            }
          }
        }
      }

      if (buffer.trim() !== "") {
        try {
          const finalUpdate = JSON.parse(buffer);
          // console.log("Final update from stream:", finalUpdate);
          processFetchResponse({
            response: finalUpdate,
            globalCompRef: compStreamObjectRef,
            negotiationObj: negotiationObj,
            currentIndex: iterationCount,
            finalUpdate: true,
          });
        } catch (err) {
          console.error("Error parsing final buffer JSON:", err, buffer);
        }
      }
      console.log('Stream is finished.');
    } catch (error) {
      console.error("Error in stream processing:", error);
    } finally {
      setLoading(false);
    }
  }, [processFetchResponse]);

  return {
    processStream,
    loading, // This will now reflect the current iteration count in the invoking component
    // You should return your compref here? unless you can just reference it.
  };
}
