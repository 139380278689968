import { useState, useRef } from 'react';
import axiosInstance from '../axiosConfig'; // Import your axios instance
import { useCompStore } from '../components/store'; // Import your Zustand store or wherever `useCompStore` is defined
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

// filtering / functionality to add:
// a rep id only run (ie: run all reps cases, currently needs to be done at muni level I think)

// Testing that needs to happen:
    // confirm the manual override functions how we need it to. (not deleting things that have been manually saved)


function getTimeStamp() {
    const now = new Date();
    const options = {
      hour12: false, // Ensure 24-hour time format
    };
    
    const timestamp = `[${now.toLocaleString('en-US', options)}.${now.getMilliseconds()}]`;
    return timestamp;
}

// fetch the KNN model

// async function fetchKNNModel(updatedNegotiationObj, updateRef) {
//     const response = await fetch("/KNNModel", {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//             MuniCode: updatedNegotiationObj.MuniCode,
//             TaxYear: updatedNegotiationObj.TaxYear,
//             CourtDate: updatedNegotiationObj.CourtDate,
//             CourtRun: updatedNegotiationObj.CourtRun,
//             uids: updatedNegotiationObj.cases,
//         }),
//     });

//     if (!response.ok) {
//         throw new Error(`Error: ${response.statusText}`);
//     }

//     return new Promise((resolve) => {
//         // Pass the resolve function to handleKNNStreamResponse
//         handleKNNStreamResponse(response, updateRef, resolve);
//     });
// }

// export function useFetchCompsHook() {
//     const [fetchError, setFetchError] = useState(null);
//     const updateRef = useRef(null); // Create a ref to store updates

//     const fetchComps = useCallback(async ({ addedPID = null, updateNegotiationObj, addedPIDs, batchNumber = 1 }) => {
//         setFetchError(null);
//         updateRef.current = null; // Initialize the ref

//         try {
//             let updatedCases;
//             if (addedPID) {
//                 let trimmedPID = addedPID.trim();
//                 updatedCases = [trimmedPID];
//                 updateNegotiationObj.cases = updatedCases;

//                 if (trimmedPID.length >= 3) {
//                     updateNegotiationObj.MuniCode = trimmedPID.substring(0, 3);
//                 } else {
//                     console.error("Error: PID is too short to extract MuniCode");
//                 }
//             } else {
//                 updatedCases = addedPIDs;
//                 updateNegotiationObj.cases = updatedCases;
//             }

//             const updatedNegotiationObjClone = _.cloneDeep(updateNegotiationObj);

//             if (updatedNegotiationObjClone.cases.length === 0) {
//                 setFetchError(['No unsettled comps found in selected muni + year', '(or no tentative assessment exists)']);
//                 return [];
//             }

//             // Start fetching the KNN model and wait for the first response to resolve
//             const firstResponse = await fetchKNNModel(updatedNegotiationObjClone, updateRef);

//             // Return the first response received
//             return firstResponse;

//         } catch (error) {
//             console.error("Error fetching comps:", error);
//             setFetchError(error.message);
//             return null; // Return null in case of error
//         }
//     }, []);

//     return { fetchComps, fetchError, updateRef }; // Return updateRef
// }


// new one with just settlements and adjustments:
export function useFetchCompsHook() {
    // const [loading, setLoading] = useState(false);
    const [fetchError, setFetchError] = useState(null);

    // PASS YOUR PIDS IN AS "updatedNegotiationObj.cases"
    const fetchComps = useCallback(async ({ addedPID = null, updateNegotiationObj, addedPIDs }) => {
        setFetchError(null);
        try {
            let updatedCases;
            if (addedPID) {
                let trimmedPID = addedPID.trim();
                updatedCases = [trimmedPID];
                updateNegotiationObj.cases = updatedCases;

                if (trimmedPID.length >= 3) {
                    updateNegotiationObj.MuniCode = trimmedPID.substring(0, 3);
                } else {
                    console.error("Error: PID is too short to extract MuniCode");
                }
            } else {
                updatedCases = addedPIDs;
                updateNegotiationObj.cases = updatedCases;
            }

            const updatedNegotiationObj = _.cloneDeep(updateNegotiationObj);

            if (updatedNegotiationObj.cases.length === 0) {
                setFetchError(['No unsettled comps found in selected muni + year', '(or no tentative assessment exists)']);
                return [];
            }

            console.log('input for fetches')
            console.log(updatedNegotiationObj)
            // Start all fetches concurrently
            const loadAdjustmentsPromise = axiosInstance.post(`/load_adjustments`, updatedNegotiationObj)

            const loadSettlementsAndCompsPromise = axiosInstance.post(`/load_muni_cases`, updatedNegotiationObj);

            // Wait for all promises to resolve
            const [loadAdjustmentsResponse, loadSettlementsAndCompsResponse] = await Promise.all([
                loadAdjustmentsPromise,
                loadSettlementsAndCompsPromise
            ]);

            let adjustments = null;
                const adjustmentResponse = loadAdjustmentsResponse.data;
                adjustments = adjustmentResponse.response;
                console.log(adjustmentResponse.response);

                console.log('settlements response')
                console.log(loadSettlementsAndCompsResponse)
            const settlementsResponse = loadSettlementsAndCompsResponse.data[0];
            updatedNegotiationObj.cases = settlementsResponse;

            if (updatedNegotiationObj.RAR === undefined || updatedNegotiationObj.RAR === null) {
                updatedNegotiationObj.RAR = null;
            }
            if (updatedNegotiationObj.EQ === undefined || updatedNegotiationObj.EQ === null) {
                updatedNegotiationObj.EQ = null;
            }

            // always pass this back, regardless of how you're reviewing.
            // if (updatedNegotiationObj.manualReview) {
                return { adjustments, updatedNegotiationObj };
            // } else {
                // console.log('No review selected, saving optimized comps.');
                // CALL THE SAVECOMPS FUNCTION
            // }

        } catch (error) {
            console.error("Error fetching comps:", error);
            setFetchError(error.message);
        }
    }, []);

    return { fetchComps, fetchError };
}
