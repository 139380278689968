import Dropdown from "./Dropdown";
import {useLocalDataStore, useAdminSettingsStore, usePersistedDataStore} from './store';
import React, { useEffect, useState, useCallback } from "react";
import DownloadXLSButton from './CaseReview/DownloadXLSButton.js';
import axiosInstance from '../axiosConfig.js'
import {handleApiError} from '../common/handleApiError.js'
import ZipAnalysis from "./ZipAnalysis.js";
import SortableTable from './SortableTable.js';
import CountyAnalysis from "./CountyAnalysis.js";
import { updateMuniObj, getMunicipalityOptions, getVillageOptions } from '../common/helper_functions';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import NewDropdown from './NewDropdown.js'

function Dashboard({ microLoad, nyDataObj, analysisObj, isAdmin, setAnalysisObj, setLoading, yearDropdownOptions, setMicroLoad}){
  const [selectedCounty, setSelectedCounty] = useState(null)
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
  const [analysisType, setAnalysisType] = useState('Muni')
  const getIsTaxYearOpen = useLocalDataStore((state)=> state.isTaxYearOpen)
  const setIsTaxYearOpen = useLocalDataStore((state)=> state.setIsTaxYearOpen)
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const urlString = location.pathname+location.search
  // const MuniCode = queryParams.get('MuniCode') || 'All';
  // const comp = parseInt(queryParams.get('comp'));
  const municipality = queryParams.get('municipality') || 'All';
  const county = queryParams.get('county') || 'All';
  const village = queryParams.get('village') || 'All';
  const courtDate = queryParams.get('CourtDate') || '';
  const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
  const repIDList = ['',1,2]
  const repID = queryParams.get('Rep') || '';
  
  const updateMuni = useCallback(({ updateField, updateValue, updateString = '', urlString }) => {
    updateMuniObj({
        updateField,
        updateValue,
        updateString,
        navigate,
        urlString,
        nyDataObj,
        county,
        TaxYear: taxYear
    });
}, [navigate, nyDataObj, county, taxYear]);

function handleTaxOpen(openVal){
  setIsTaxYearOpen(openVal)
}
  // Have default dashboard be to run for prior year current year -1
  // And error handling for the taxyear funct

  async function runAnalysis(taxYear) {
    try {
    setMicroLoad(true);
    console.log(taxYear)
    console.log(analysisType)
      const response = await axiosInstance.post(`/client_analysis`, {
        TaxYear: taxYear,
        analysisType: analysisType
      });
      const clientAnalysis = response.data;

      console.log(clientAnalysis)
      // Get the existing analysis data from sessionStorage or initialize an empty object
      let analysisData = JSON.parse(sessionStorage.getItem('analysisData')) || {};
      // Add or update the analysis data for the current tax year
      analysisData[getNegotiationObj.TaxYear] = clientAnalysis;

      console.log(analysisData)
      // Store the updated analysis data back in sessionStorage
      sessionStorage.setItem('analysisData', JSON.stringify(analysisData));
  
      // Set the analysis object state
      setAnalysisObj(analysisData);
      console.log(analysisData)
      console.log(analysisData[getNegotiationObj.TaxYear])
    } catch (error) {
      handleApiError(error, 'Error while loading invoice data', setLoading);
    }
    setMicroLoad(false);
  }
useEffect(() => {
  // fire your initial data load.
  // Check if analysis data for the current tax year is already present in sessionStorage
  const analysisData = JSON.parse(sessionStorage.getItem('analysisData')) || {};
  if(getNegotiationObj.TaxYear!==2024){
    if (analysisData[getNegotiationObj.TaxYear]) {
      setMicroLoad(false) // this is getting set to true somewhere
      // console.log(analysisData[getNegotiationObj.TaxYear])
      setAnalysisObj(analysisData);
    }
  }
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // This is used for IF we are after May
  // If after may we can start displaying the next year.

  const options = []; // taxyear dropdown options set based on what time of year you are in
  for (let i = currentYear; i >= currentYear - 2; i--) {
    if (i === currentYear && currentMonth >= 4) {
      options.push((i + 1).toString());
      options.push((i).toString());
  }else {
    options.push((i).toString());
  }
  }
  setDropdownOptions(options);

}, [getNegotiationObj.TaxYear]);

// TODO
// Need to add a button + also put in state, to display button if results don't exist.
function runDashboardAnalysis(){
  // change ths function 
    console.log('Re-fetching')
    setMicroLoad(true)
    runAnalysis(getNegotiationObj.TaxYear);
}


    return(
      <div className="flex justify-center dark:text-white  overflow-auto px-6 py-2 2xl:p-10">
      <div className="w-full max-w-screen-lg min-h-[180px] overflow-auto">
        {/* Content for the Dashboard */}
        <div className="admin-dashboard ">
          <div className="flex mb-4 border-b-2 items-center">
            {/* ON THE OTHER SIDE OF THIS ELEMENT, put breadcrumbs home/dashboard */}
            {/* Update this w/ year componenent. */}

            <NewDropdown
                  value={taxYear}
                    label="Tax Year"
                    dropdownOptions={yearDropdownOptions}
                    updateFunction={updateMuni}
                    updateFunctionArgs={{
                      updateField: 'TaxYear',
                      updateString: '',
                      navigate: useNavigate(),
                      urlString,
                      nyDataObj,
                      county: null,
                      TaxYear: taxYear,
                  }}
                />
           {/* <Dropdown 
           handleChange={handleChange}
            dropdownLabel='Tax Year'
            displayValue={getNegotiationObj.TaxYear}
            isOpen={getIsTaxYearOpen}
            format='comps' 
            setDropToggle={handleTaxOpen}
            dropdownOptions={dropdownOptions}
            ></Dropdown> */}

          <div className="mx-2">
            <button
              className={`rounded ${analysisType === 'Muni' ? 'bg-white' : ''} py-1 px-3 text-xs font-medium text-black shadow-card hover:bg-white hover:shadow-card ${analysisType === 'Muni' ? 'dark:bg-boxdark dark:text-white dark:hover:bg-boxdark' : ''}`}
              onClick={() => setAnalysisType('Muni')}
            >
              Muni's
            </button>
            <button
              className={`rounded ${analysisType === 'Zip' ? 'bg-white' : ''} py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card ${analysisType === 'Zip' ? 'dark:text-white dark:hover:bg-boxdark' : ''}`}
              onClick={() => setAnalysisType('Zip')}
            >
              Zip Codes
            </button>
          </div>

        <div className="mx-auto">
          {!microLoad ?
            <div className="flex justify-center items-center mb-4">
            {/* set a state that checks if it's already run (if false show the button.) */}
            <button onClick={runDashboardAnalysis} className="flex mx-auto w-fit px-6 py-2 font-semibold rounded bg-violet-400 text-gray-800">
              {!analysisObj || !analysisObj[getNegotiationObj.TaxYear] ? 'Run Analysis' : 'Update Analysis'}
            </button>
          </div>
            :
            null}
        </div>

            <div className="ml-auto pb-1">
              <div className="inline-flex items-center rounded-md bg-whiter p-1.5 dark:bg-meta-4">

                {/* ADD ONCLICKS TO TOGGLE BETWEEN RESI / COMMERCIAL */}
                <button className="rounded bg-white py-1 px-3 text-xs font-medium text-black shadow-card hover:bg-white hover:shadow-card dark:bg-boxdark dark:text-white dark:hover:bg-boxdark">
                  Residential
                </button>
                <button className="rounded py-1 px-3 text-xs font-medium text-black hover:bg-white hover:shadow-card dark:text-white dark:hover:bg-boxdark">
                  Commercial
                </button>
              </div>
            </div>

          </div>

        <div>
          {/* Removed your sample table for now, will need to add data. */}
          {/* <SortableTable/> */}
        </div>


{analysisObj?.[getNegotiationObj.TaxYear] && analysisObj[getNegotiationObj.TaxYear] !== undefined && (
  analysisType === 'Zip' ? (
    <>
    <ZipAnalysis isAdmin={isAdmin} analysisObj={analysisObj[getNegotiationObj.TaxYear]} microLoad={microLoad}></ZipAnalysis>
    {microLoad?
      <div className="p-4 items-center content-center m-auto">
        <div>
        <div className="flex items-center content-center justify-center z-[9999]">
          <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
        </div>
      </div>
      </div>
    :
    null
    }
    </>
  ) : (
    <>
    <CountyAnalysis isAdmin={isAdmin} analysisObj={analysisObj[getNegotiationObj.TaxYear]} selectedCounty={selectedCounty} nyDataObj={nyDataObj} setSelectedCounty={setSelectedCounty} microLoad={microLoad}></CountyAnalysis>
     {microLoad?
      <div className="p-4 items-center content-center m-auto">
        <div>
        <div className="flex items-center content-center justify-center z-[9999]">
          <div className="h-16 w-16 animate-spin rounded-full border-4 border-solid border-primary border-t-transparent"></div>
        </div>
      </div>
      </div>
    :
    null
    }
    </>
  )
)}

      <div className="equity-summary text-center mt-6 z-20">
        {analysisObj?.county_data?
        <div className='flex mx-auto w-fit px-6 py-2 mt-4 font-semibold rounded bg-violet-400 text-gray-800'>
        <DownloadXLSButton jsonData={analysisObj.county_data} fileName="data" />
        </div>
        :
        null}
        
      </div>

    </div>
      </div>
    </div>
  );
} export default Dashboard