import {useCompStore, useAdminSettingsStore, useLocalDataStore, usePersistedDataStore} from '../store.js';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import _, {isEqual} from 'lodash';
import Button from '@mui/material/Button';
import CurrencyInput from 'react-currency-input-field';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { updateMuniObj, getMunicipalityOptions, getVillageOptions, getCountyOptions } from '../../common/helper_functions.js';
import axiosInstance from '../../axiosConfig.js'
import NewDropdown from '../NewDropdown.js'
import Modal from '../../common/Modal.js'
import {useFetchCompsHook} from '../../common/fetchComps.js'
import CircularProgress from '@mui/material/CircularProgress';
import { saveComps } from '../../common/saveComps'
import { toast } from 'react-toastify';
import { useStreamProcessor } from '../../common/useStreamProcessor';

export default function MuniSelection({nyDataObj, yearDropdownOptions, upcomingCourtDates, globalCompRef}) {
    const getShowSettledCases = useAdminSettingsStore((state) => state.showSettledCases)
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const [displayOptions, setDisplayOptions] = useState(false);
    const setShowSettledCases = useAdminSettingsStore((state) => state.setShowSettledCases)
    const getDefaultNumCases = useAdminSettingsStore((state) => state.defaultNumCases)
    const setDefaultNumCases = useAdminSettingsStore((state) => state.setDefaultNumCases)
    const getManualReview = useAdminSettingsStore((state) => state.manualReview)
    const setManualReview = useAdminSettingsStore((state) => state.setManualReview)
    const setRar = usePersistedDataStore((state) => state.setRar)
    const setEq = usePersistedDataStore((state) => state.setEq)
    const [addedPID, setAddedPID] = useState('')
    const [error, setError] = useState(false)
    const [popup, setPopup] = useState(false)
    const setCompSheet = usePersistedDataStore((state) => state.setCompSheet)
    const setAdjustments = useCompStore((state)=> state.setAdjustments)
    const setOptimizedComps = usePersistedDataStore((state)=> state.setOptimizedComps)
    const setOriginalOptimComps = useCompStore((state)=> state.setOriginalOptimComps)
    const setOriginalCompList = useCompStore((state) => state.setOriginalCompList)
    const setEvalDate = usePersistedDataStore((state)=> state.setEvalDate)
    const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
    // console.log('rerendered muniselect')
    const navigate = useNavigate();
    const location = useLocation();
    // console.log(location.search)
    const queryParams = new URLSearchParams(location.search);
    const urlString = location.pathname+location.search
    const [loading, setLoading] = useState(false);
    const MuniCode = queryParams.get('MuniCode') || 'All';
    // const comp = parseInt(queryParams.get('comp'));
    const [muniOptions, setMuniOptions] = useState([{value:'All', label:'All'}])
    const municipality = queryParams.get('municipality') || 'All';
    const county = queryParams.get('county') || 'All';
    const village = queryParams.get('village') || 'All';
    const courtDate = queryParams.get('CourtDate') || '';
    const taxYear = queryParams.get('TaxYear') || getNegotiationObj.TaxYear; // updating taxyear updates whole obj. Analyze refactors to see if performance issue in future.
    const repIDList = ['',1,2]
    const repID = queryParams.get('Rep') || '';
    const [villageOptions, setVillageOptions] = useState([{value:'All', label:'All'}])
    const [countyOptions, setCountyOptions] = useState([{ value: "All", label: "All", selectVal: 'All'},
      ...Object.keys(nyDataObj).map(key => ({
          label: key,
          value: nyDataObj[key].selectVal
      }))])
      const { fetchComps, fetchError, updateRef } = useFetchCompsHook();
      const setFetchIteration = usePersistedDataStore((state) => state.setFetchIteration);
      const { processStream, fetchIteration } = useStreamProcessor(setFetchIteration);

    useEffect(()=>{ // update the available counties when courtdate is updated.
      if (!courtDate || !county) {
        console.log('its setting default, could just return nothing here.')
        return
      }
      if(courtDate !== ''){
        // Set a loading widget with value for spinner for the county dropdown.

        console.log('loading the counties for the court date:')
        console.log(courtDate)
        loadCountyOptions(courtDate);
      }
    }, [courtDate]);

    const [courtMunis, setCourtMunis] = useState([]);

    useEffect(() => {
      // Update the available municipalities when the county is updated.
      if (courtDate !== '' && county !== '' && county !== 'All' && county !== 'select') {
        console.log('Loading the munis for the court date:');
        console.log(courtDate);
        loadMuniOptions();
      } else { // set the default muni options if no court date.
        getMunicipalityOptions({
          setMuniOptions,
          nyDataObj,
          county,
          courtMuni: [],
          taxYear,
          navigate,
          countyOptions,
        });
      }
      console.log(county);
    }, [county]);
  
    // really shit function and dumb, but works for now.
    async function loadMuniOptions() {
      console.log(courtDate);
      const courtMunis = await loadCourtMunis(courtDate);
      setCourtMunis(courtMunis);  // Store the fetched courtMunis in state
      getMunicipalityOptions({
        setMuniOptions,
        nyDataObj,
        county,
        courtMuni: courtMunis,
        taxYear,
        navigate,
        countyOptions,
      });
    }
  
    async function loadCountyOptions(courtDate) {
      const courtMunis = await loadCourtMunis(courtDate);
      setCourtMunis(courtMunis);  // Store the fetched courtMunis in state
      getCountyOptions({
        setMuniOptions,
        nyDataObj,
        county,
        courtMuni: courtMunis,
        taxYear,
        navigate,
        setCountyOptions,
      });
    }
  
    const loadCourtMunis = useCallback(async (courtDate) => {
      console.log('Running the load court munis function');
      const response = await axiosInstance.post(`/load_court_munis`, { CourtDate: courtDate });
      console.log(response.data);
      return response.data;
    }, [courtDate]);  // Add dependencies here if necessary
  
    // Memoized function to update municipality options
    const updateMuni = useCallback(({ updateField, updateValue, updateString = '', urlString }) => {
      updateMuniObj({
        updateField,
        updateValue,
        updateString,
        navigate,
        urlString,
        nyDataObj,
        county,
        taxYear,
        setCountyOptions,
        courtMuni: courtMunis,  // Pass courtMunis from state
      });
    }, [navigate, nyDataObj, county, taxYear, courtMunis]);

  // Combine the useeffect function of updatecourtmunioptions into a usememo that sets the munioptions.

  useEffect(()=>{ // would be better to memoize instead of useeffect, although computationally think makes no diff.
    console.log('running the get village useeffect (do this in the function for muniupdate')
    if(courtDate===''){
    getVillageOptions(setVillageOptions, nyDataObj, municipality, county)
    }
  },[municipality])



// refactored the initialization, removed adjustment load.
async function loadClientIds(muniObj) {
  let loading = true;
  let error = null;
      // Start loading clients and adjustments concurrently
      // Within the new client ids route, we also do filtering for if settled or not.
      const loadClientsPromise = await axiosInstance.post(`/load_client_ids`, muniObj);
      // const loadAdjustmentsPromise = axiosInstance.post(`/load_settlements`, muniObj);

      // Process clients once they arrive
      let clients = loadClientsPromise.data;
      // console.log(`${getTimeStamp()} Client loading done:`);
      // Update negotiation object
      const clientList = clients;
      loading = false;
      return clientList; // Return updated object or clients as needed
}
// old batch logic:
// const processFetchResponse = ({fetchResponse, batchNumber=1, totalLength=1}) => {
//   setLoading(false)
//   const properties = fetchResponse.properties;
//   const adjustments = fetchResponse.adjustments;
//   const negotiationObj = fetchResponse.updatedNegotiationObj;
//   const cases = negotiationObj.cases // this is wher eyou should be getting in the settlements data?
//   console.log(properties)
//     // BATCH PROCESSESSING - Set global state.
//     const existingProperties = globalCompRef.current?.properties || [];

//     // Ensure the existingProperties array has at least two nested arrays
//     while (existingProperties.length < 2) {
//       existingProperties.push([]); // Add empty arrays if needed
//     }
//     console.log('starign existing:')
//     console.log(existingProperties)
    
//     properties.forEach((property) => {
//       if (property[0] !== undefined) {
//         existingProperties[0].push(property[0]); // Append to the first nested array
//       }
//       if (property[1] !== undefined) {
//         existingProperties[1].push(property[1]); // Append to the second nested array
//       }
//     });
  
//     console.log(existingProperties);
//     // Push new values into the first and second nested arrays
//     // existingProperties[0].push(...properties[0]); // Append to the first nested array
//     // existingProperties[1].push(...properties[1]); // Append to the second nested array
//     // console.log(existingProperties)
//     // Merge cases into the globalCompRef without overwriting
//     globalCompRef.current = {
//       properties: [...existingProperties],
//       cases: [...(globalCompRef.current?.cases || []), ...cases],
//       totalCases: totalLength,
//     };

//     console.log('the comp ref:')
//     console.log(globalCompRef.current)
    
//   if(batchNumber===1){
//     console.log('first batch')
//   let evalDate = properties[3];
//   negotiationObj.RAR = properties[0][0].RAR[0];
//   negotiationObj.EQ = properties[0][0].EQ[0];

//   console.log(negotiationObj)
//   setEvalDate(evalDate);
//   setAdjustments(adjustments);
//   setNegotiationObj(negotiationObj);
//   setCompSheet(existingProperties[0]);
//   setOptimizedComps(existingProperties[1]);

//   const url = new URL(location.pathname + location.search, window.location.origin);
//   const searchParams = new URLSearchParams(url.search);
//   searchParams.set('comp', 1);
//   searchParams.set('TaxYear', negotiationObj.TaxYear);
//   searchParams.set('view', 'regular');
//   const newUrlString = `${url.pathname}?${searchParams.toString()}`;
//   // Update the URL without reloading the page or navigating, seems to work fine.
//   // window.history.replaceState(null, '', newUrlString);
//   navigate(newUrlString);

//   return;
//   }

// };

// the looped batch fetch code
// const handleInitializeFetch = async (addedPID, manualOverride = false) => {
//   let formattedCourtDate = courtDate ? new Date(courtDate).toISOString().split('T')[0] : '';
//   setLoading(true);
//   globalCompRef.current = {
//     properties: [],
//     cases: [],
//     totalCases: 0,
//   };
//   // have a callback to set higher level loading spinner, and cancel it when done at end of fetch loop.
//   let updateNegotiationObj = {
//       MuniCode,
//       TaxYear: parseInt(taxYear),
//       ...(formattedCourtDate ? { CourtDate: formattedCourtDate, CourtRun: 1 } : { CourtRun: 0 }),
//       showSettledCases: getShowSettledCases,
//       manualReview: getManualReview ? 1 : 0,
//       manualReviewOverride: manualOverride ? 1 : 0,
//       RepId: repID,
//   };

//   if (courtDate === '') {
//       delete updateNegotiationObj.CourtDate;
//   }

//   if (addedPID) {
//       const fetchResponse = await fetchComps({ addedPID: addedPID, updateNegotiationObj });
//       if (fetchError || fetchResponse.length === 0) {
//           console.log('Breaking execution, fetch errored.');
//           return;
//       }
//       processFetchResponse({fetchResponse});
//   } else {
//       const updatedCases = await loadClientIds(updateNegotiationObj);
//       if (updatedCases.length === 0) {
//           console.log('No client IDs found, aborting fetch.');
//           return;
//       }

//       const batchSize = 5; // Your desired batch size
//       const backupResponses = []; // idt we need this for anything at this point.

//       // Fetch each batch individually and process immediately
//       for (let i = 0; i < updatedCases.length; i += batchSize) {
//           const batch = updatedCases.slice(i, i + batchSize);
//           const batchNumber = Math.floor(i / batchSize) + 1; // Calculate the batch number
//           console.log('doing batch:', batchNumber);
//           try {
//               const fetchResponse = await fetchComps({ addedPIDs: batch, updateNegotiationObj, batchNumber });
//               backupResponses.push(fetchResponse);
//               processFetchResponse({fetchResponse, batchNumber, totalLength: updatedCases.length}); // Process the response immediately
//           } catch (error) {
//               console.error('Fetch error for batch:', error);
//           }
//       }
//       // handleCallBackLoadDone
//   }
// };


// this is a little retarded but it was the quickest way to get your existing code to work w/ brennans stream
const isFirstFetchRef = useRef(true);
const isSecondFetchRef = useRef(false); // Track second run

// const processFetchResponse = (response,negotiationObj) => {
//   const regularComps = [response[0]]
//   const optimizedComps = [response[1]]
//   const evalDate = response[3]
  
//   if(negotiationObj.manualReview === 1){
//     setLoading(false)
//   if (isFirstFetchRef.current) {
//     // console.log("First time fetching data, handling specific logic.");
//     // Add your specific handling logic here for the first fetch
//     globalCompRef.current = {
//       properties: [regularComps, optimizedComps],
//     };
//     isFirstFetchRef.current = false; // set to false after first call
//     isSecondFetchRef.current = true; // Mark that the next fetch true

//     // Update the URL without reloading the page or navigating, seems to work fine.
//     // window.history.replaceState(null, '', newUrlString);
//     setCompSheet(regularComps);
//     setOptimizedComps(optimizedComps);
//     setEvalDate(evalDate);
//     return
//   }
//   if (isSecondFetchRef.current) {
//     // Second fetch logic
//     globalCompRef.current.properties[0] = globalCompRef.current.properties[0].concat(regularComps);
//     globalCompRef.current.properties[1] = globalCompRef.current.properties[1].concat(optimizedComps);
//     console.log('the ref in your second run')
//     console.log(globalCompRef.current)
//     // Perform your state updates for the second fetch here
    
//     setRar(globalCompRef.current.properties[0][0].RAR[0]);
//     setEq(globalCompRef.current.properties[0][0].EQ[0]);
//     setCompSheet(globalCompRef.current.properties[0]); // or some other logic if you want different handling for the second fetch
//     setOptimizedComps(globalCompRef.current.properties[1]);
//     const url = new URL(location.pathname + location.search, window.location.origin);
//     const searchParams = new URLSearchParams(url.search);
//     searchParams.set('comp', 1);
//     searchParams.set('TaxYear', negotiationObj.TaxYear);
//     const newUrlString = `${url.pathname}?${searchParams.toString()}`;
//     navigate(newUrlString);
    

//     isSecondFetchRef.current = false; // Mark that the second fetch is done
//   } else {

//   // console.log(globalCompRef.current)
//   // use your ref here to update the global state.
//       globalCompRef.current.properties[0] = globalCompRef.current.properties[0].concat(regularComps);
//       globalCompRef.current.properties[1] = globalCompRef.current.properties[1].concat(optimizedComps);
//       // console.log('the total ref obj:')
//       // console.log(globalCompRef.current)
//   }
//   } else {
//     // here if you don't run manual review, update our compref so you can save the comps.
//     if (globalCompRef.current && globalCompRef.current.properties && globalCompRef.current.properties[0]) {
//       globalCompRef.current.properties[0] = globalCompRef.current.properties[0].concat(regularComps);
//       globalCompRef.current.properties[1] = globalCompRef.current.properties[1].concat(optimizedComps);
//     }
//     else{ // instantiate the obj if the first time
//         globalCompRef.current = {
//             properties: [regularComps, optimizedComps],
//         };
//       }
//   }
// };

const handleFetchSettlements = async (updateNegotiationObj) => {
  // here, we are going to hit the fetchcomps hook to return the values of adj and settlements
  const fetchResponse = await fetchComps({ addedPIDs: updateNegotiationObj.cases, updateNegotiationObj });

  const fetchedCases = fetchResponse.updatedNegotiationObj.cases;
  const originalCases = updateNegotiationObj.cases;

  // Check if the original object is longer than the response
  if (originalCases.length > fetchedCases.length) { // this should no longer be relevant w/ the backend fix you did.
    const diff = originalCases.length - fetchedCases.length;

    // Add empty objects to the end of the fetched cases to match the original length
    for (let i = 0; i < diff; i++) {
      fetchedCases.push({});
    }
  }
  // return fetchResponse;
  console.log(fetchResponse.updatedNegotiationObj.cases)
  const updateingNegotiationObj = {...getNegotiationObj};
  updateingNegotiationObj.cases = fetchResponse.updatedNegotiationObj.cases
  globalCompRef.current = {
    cases: updateingNegotiationObj.cases, // you can update this with a seperate function entirely.
    totalCases: updateingNegotiationObj.cases.length,
  };
  setNegotiationObj(updateingNegotiationObj);
  // setCaseResults(fetchResponse.updatedNegotiationObj.cases);
  setAdjustments(fetchResponse.adjustments);
  return updateingNegotiationObj
};


// the streaming update to this function removed the helper function entirely. TBD if that is desired behavior or not.
const handleInitializeFetch = async (addedPID, manualOverride = false) => {
  let formattedCourtDate = courtDate ? new Date(courtDate).toISOString().split('T')[0] : '';
  setLoading(true);
  
  globalCompRef.current = {
      properties: [],
      cases: [],
      totalCases: 0,
  };

  let updateNegotiationObj = {
      MuniCode,
      TaxYear: parseInt(taxYear),
      ...(formattedCourtDate ? { CourtDate: formattedCourtDate, CourtRun: 1 } : { CourtRun: 0 }),
      showSettledCases: getShowSettledCases,
      manualReview: getManualReview ? 1 : 0,
      manualReviewOverride: manualOverride ? 1 : 0,
      // Village flag is now getting set by the municode >3 digits on backend
      RepId: repID,
  };

  if (courtDate === '') {
      delete updateNegotiationObj.CourtDate;
  }

  // If you put in a single specific PID to run:
  if (addedPID) {
      // Directly invoke the fetch without awaiting the entire response\
      console.log('running a single pid ')

      // This does NOT allow you to run a village for a single comp run atm.
        updateNegotiationObj.cases = [addedPID];
        // set the municode equalt ot he first 3 digits of added PID
        updateNegotiationObj.MuniCode = addedPID.slice(0,3); // this line specifically cuases the village incompatiability.
        const fetchCompsResponse = await fetchKNNModel(updateNegotiationObj);

      if (fetchError) {
          console.log('Breaking execution, fetch errored.');
          return;
      }
  } else {
      // Our generic fetch for all cases
      const updatedCases = await loadClientIds(updateNegotiationObj);
      if (updatedCases.length === 0) {
          console.log('No client IDs found, aborting fetch.');
          return;
      }
      
      // set the value of your pids equalt o the clients. (passing null will fetch all)
      updateNegotiationObj.cases = updatedCases;

      console.log(updateNegotiationObj)
      // if(updateNegotiationObj.manualReviewl === 1){ // only get settlement + adj info if user is going to review.
      // for now force a wait to update teh adjustments and settlements before calling KNN to prevent race conditions.
      // does this even need to be awaited here though?
      const negotiationObjWithSettlements = await handleFetchSettlements(updateNegotiationObj);
      // }

      // const onUpdate = (update) => {
      //     // Handle the update received from fetchComps
      //     console.log('update function called, calling processing function')
      //     processFetchResponse(update,updateNegotiationObj);
      // };

      // Directly invoke the fetch without awaiting the entire response
      const fetchCompsResponse = await fetchKNNModel(updateNegotiationObj);
      // console.log(fetchCompsResponse)
      if(!getManualReview){ // this will only be true if you are not doing a manual review
        console.log('goign into the non-manual save function')
        console.log(negotiationObjWithSettlements)
        const userObject = JSON.parse(localStorage.getItem('userInfo'))
        // iterate over the negotiation Object cases, and add in the value of the comps and PID (pid already exists in cases)
        const saveObject = {
          ManualReviewFlag: 0, 
          ManualOverride: manualOverride ? 1 : 0,
          // cases are passed in capital here. Stupid that this isn't consistent
          Cases: negotiationObjWithSettlements.cases, // pass in as an object so the function works to be modular to save all too.
          UserId: userObject.userId || null,
          VillageFlag: village!=='All' ? 1 : 0,
          TaxYear: taxYear,
          RepId: repID,
        }
        console.log(globalCompRef.current)

        // map over the saveObject, and update the value of each index of the cases with the value of globalCompRef.properties[1] slice of first 5
        saveObject.Cases = saveObject.Cases.map((item, index) => {
          // Create the compsObject from the first 5 items in globalCompRef's parcel_id slice
          const compsObject = globalCompRef.current.properties[1][index].parcel_id.slice(1, 6).reduce((acc, value, index) => {
            acc[index + 1] = value; // Map the index to the value
            return acc;
          }, {});
        
          const newCaseItem = {
            Subject: item.PID,
            Comps: compsObject,
            // repid is inconsistently named. dumb as fuck. Keys are lowercase "Id".
            ...(Array.isArray(globalCompRef.current.properties[0][index]?.RepID) && globalCompRef.current.properties[0][index].RepID[0] !== ''
            ? { RepId: globalCompRef.current.properties[0][index].RepID[0] }
            : { RepId: null })
          };
          return newCaseItem;
        });

        console.log(saveObject) // now, try to call the savecomps function and pass this in.
        const savingComps = await saveComps(saveObject)
        if(savingComps.message === "Comparables batch saved successfully"){
          toast.success(`Comparables updated`, {
            position: 'top-right',
            autoClose: 2000,
          });
        }
        setLoading(false)
        console.log(savingComps)
      }
    }
      // here, after all this, we should call save with the updated savecomp object (put comps into the negotiationobj cases)
};

// Modify the fetchKNNModel to handle streaming updates, invoke external stream function.
async function fetchKNNModel(updateNegotiationObj) {
  console.log(updateNegotiationObj)
  
  // handling the Base URl here bc axios doesn't support streaming in the same way as fetch.
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/KNNModel`, {
      method: "POST",
      headers: {
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
            MuniCode: updateNegotiationObj.MuniCode,
            TaxYear: updateNegotiationObj.TaxYear,
            CourtDate: updateNegotiationObj.CourtDate,
            CourtRun: updateNegotiationObj.CourtRun,
            uids: updateNegotiationObj.cases,
            // RepId: updateNegotiationObj.RepId,
            // Add in manual Review flags?
        }),
  });

  if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
  }

  console.log('the response object, body gets passed in. Its just a data with string [{...}')
  console.log(response)
  // Here, the process stream and fetch combined function.
  const streamProcessingResponse = await processStream({
    compStreamObjectRef: globalCompRef.current,
    stream: response.body, // pass this in to then get reader.
    isFirstFetch: isFirstFetchRef.current,
    isSecondFetch: isSecondFetchRef.current,
    negotiationObj: updateNegotiationObj,
    ManualReview: getManualReview, // if false, accumulate object, then at the end of this invoke savecomps
    
  });

  console.log('loading state from stream ') // this is true up until the last one (make it a number instead)
  console.log(loading)

  console.log('stream response') // this is empty
  console.log(streamProcessingResponse)

  console.log('obj after the process') // this looks like it updated properly?
  console.log(globalCompRef.current,)
}

// can use fetch iteration to display wher eyou are in the stream fetching process
// HAVE THIS NUMBER UPDATE THE TOTAL NUM CASES IN THE DASHBOARD!
// console.log('fetch iteration')
// console.log(fetchIteration)


  const handleUserSelection = (selection) => {
    let manualOverride = false
    if(selection==='Delete them'){
      console.log('deleting manual comps')
      manualOverride = true
    }
    // call fetch
    handleInitializeFetch(addedPID, manualOverride)
    setPopup(false)
  }
  

  // This is a piece of shit component. Should be modularized, and mapped over. 
  // The handle select function casuses app level rerenders because it is coming down from the top level for no reason.
  // TBD if I revisit this in this refactor. Absolutely needs to be cleaned up, but works for now.
    return(
        <div className='justify-center m-auto  content-center bg-white px-8 pt-8 pb-6'>
          <div className=" m-auto justify-center content-center ">

            {/* This popup should be conditional on IF manual comps exist. for now */}
              {!getManualReview && popup &&
              // getPopup==='manualReview'? */}
               <Modal option1='Delete them' option2='Keep reviewed' header='How would you like to handle manually reviewed comps?'
              message='Would you like to override existing manually reviewed comps?.'
              onSelection={handleUserSelection}
              ></Modal>
              }
            {/* Main dropdowns element */}
            <div className='flex m-auto gap-4 justify-center items-end min-w-[190px]'>
              {/* County dropdown, always visible. */}
                <div className='countyDropdown'>
                    <NewDropdown
                          value={county}
                          label="County"
                          dropdownOptions={countyOptions.map(option => ({
                              value: option.value,
                              label: option.label
                          }))}
                          updateFunction={updateMuni}
                          updateFunctionArgs={{
                              updateField: 'county',
                              updateString: '', // assuming `option.label` is not needed
                              navigate,
                              urlString,
                              nyDataObj,
                              county,
                              TaxYear: taxYear,
                              setCountyOptions,
                          }}
                          disabled={countyOptions.length > 1 ? false : true}
                      />
                      {courtDate !== '' && county ==='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a County</span>
                      : null}
                </div>

              {/* Municipality dropdown, conditionally rendered. */}
              <div className='relative flex-col items-center'>
                    <NewDropdown
                        value={municipality}
                        label="Municipality"
                        dropdownOptions={muniOptions.map(option => ({
                            value: option.value,
                            label: option.selectVal,
                            county: option.county,   // Passing additional fields for updateFunction
                            TaxYear: option.TaxYear  // Passing additional fields for updateFunction
                        }))}
                        updateFunction={(...args) => {
                          // console.log('Arguments passed to updateMuni:', args);
                          updateMuni(...args);
                      }}
                        updateFunctionArgs={{
                            updateField: 'municipality',
                            updateString: '', // assuming `option.label` is not needed
                            urlString,
                            nyDataObj,
                            county,
                            navigate,
                            setCountyOptions,
                        }}
                        disabled={county === 'All' && courtDate=== '' || county==='select'}
                    />
                    {error ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>{error}</span>
                    : courtDate !== '' && municipality === 'select' && county !=='select' ? <span className='absolute bottom-[-10] w-[150px] text-xs text-danger'>Select a Municipality</span>
                      : null}
              </div>

              {/* Village */}
              <NewDropdown
                    value={village}
                    label="Village"
                    dropdownOptions={villageOptions.map(option => ({
                        value: option.value,
                        label: option.selectVal
                    }))}
                    updateFunction={updateMuni}
                    updateFunctionArgs={{
                        updateField: 'village',
                        updateString: '', // assuming `option.label` is not needed
                        urlString,
                        nyDataObj,
                        navigate,
                        county: null,  // You can pass actual county if available
                        TaxYear: null,  // You can pass actual TaxYear if available,
                        setCountyOptions,
                    }}
                    disabled={(municipality === 'All' && villageOptions[0] === 'All') || villageOptions.length > 1 ? false : true}
                />

              {/* Taxyear drop */}
              <NewDropdown
                  value={taxYear}
                    label="Tax Year"
                    dropdownOptions={yearDropdownOptions}
                    updateFunction={updateMuni}
                    updateFunctionArgs={{
                      updateField: 'TaxYear',
                      updateString: '',
                      navigate: useNavigate(),
                      urlString,
                      nyDataObj,
                      county: null,
                      TaxYear: taxYear,
                      setCountyOptions,
                  }}
                />
            </div>

      <div className='justify-center m-auto text-center content-center'>
        <span>
          OR: 
        </span>
        {/* Court date dropdown */}
        <div className='flex justify-center'>
          <div className='flex w-fit min- mt-2'>
                <NewDropdown
                    value={courtDate}
                    width={125}
                    label="Court Date"
                    dropdownOptions={
                        upcomingCourtDates.length < 1
                            ? []
                            : upcomingCourtDates.map(value => ({
                                  value: value,
                                  label: value,
                              }))
                    }
                    updateFunction={(...args) => {
                        updateMuni(...args);
                    }}
                    updateFunctionArgs={{
                        updateField: 'CourtDate',
                        updateString: '',
                        urlString,
                        nyDataObj,
                        navigate,
                        setCountyOptions: setCountyOptions,
                    }}
                />
          </div>
        </div>
      </div>
      
          <div className=' mt-6 border-t text-center min-w-[210px]'>
            <div className='my-1 text-sm flex items-center justify-center'>
              <div className="mr-0.5">Additional Options:</div>
              <button className="float items-center justify-center content-center" onClick={() => {
                setDisplayOptions(!displayOptions);
              }}>
                {displayOptions?
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-x"><rect width="18" height="18" x="3" y="3" rx="2" ry="2"/><path d="m15 9-6 6"/><path d="m9 9 6 6"/></svg>
                :
                // Up arrow:
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-square-arrow-down"><rect width="18" height="18" x="3" y="3" rx="2"/><path d="M12 8v8"/><path d="m8 12 4 4 4-4"/></svg>
                }
              </button>
            </div>

            {displayOptions?
            <div className='flex justify-center'>
            <div className='m-auto content-center justify-center'>

              {/* Settled cases */}
            <div className='my-1 flex justify-center m-auto content-center'>
            <FormControl className='flex w-fit min-w-[115px]' size='small' fullWidth>
              <InputLabel id="show-settled-label"
              sx={{zIndex: '10 !important', backgroundColor: 'white !important'}}
              >Show settled:</InputLabel>
              <Select
                  id="show-settled-select" // Add an id for the Select component
                  value={getShowSettledCases} // set a default value for manualReview.
                  onChange={(event) => setShowSettledCases(event.target.value==='true')}
                  className='w-fit min-w-[115px]'
                  labelId="show-settled-label" // Associate the label with this id
                  MenuProps={{
                      PaperProps: {
                          style: { minHeight: '30px' }, // Set your desired minimum height here
                      },
                  }}
              >
                  {[true, false].map((value, index) => (
                      <MenuItem
                          key={index}
                          value={value.toString()} // Convert boolean to string to match the state type
                      >
                          {value.toString()}
                      </MenuItem>
                  ))}
              </Select>
          </FormControl>

        </div>

            {/* DROPDOWN FOR MANUAL REVIEW */}
            <div className='my-3 flex justify-center m-auto content-center'>
            <FormControl className='flex w-fit min-w-[115px] justify-end' size='small' fullWidth>
            <InputLabel id="show-settled-label"
            sx={{zIndex: '10 !important', backgroundColor: 'white !important'}}
            >Manual Review:</InputLabel>
                <Select
                    value={getManualReview} // set a default value for manualReview.
                    className='w-fit min-w-[115px]'
                >
                    {[true, false].map((value, index) => (
                        <MenuItem
                            key={index}
                            value={value.toString()} // Convert boolean to string to match the state type
                            onClick={()=>{setManualReview(value); console.log(value); if(!value){setPopup(true)}}}
                            style={{ minHeight: '30px' }} // Set your desired minimum height here
                        >
                            {value.toString()}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>

            {/* Rep ID */}
            <div className='my-1 flex justify-center m-auto content-center'>
            <NewDropdown
                value={repID}
                label="RepID"
                dropdownOptions={repIDList.map(id => ({
                    value: id,
                    label: id === null ? 'None' : id,
                }))}
                updateFunction={updateMuni}
                updateFunctionArgs={{
                    updateField: 'Rep',
                    updateString: '',
                    urlString,
                    nyDataObj,
                    navigate,
                }}
            />
        </div>


            <div className={`flex max-h-[30px] mt-3 justify-end`}>
              <span className={`font-bold pr-2 `}>
                Run "x" cases:
              </span>
                <fieldset className=" dark:text-gray-100 ">
                  <div className="flex">
                    <CurrencyInput type="text"
                      name="settlement"
                      // prefix=""
                      id="settlement"
                      placeholder="ex: 50"
                      className={`flex flex-1 text-center max-w-[80px] text-black sm:text-sm rounded-md focus:ri p-1 border`}
                      onChange={(e) => setDefaultNumCases(e.target.value)}
                      autoComplete="off"
                      // Disable your input for settlement when it is settled. (should also gray it out more)
                      value={
                        getDefaultNumCases ?
                        getDefaultNumCases
                        :
                        ''
                      }
                    />
                  </div>
                </fieldset>
            </div>

            <div className='mt-1'>
              <span className='text-xs font-bold'>
              Run one Comp:
              </span>
            <fieldset className=" dark:text-gray-100">
                <div className="flex">
                  <input type="text"
                    id="PID"
                    placeholder="Parcel ID"
                    className="flex flex-1 p-1 text-xs text-black text-center border w-[240px] mx-1 rounded-md focus:ri dark:border-gray-700 dark:text-gray-100 dark:bg-gray-800"
                    onChange={(e) => setAddedPID(e.target.value)}
                    value={addedPID || ''}
                  />
                    </div>
              </fieldset>
              </div>
          </div>
          </div>
            :null}
        </div>
      </div>

      {/* Button to fetch comps */}
      <div className='flex items-center mt-4'>
        <Button
          variant="contained"
          className='m-auto flex items-center relative' // Use relative positioning for the button container
          onClick={() => {
            if (courtDate !== '' && municipality === 'All') {
              setError('Select a Municipality');
            }
            handleInitializeFetch(addedPID);
          }}
          disabled={loading} // Optionally disable button when loading
        >
          Generate Comparables
          {loading && (
            <CircularProgress
              size={20}
              sx={{
                color: 'white',
                position: 'relative', // Use relative positioning for the spinner
                marginLeft: '16px',   // Add some space between the text and the spinner
              }}
            />
          )}
        </Button>
      </div>
      {/* display error text under */}
      <div className='text-sm text-red-500 text-center h-[20px]'>
        {fetchError && 
        <div className='flex flex-col'>
        <span>
        {fetchError[0]}
        </span>
        <span>
        {fetchError[1]}
        </span>
        </div>

        }
      </div>

    </div>
    )
}